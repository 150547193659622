<div class="button" *ngIf="loaded else popper">
	<button class="dropdown" type="button">
		<mat-select
			panelClass="dropdown-list"
			[formControl]="supplyDateCtrl"
			(selectionChange)="onSelected($event.value)"
			disableOptionCentering
		>
			<mat-option *ngFor="let supplyDate of supplyDates" [value]="supplyDate">
				{{ supplyDate | eliqdate:'short' }}
			</mat-option>
		</mat-select>
	</button>
</div>

<p class="supplyDateText">
	{{ translationString | translate : { date: translationDate } }}
</p>

<ng-template #popper>
	<div
		[popper]="supplyDatesPopper"
		[popperTrigger]="'click'"
		[popperPlacement]="'bottom'"
		[popperApplyClass]="'myPopper'"
		[popperApplyArrowClass]="'popperArrow'"
		[popperPreventOverflow]="true"
		[popperPositionFixed]="true"
	>
		<button class="dropdown">
			<div class="container">
				<span class="date">{{ currentDate }}</span>
				<img
					class="down-arrow"
					[src]="'assets/images/Icon_arrow_down.svg' | imgSrc"
					alt="Arrow"
					height="29"
					width="29"
				/>
			</div>
		</button>
	</div>

	<popper-content #supplyDatesPopper>
		<div class="popper-container">
			<p>{{ 'submit_meter_read.no_available_dates_message' | translate }}</p>
		</div>
	</popper-content>
</ng-template>
