<div class="container">
  <div class="message-card error-message-card" *ngIf="errorMessage">{{errorMessage | translate}}</div>
  <div class="header-container">
    <h6 class="primary-h6 header">{{headerKey | translate:headerKeyData}}</h6>
    <div class="yearlyTitle">
      <h5 class="sub-header primary-h5" *ngIf="headerValue">{{headerValue}}</h5>
      <span class="text secondary-p3" *ngIf="headerValueKey">{{headerValueKey | translate | lowercase}}</span>
    </div>

    <!-- These spans below is the only way to be able to inject these css variables into javascript -->
    <span class="homechart-axis-color"></span>
    <span class="homechart-elec-color"></span>
    <span class="homechart-elec-2-color"></span>
    <span class="homechart-district_heating-color"></span>
    <span class="homechart-gas-color"></span>
    <span class="homechart-elec-forecast-color"></span>
    <span class="homechart-elec-forecast-border-color"></span>
    <span class="homechart-district_heating-border-color"></span>
    <span class="homechart-district_heating-forecast-color"></span>
    <span class="homechart-gas-forecast-color"></span>
  </div>
  <div class="chart-outer-container">
    <!--<p *ngIf="forecast" class="chart-forecast-legend secondary-p3" translate>common.forecast</p>-->
    <div #myChartContainer id="chart-container" (window:resize)="onResize($event)"></div>
  </div>
</div>
