<div
	class="container"
	*ngFor="let value of meterRead.values; let indexOfElem = index;"
>
	<div class="left">
		<div id="source">{{ meterRead.source }}</div>
		<div class="bottom-left">
			<div
				class="img"
				*ngIf="value.type === 'day' || 'night' && !'standard' else standard "
			>
				<img
					[src]="'assets/images/smr/' + value.type + 'meter_read.svg' | imgSrc"
					height="25"
					width="20"
				/>
			</div>
			<ng-template #standard>
				<img
					class="img"
					[src]="'assets/images/smr/meter_register_' + [indexOfElem + 1] + '.svg' | imgSrc"
					alt="Gas"
					height="25"
					width="20"
				/>
			</ng-template>
			<div id="meter-value">{{ value.value }}</div>
		</div>
	</div>
	<div class="right">
		<div id="date">{{ submittedDate | eliqdate: 'short'  }}</div>
		<div
			*ngIf="value.status === 'pending' || 'error' && !'accepted' && !'removed' && !'unknown' else highlight"
		>
			<span id="status-highlight">{{ value.status }}</span>
		</div>
		<ng-template #highlight>
			<span id="status">{{ value.status }}</span>
		</ng-template>
	</div>
</div>
