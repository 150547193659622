import { Component, OnInit, Input } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'
import { EliqDatePipe } from '@eliq/core/pipes/date.pipe'
import { NgClass, NgIf } from '@angular/common'
import { NotificationItemComponent } from '../notification-item/notification-item.component'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'

@Component({
	selector: 'eliq-notification-item-wrapper',
	templateUrl: './notification-item-wrapper.component.html',
	styleUrls: ['./notification-item-wrapper.component.scss'],
	standalone: true,
	imports: [
		CardComponent,
		NotificationItemComponent,
		NgClass,
		NgIf,
		EliqDatePipe,
	],
})
export class NotificationItemWrapperComponent {
	@Input() index: number
	@Input() notification: NotificationData

	public get yearFormat() {
		return new Date().getFullYear() -
			this.notification.$createdDate.getFullYear() >
			0
			? 'numeric'
			: undefined
	}
}
