<div class="container">
	<div
		class="starting-container max-w-container"
		*ngIf="state === 'starting'; else startedBlock"
	>
		<h6 class="set-up-header primary-h6" translate>
			insights_home_profile_prompt.set_up_home_profile
		</h6>
		<p class="explaining-text secondary-p2" translate>
			insights_home_profile_prompt.this_is_your_consumption_insights_page
		</p>
		<p
			class="explaining-text secondary-p2"
			[innerHTML]="'insights_home_profile_prompt.tell_us_about_your_home' | translate"
		></p>
		<p class="explaining-text secondary-p2" translate>
			insights_home_profile_prompt.you_can_update_your_home_profile_at_any_time
		</p>
		<div class="setup-buttons">
			<div style="margin-bottom: 15px;">
				<eliq-basic-button
					(clicked)="startClick()"
					[label]="'insights_home_profile_prompt.lets_get_started' | translate"
					[useEntireWidth]="true"
				></eliq-basic-button>
			</div>

			<eliq-basic-button
				(clicked)="dontStartClick()"
				[label]="'insights_home_profile_prompt.skip_for_now' | translate"
				[variant]="'secondary'"
				[useEntireWidth]="true"
			></eliq-basic-button>
		</div>
	</div>
</div>

<ng-template #startedBlock>
	<div *ngIf="state==='started'; else completeBlock">
		<eliq-homedetails-setup-stepper
			[locationName]="locationName"
			[locationId]="locationId"
			[properties]="properties"
			[propertyGroups]="propertyGroups"
			(done)="stepperDone($event)"
			(loading)="stepperLoading($event)"
		></eliq-homedetails-setup-stepper>
	</div>
</ng-template>

<ng-template #completeBlock>
	<div class="container-result max-w-container" *ngIf="state==='success'">
		<h5 class="set-up-header primary-h5" translate>
			insights_home_profile_prompt.thanks
		</h5>
		<p class="secondary-p2" translate>
			insights_home_profile_prompt.thanks_for_giving_us_details
		</p>
		<eliq-basic-button
			class="close-button"
			[label]="'common.button_close'"
			(clicked)="closeClick()"
		></eliq-basic-button>
	</div>
	<div
		class="container-result max-w-container"
		*ngIf="state==='success-progress-saved'"
	>
		<h5 class="set-up-header primary-h5" translate>
			insights_home_profile_prompt.saved
		</h5>
		<p class="secondary-p2" translate>
			insights_home_profile_prompt.progress_saved
		</p>
		<eliq-basic-button
			class="close-button"
			[label]="'common.button_close'"
			(clicked)="closeClick()"
		></eliq-basic-button>
	</div>
	<div class="container-result max-w-container" *ngIf="state==='failure'">
		<h5 class="set-up-header primary-h5" translate>
			common.something_went_wrong
		</h5>
		<p class="secondary-p2" translate>common.please_try_again_later</p>
	</div>
</ng-template>
