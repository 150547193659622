import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { APIConsent } from '@eliq/models'
import { ConsentService } from '../consent.service'
import { BasicButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-give-consent-modal',
	templateUrl: './give-consent-modal.component.html',
	styleUrls: ['./give-consent-modal.component.css'],
	standalone: true,
	imports: [BasicButtonComponent],
})
export class GiveConsentModalComponent implements OnInit {
	public consent?: APIConsent
	public acceptLabel = 'high_resolution_insights_data.accept'
	public rejectLabel = 'high_resolution_insights_data.decline'
	public title = ''
	public description = ''
	constructor(
		public dialogRef: MatDialogRef<GiveConsentModalComponent>,
		private translator: TranslateService,
		private consentService: ConsentService,
	) {}

	ngOnInit() {
		if (!this.consent) {
			return
		}
		if (this.consent.is_updated) {
			this.title = this.translator.instant(
				'user_consents.' + this.consent.name + '_title_updated',
			)
			this.description = this.translator.instant(
				'user_consents.' + this.consent.name + '_description_updated',
			)
		} else {
			this.title = this.translator.instant(
				'user_consents.' + this.consent.name + '_title_first_time',
			)
			this.description = this.translator.instant(
				'user_consents.' + this.consent.name + '_description_first_time',
			)
		}
	}

	accept() {
		this.consentService
			.putConsents([{ ...this.consent, given_consent: true } as APIConsent])
			.subscribe(
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				(res) => {},
				(err) => {
					console.error('Something went wrong when setting consents')
				},
			)
		this.dialogRef.close(true)
	}

	reject() {
		this.dialogRef.close(false)
	}
}
