import { DecimalPipe, NgIf } from '@angular/common'
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LocationHttpService } from '@eliq/core'
import { DateHelper } from '@eliq/util'

import { Period } from '@eliq/core'
import { take } from 'rxjs/operators'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { Co2CardComponent } from '../co2-card/co2-card.component'

@Component({
	selector: 'eliq-co2-card-container',
	templateUrl: './co2-card-container.component.html',
	styleUrls: ['./co2-card-container.component.css'],
	standalone: true,
	imports: [NgIf, Co2CardComponent, ImgSrcPipe],
})
export class Co2CardContainerComponent implements OnInit, OnChanges {
	@Input() locId?: number
	@Input() period?: Period
	@Output() loading = new EventEmitter<boolean>()

	// to pass to presentational component
	public header = ''
	public imgSrc = '/assets/images/home_co2.svg'
	public description = ''
	public loaded = false

	private setupDone = false
	private dateCalc = DateHelper.getInstance()

	constructor(
		private translator: TranslateService,
		private decPipe: DecimalPipe,
		private locHttp: LocationHttpService,
	) {}

	ngOnInit(): void {
		this.setup()
		this.setupDone = true
	}

	ngOnChanges() {
		if (this.setupDone) {
			this.setup()
		}
	}

	private setup() {
		if (!this.locId || !this.period) {
			return
		}

		this.setLoaded(false)
		const fromDate = this.period.getFirstDate()
		const toDate = this.dateCalc.addOfPeriod(
			this.period.getPeriodType(),
			1,
			this.period.getFirstDate(),
		)
		this.locHttp
			.getLocationCO2(this.locId, fromDate, toDate)
			.pipe(take(1))
			.subscribe(
				(co2emission) => {
					if (co2emission) {
						// might be undefined, as in api returned no "co2emission" field. in that case, treat as error.
						const carco2 = this.decPipe.transform(
							co2emission.car_gas_emission_in_km,
							'1.0-0',
						)
						const co2 = this.decPipe.transform(co2emission.co2_in_kg, '1.0-0')

						this.header = this.translator.instant('home_co2_card.co2_heading', {
							value: co2,
						})

						const d1: string = this.translator.instant(
							'home_co2_card.co2_description',
						)
						const d2 = this.translator.instant(
							'home_co2_card.car_description',
							{ value: carco2 },
						)

						if (d1 === 'NONE') {
							this.description = d2
						} else {
							this.description = d1 + '<br>' + d2
						}
					} else {
						this.setFailed()
					}
					this.setLoaded(true)
				},
				(err) => {
					this.setFailed()
					this.setLoaded(true)
				},
			)
	}

	private setFailed() {
		this.header = this.translator.instant('common.something_went_wrong')
		this.description = this.translator.instant('common.please_try_again_later')
	}

	private setLoaded(loaded: boolean) {
		// Checking if load status has changed to avoid emiting same status multiple times
		if (loaded !== this.loaded) {
			this.loaded = loaded
			this.loading.emit(!loaded)
		}
	}
}
