import { Component, OnInit, Input } from '@angular/core'

@Component({
	selector: 'eliq-property-icon',
	templateUrl: './property-icon.component.html',
	styleUrls: ['./property-icon.component.css'],
	standalone: true,
})
export class PropertyIconComponent {
	@Input() imgPath = ''
	@Input() altText = ''

	constructor() {}
}
