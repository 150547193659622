<footer>
	<section class="main">
		<!-- The company name and privacy policy -->
		<section class="intro">
			<h1>{{header["title"]}}</h1>
			<eliq-link [colorKey]="'footer-contrast'" [leftIcon]="false" [href]="header['link']['url']" [label]="header['link']['text']"></eliq-link>
		</section>

		<!-- FAQ, Contact Us, Privacy Policy & Other custom links added by client -->
		<section class="links">
			<nav>
				<ng-container *ngFor="let link of links">
					<eliq-link [colorKey]="'footer-contrast'" [leftIcon]="false" [href]="link.url">{{ link.text }}</eliq-link>
				</ng-container>
			</nav>
		</section>
	</section>
	<!-- Copyright-->
	<section class="copyright">
		<p>{{footer["text"]}}</p>
	</section>
</footer>
