<div class="budget-item-container" (click)="budgetClicked.emit()">
  <p class="primary-p1" style="margin: 0;"><b>{{title}}</b></p>
  <div class="date-and-cost" style="margin: 16px 0;">
    <span class="secondary-p2">{{dateString}}</span>
    <span class="secondary-p2" [innerHTML]="costString"></span>
  </div>
  <div class="bar-container">
    <div class="bar outer-bar budget-home-card-outer-bar">
      <div [style.width.%]="budgetPercentage" class="bar inner-bar"
        [ngClass]="'budget-home-card-inner-bar-' + (budgetPercentage == 100 ? 'exceeded' : 'below')"></div>
    </div>
  </div>
  <p class="secondary-p3" style="margin-bottom: 0;" *ngIf="newPeriodMessage">{{newPeriodMessage}}</p>
</div>
