import { Component, OnInit, Input, inject } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'
import { Router } from '@angular/router'
import {
	EliqTrackingService,
	TrackingEventName,
	EliqNotificationOpenedEvent,
	ViewNotificationEvent,
	TrackingScreen,
} from '@eliq/core/tracking'
import { TranslateModule } from '@ngx-translate/core'
import { LinkComponent } from '@eliq/ui'
import { NotificationItemMonthlyInsightsComponent } from '../notification-item-monthly-insights/notification-item-monthly-insights.component'
import { NotificationItemTargetComponent } from '../notification-item-target/notification-item-target.component'
import { NotificationItemAbnormalComponent } from '../notification-item-abnormal/notification-item-abnormal.component'
import { NotificationItemClaimAndRequestComponent } from '../notification-item-claim-and-request/notification-item-claim-and-request.component'
import { NotificationItemOutageComponent } from '../notification-item-outage/notification-item-outage.component'
import { NotificationItemAnomalyComponent } from '../notification-item-anomaly/notification-item-anomaly.component'
import { NotificationItemBillComponent } from '../notification-item-bill/notification-item-bill.component'
import { NotificationItemInfoComponent } from '../notification-item-info/notification-item-info.component'
import { NotificationItemMonitorComponent } from '../notification-item-monitor/notification-item-monitor.component'
import { NotificationItemReportComponent } from '../notification-item-report/notification-item-report.component'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-notification-item',
	templateUrl: './notification-item.component.html',
	styleUrls: ['./notification-item.component.css'],
	standalone: true,
	imports: [
		NgIf,
		NotificationItemReportComponent,
		NotificationItemMonitorComponent,
		NotificationItemInfoComponent,
		NotificationItemBillComponent,
		NotificationItemAnomalyComponent,
		NotificationItemOutageComponent,
		NotificationItemClaimAndRequestComponent,
		NotificationItemAbnormalComponent,
		NotificationItemTargetComponent,
		NotificationItemMonthlyInsightsComponent,
		LinkComponent,
		TranslateModule,
	],
})
export class NotificationItemComponent{
	@Input() notification: NotificationData
	@Input() index: number

	public router = inject(Router)
	public tracking = inject(EliqTrackingService)

	linkClicked(href): void {
		const eventData: ViewNotificationEvent = {
			v: 2,
			from: TrackingScreen.Notifications,
			header: this.notification.$header,
			eventType: this.notification.$eventType,
		}

		this.tracking.logEvent(TrackingEventName.ViewNotification, eventData)

		if (href.includes('://')) {
			window?.open(href, '_blank')
		} else {
			this.router.navigate([href])
		}
	}
}
