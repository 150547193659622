<div class="icons">
	<eliq-property-icon
		[imgPath]="'/assets/images/' + property.$key + '.svg' | imgSrc"
		[altText]="property.$key"
	></eliq-property-icon>
</div>
<!-- <p class="secondary-p2 question">
	{{'home_profile_prompt.question_' + property.$key | translate}}
</p> -->
<ng-container *ngIf="property.$key === 'yearlyenergy'">
	<p
		class="question secondary-p2"
		style="white-space: pre-line;"
		[innerHTML]="(
		'home_profile_prompt.question_' + property.$key
			| translate: { value:
				'<strong>'+(
					estimationKwh !== '-' ? (estimationKwh | eliqNumber: 'energy': '1.0-0') : '-'
				)+'</strong>'
			}).replaceAll('\\n', '\n')"
	></p>

	<div *ngIf="estimationKwh" style="height: 10ch; width: 100%;">
		<eliq-spinner
			*ngIf="estimationKwh === '-'"
			[size]="'medium'"
		></eliq-spinner>
	</div>
</ng-container>

<ng-container *ngIf="property.$key !== 'yearlyenergy'">
	<p
		class="question secondary-p2"
		style="white-space: pre-line;"
		[innerHTML]="(
		'home_profile_prompt.question_' + property.$key
			| translate
			).replaceAll('\\n', '\n')"
	></p>
</ng-container>

<!--<p class="subtext" translate>{{'settings_location_properties.'+title}}</p>-->

<form [formGroup]="form" class="secondary-p2">
	<div class="form-flexbox-container">
		<mat-form-field appearance="outline" class="form-item">
			<input
				matInput
				type="text"
				(focus)="onFocusInput($event)"
				(blur)="onBlurInput($event)"
				(keydown)="sizeKeydown($event)"
				(keyup)="sizeKeyup($event)"
				autocomplete="off"
				formControlName="homesize"
				[id]="randomId + 0"
			/>
		</mat-form-field>
		<label for="randomId+0" class="input-label-text">
			<ng-container *ngIf="suffix === 'm^2'">
				m
				<span class="power">2</span>
			</ng-container>
			<ng-container *ngIf="suffix === 'energy'">kWh</ng-container>
		</label>
	</div>
</form>

<mat-slider
	[min]="property.$limits.min"
	[max]="property.$limits.max"
	[step]="property.$limits.step"
	#ngSlider
>
	<input
		matSliderThumb
		[value]="property.$placeholder"
		(input)="sliderMoved({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})"
		#ngSliderThumb="matSliderThumb"
	/>
</mat-slider>
<div class="sliderlabelcontainer secondary-p2">
	<div class="sliderlabel">
		{{ property.$limits.min }}
		<ng-container *ngIf="suffix === 'm^2'">
			m
			<span class="power">2</span>
		</ng-container>
		<ng-container *ngIf="suffix === 'energy'">kWh</ng-container>
	</div>
	<div class="sliderlabel">
		{{ property.$limits.max }}
		<ng-container *ngIf="suffix === 'm^2'">
			m
			<span class="power">2</span>
		</ng-container>
		<ng-container *ngIf="suffix === 'energy'">kWh</ng-container>
	</div>
</div>
