//import 'zone.js'
//import 'zone.js/dist/long-stack-trace-zone.js'

import {
	mergeApplicationConfig,
	ApplicationConfig,
	importProvidersFrom,
	APP_ID,
} from '@angular/core'
import { baseConfig } from './app.config.base'
import { dynamicRoutes } from './app.routes'
import {
	NoPreloading,
	provideRouter,
	withEnabledBlockingInitialNavigation,
	withPreloading,
} from '@angular/router'
//import { provideClientHydration } from '@angular/platform-browser'
import { CookieModule } from 'ngx-cookie'
import { provideLottieOptions } from 'ngx-lottie'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

const _appConfig: ApplicationConfig = {
	providers: [
		//provideServerRendering(),
		//provideClientHydration(),
		importProvidersFrom(CookieModule.withOptions(), BrowserAnimationsModule), //BrowserAnimationsModule),
		provideRouter(
			dynamicRoutes,
			// all these below probably aren't needed but I have them here for research.
			// TODO figure out which RouterFeatures[] we need
			withPreloading(NoPreloading),
			withEnabledBlockingInitialNavigation(),
			//withDebugTracing(),
			//withDebugTracing(),
		),
		{ provide: APP_ID, useValue: 'clientApp' },
		provideLottieOptions({
			player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
		}),
	],
}

export const appConfig = mergeApplicationConfig(baseConfig, _appConfig)
