<div class="location-switch-background">
	<div
		class="selectiontext"
		[popper]="homesPopper"
		[popperTrigger]="'click'"
		[popperPlacement]="'bottom'"
		[popperApplyClass]="'myPopper'"
		[popperApplyArrowClass]="'popperArrow'"
		(popperOnShown)="popperShowing=true"
		(popperOnHidden)="popperShowing=false"
	>
		<span class="primary-p2">
			{{!currentLocation?.name ? currentLocation?.address?.postal_code : currentLocation?.name}}
		</span>
		<span class="arrow down location-switch-arrow"></span>
	</div>
</div>

<popper-content #homesPopper>
	<ul class="list">
		<ng-container *ngFor="let location of locations">
			<li
				class="home"
				(click)="setCurrentLocation(location.id); homesPopper.hide();"
				[class.disabled]="!(location?.fuels?.length ?? 0)"
			>
				<p
					class="hometext primary-p2"
					[class.hometext--selected]="location.id === currentLocation.id"
				>
					{{!location.name ? location.address.postal_code : location.name}}
				</p>
			</li>
		</ng-container>
	</ul>
</popper-content>
