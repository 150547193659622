import { Component, OnInit, Input } from '@angular/core'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { map, switchMap } from 'rxjs/operators'
import {
	BillingHandlerService,
	BillViewerService,
	Invoice,
} from '@eliq/feature/bills'
import { NotificationData } from '../../models/NotificationData.model'
import { NotificationDataSourceBill } from '../../models/NotificationDataSourceBill.model'
import { BlobHelper } from '@eliq/util'
import { SnackbarService } from '@eliq/ui/snackbar'
import {
	EliqTrackingService,
	TrackingEventName,
	ViewNotificationEvent,
	TrackingScreen,
} from '@eliq/core/tracking'
import { EliqCurrencyPipe } from '@eliq/core/pipes/currency.pipe'
import { LinkComponent, SpinnerComponent } from '@eliq/ui/common'
import { BasicButtonComponent } from '@eliq/ui'
import { NgIf, DatePipe } from '@angular/common'

@Component({
	selector: 'eliq-notification-item-bill',
	templateUrl: './notification-item-bill.component.html',
	styleUrls: ['./notification-item-bill.component.css'],
	standalone: true,
	imports: [
		NgIf,
		LinkComponent,
		SpinnerComponent,
		DatePipe,
		EliqCurrencyPipe,
		TranslateModule,
	],
})
export class NotificationItemBillComponent implements OnInit {
	@Input() notification?: NotificationData

	public notificationSource?: NotificationDataSourceBill
	public invoice?: Invoice

	public billLoading = false

	public showSimpleBill = false

	constructor(
		private billingHandlerService: BillingHandlerService,
		private tracking: EliqTrackingService,
		private billViewer: BillViewerService,
		private snackbar: SnackbarService,
		private translator: TranslateService,
	) {}

	ngOnInit() {
		this.notificationSource = <NotificationDataSourceBill>(
			this.notification!.$source
		)
		this.invoice = this.notificationSource.getInvoice()
		if (!this.invoice.$amount) {
			// this is some other type of old notification. it doesnt have the normal bill values
			this.showSimpleBill = true
		}
	}

	viewBillClicked() {
		const eventData: ViewNotificationEvent = {
			v: 2,
			from: TrackingScreen.Notifications,
			header: this.notification!.$header,
			eventType: this.notification!.$eventType,
		}

		// TODO here we used EliqNotificationOpened before,
		// look in commit history before the AT3-1455 change to see how it was done.
		// Which way is correct?
		this.tracking.logEvent(TrackingEventName.ViewNotification, eventData)

		this.billLoading = true

		this.billViewer
			.getBillWithoutBillingAccountId(this.invoice!.$id)
			.pipe(
				switchMap((invoice) => {
					return this.billingHandlerService
						.getTransactionFile(invoice.$billingAccountId, invoice.$id)
						.pipe(
							map((data) => {
								this.billLoading = false
								BlobHelper.downLoadFile(
									`${invoice.$invoiceNumber}.pdf`,
									data,
									'application/pdf',
								)
							}),
						)
				}),
			)
			.subscribe(
				{
					next: (result) => {
					this.billLoading = false
				},
					error: (error) => {
						this.billLoading = false
						this.snackbar.doTextToast(
							this.translator.instant('common.something_went_wrong') +
								' ' +
								this.translator.instant('common.please_try_again_later'),
						)
					},
				})
	}
}
