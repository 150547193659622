import { Injectable } from '@angular/core'
import { JsonGetterService } from '../json-getter.service'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { CommonConfig, ConfigButton, ConfigButtonTypes } from '../../models/common-configs.model'


@Injectable({
	providedIn: 'root',
})
export class CommonConfigService {
	constructor(private config: JsonGetterService) {}

	private getCommonConfig(): Observable<CommonConfig> {
		return this.config.getConfig('common')
	}

	/**
	 * Gets the value of a potential underline button icon left path
	 * @returns a string for the path (only name and .svg ending), or undefined if there shouldnt be any left icon
	 */
	underlineButtonLeftIconPath(): Observable<string | undefined> {
		return this.getCommonConfig().pipe(
			map((response) => response.underline_button_icon_left),
			catchError((err) => of(undefined)),
		)
	}

	getDatesConf(): Observable<CommonConfig['dates']> {
		return this.getCommonConfig().pipe(
			map((response) => response.dates),
			catchError((err) => of(undefined)),
		)
	}

	/**
	 * Returns whether or not all primary buttons should be ALL CAPS.
	 * If not specified by the app's config, returns false by default.
	 * @returns
	 */
	primaryButtonAllCaps(): Observable<boolean> {
		return this.getCommonConfig().pipe(
			map((config) => config.button_options.primary_button_all_caps),
			catchError(() => of(false)), // default to false if anything doesnt work
		)
	}

	dailyGraphShiftRight(): Observable<number | boolean> {
		return this.getCommonConfig().pipe(
			map((config) => config.daily_graph_shift_right ?? false),
			catchError(() => of(false)), // default to false if anything doesnt work
		)
	}

	/**
	 * Gets the button type, either primary secondary or underline based on a given buttonId.
	 * If there is no matching buttonId, defaults to "primary" button type.
	 * If buttonId is undefined, returns "primary" as well
	 * @param buttonId
	 * @returns
	 * {
	 * 	type: 'primary' | 'secondary' | 'underline',
	 * 	styles: Record<string, string>
	 * }
	 */
	getButton(buttonId: string): Observable<ConfigButton> {
		return this.getCommonConfig().pipe(
			map((config) => {
				const defaultButton = {id: buttonId, type: ConfigButtonTypes.PRIMARY, style: {} as Record<string, string>}
				if (buttonId == undefined) return defaultButton
				const button = config.button_types.find(
					(button: any) => button.id == buttonId,
				)

				if (!button) {
					console.warn(`No button type found for buttonId: ${buttonId}`)
					return defaultButton
				}

				if (!button.style) {
					button["style"] = {} as Record<string, string>
				}

				return button ? button : defaultButton // default to primary if there isnt anything specified.
			}),
		)
	}

	noWebComponentStyles(): Observable<boolean> {
		return this.getCommonConfig().pipe(
			map((config) => config.no_web_component_styles ?? false),
			catchError(() => of(false)), // default to false if anything doesnt work
		)
	}
}
