import { Injectable } from '@angular/core'
import { PeriodType } from '@eliq/core'
import {
	TrackingEventName,
	DctHomeCardPeakDetailsClickedEvent,
	DctHomeCardMonthlyInsightsClickedEvent,
	DctInsightsInfoClickedEvent,
	EliqTrackingService,
	DctInsightsPeakDetailsClickedEvent,
	DctPeakDetailsLinkClickedEvent,
} from '@eliq/core/tracking'

@Injectable({
	providedIn: 'root',
})
export class DynamicCapacityTariffsTrackingService {
	constructor(private tracking: EliqTrackingService) {}

	homeCardPeakDetailsClicked() {
		const data: DctHomeCardPeakDetailsClickedEvent = {
			v: 1,
		}
		this.tracking.logEvent(
			TrackingEventName.DctHomeCardPeakDetailsClicked,
			data,
		)
	}

	homeCardDctMonthlyInsightsClicked() {
		const data: DctHomeCardMonthlyInsightsClickedEvent = {
			v: 1,
		}
		this.tracking.logEvent(
			TrackingEventName.DctHomeCardMonthlyInsightsClicked,
			data,
		)
	}

	insightsDctInfoClicked(period: PeriodType) {
		const data: DctInsightsInfoClickedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.DctInsightsInfoClicked, data)
	}

	insightsDctPeakDetailsClicked(period: PeriodType) {
		const data: DctInsightsPeakDetailsClickedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(
			TrackingEventName.DctInsightsPeakDetailsClicked,
			data,
		)
	}

	peakDetailsLinkClicked() {
		const data: DctPeakDetailsLinkClickedEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.DctPeakDetailsLinkClicked, data)
	}
}
