import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core'
import { getCurrencySymbol, getLocaleCurrencyCode } from '@angular/common'
import { formatCurrency } from '@angular/common'
import { Observable } from 'rxjs'

@Pipe({
	name: 'eliqcurrency',
	standalone: true,
})
export class EliqCurrencyPipe implements PipeTransform {
	private myLocale: string
	constructor(@Inject(LOCALE_ID) input_locale: string) {
		this.myLocale = input_locale
	}

	isCurrencySymbolSuffix(
		currencyCode: string = getLocaleCurrencyCode(this.myLocale) || '',
		digitsInfo = '1.0-2',
		locale: string = this.myLocale,
		display: 'narrow' | 'wide' = 'narrow',
	): boolean {
		const symbolOffset = this.transform(
			1,
			currencyCode,
			display,
			digitsInfo,
			locale,
		)?.indexOf(getCurrencySymbol(currencyCode, display))

		if (!symbolOffset || !(symbolOffset > 0)) {
			return false
		}
		return true
	}

	transform(
		value: number,
		currencyCode: string = getLocaleCurrencyCode(this.myLocale) || '',
		display: 'narrow' | 'wide' = 'narrow',
		digitsInfo = '1.0-2',
		locale: string = this.myLocale,
	): string | null {
		return formatCurrency(
			value,
			locale,
			getCurrencySymbol(currencyCode, display),
			currencyCode,
			digitsInfo,
		)
	}

	/**
	 * gets the currency symbol for the app determined locale
	 * @param format either wide or narrow as strings
	 */
	_getCurrencySymbol(format?: 'wide' | 'narrow') {
		return getCurrencySymbol(
			getLocaleCurrencyCode(this.myLocale) || '',
			format ? format : 'narrow',
		)
	}

	/**
	 * Gets the currencyCode for example SEK, EUR or USD dependant on the app defined locale
	 */
	_getCurrencyCode() {
		return getLocaleCurrencyCode(this.myLocale)
	}
}
