import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import {
	FormGroup,
	FormBuilder,
	FormControl,
	FormGroupDirective,
	NgForm,
	Validators,
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { TicketStatusType } from '@eliq/feature/auth'
import { LoginHelperService } from '@eliq/feature/login/services/login-helper/login-helper.service'
import { ModalService } from '@eliq/ui/modal'
import { of, Subscription } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'
import { SigninMagiclinkHavingTroubleComponent } from '../signin-magiclink-having-trouble/signin-magiclink-having-trouble.component'
import { SigninMagiclinkVerificationComponent } from '../signin-magiclink-verification/signin-magiclink-verification.component'
import { LinkComponent } from '@eliq/ui'
import { BasicButtonComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { NgIf } from '@angular/common'
import { SigninCardTwoComponent } from '../../signin-card-two/signin-card-two.component'
import { Router } from '@angular/router'

export class SigninErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null,
	): boolean {
		const isSubmitted = form && form.submitted
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		)
	}
}

enum Headers {
	Login = 'sign_in_start.sign_in',
	VerifyMagiclink = 'sign_in_verify_magic_link.check_email_inbox',
	TroubleLoggingIn = 'sign_in_verify_magic_link.having_trouble_logging_in',
}

@Component({
	selector: 'eliq-signin-magiclink',
	templateUrl: './signin-magiclink.component.html',
	styleUrls: ['./signin-magiclink.component.scss'],
	standalone: true,
	imports: [
		SigninCardTwoComponent,
		NgIf,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		TranslateModule,
		BasicButtonComponent,
		LinkComponent,
		SigninMagiclinkVerificationComponent,
		SigninMagiclinkHavingTroubleComponent,
	],
})
export class SigninMagiclinkComponent implements OnInit {
	@Input() signUpUrl: string
	//@Input() integrationless = false
	@Output() logInDone = new EventEmitter()

	public inputText = ''
	public showErrors = false
	public isSubmitButtonClicked = false

	public form: FormGroup

	public signinErrorStateMatcher = new SigninErrorStateMatcher()

	public state = 'started' // "started, initiated, verifying, complete"

	public emailControl: AbstractControl
	public userEmail = ''
	// signin card variables
	public currentHeader = ''
	public showBackButton: boolean

	// subs
	private ticketSub?: Subscription

	constructor(
		private formBuilder: FormBuilder,
		private loginHelper: LoginHelperService,
		private modal: ModalService,
		private router: Router,
	) {}

	ngOnInit() {
		this.currentHeader = Headers.Login

		this.form = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		})

		const emailControl = this.form.get('email')
		if (emailControl) {
			this.emailControl = emailControl
		}
	}

	onSubmit(resend = false) {
		if (!this.form.valid) {
			this.showErrors = true
		} else {
			this.showErrors = false

			if (!resend) {
				this.setState('initiating')
			}

			if (this.ticketSub) {
				this.ticketSub?.unsubscribe()
				this.ticketSub = undefined
			}

			this.ticketSub = this.loginHelper
				.getMagiclinkTicket(this.emailControl.value)
				.pipe(
					tap((result) => this.setState('initiated')),
					switchMap((result) => {
						return this.loginHelper.startTicketVerificationAndLogin(
							result.ticket.id,
						)
					}),
				)
				.subscribe(
					(ticketStatus) => {
						if (ticketStatus == TicketStatusType.Completed) {
							this.ticketSub?.unsubscribe()
							this.logInDone.emit()
						}
					},
					(err) => {
						console.error(err)
						this.ticketSub?.unsubscribe()
						this.setState('started')
						this.handleError(err)
					},
				)
		}
	}

	public setState(state: string) {
		this.state = state
		switch (state) {
			case 'started': {
				this.showBackButton = false
				this.currentHeader = Headers.Login
				return
			}

			case 'initiating': {
				this.showBackButton = false
				this.currentHeader = Headers.Login
				return
			}

			case 'initiated': {
				this.showBackButton = true
				this.currentHeader = Headers.VerifyMagiclink
				return
			}

			case 'trouble': {
				this.showBackButton = true
				this.currentHeader = Headers.TroubleLoggingIn
				return
			}

			default:
				return
		}
	}

	sendNewEmail() {
		this.onSubmit(true)
		this.setState('initiated')
	}

	backClicked() {
		if (this.state === 'initiated') {
			this.setState('started')
		} else if (this.state === 'trouble') {
			this.setState('initiated')
		}
	}

	openSignUpPage() {
		if (this.signUpUrl?.at(0) === '/') {
			this.router.navigate([this.signUpUrl])
		} else {
			window?.open(this.signUpUrl, '_self')
		}
	}

	private handleError(error) {
		this.loginHelper.handleLoginError(error)
	}

	ngOnDestroy() {
		if (this.ticketSub) {
			this.ticketSub?.unsubscribe()
		}
	}
}
