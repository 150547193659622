<eliq-signin-card-two padding="16px">
	<div *ngIf="state === 'setup'; else loadingBlock">
		<eliq-homedetails-setup
			*ngIf="steps[currentStep] === 'home_profile_setup'"
			[askToStart]="true"
			[locationId]="loc?.id ?? 0"
			[locationName]="locName"
			[properties]="properties"
			[propertyGroups]="propertyGroups"
			(done)="stepCompleted()"
		></eliq-homedetails-setup>
		<eliq-signin-consent
			*ngIf="steps[currentStep] === 'consents'"
			[consents]="consents"
			(done)="stepCompleted()"
		></eliq-signin-consent>
	</div>
</eliq-signin-card-two>

<ng-template #loadingBlock>
	<div class="loading-container">
		<eliq-spinner size="medium" fullscreen="true"></eliq-spinner>
	</div>
</ng-template>
