<div class="container">
	<section class="header">
		<div
			id="errorModalIconContainer"
			class="img-container"
			[inlineSVG]="'/assets/images/Icon_fail.svg' | imgSrc"
			aria-label="'Error Icon'"
			[setSVGAttributes]="{'height': '50px'}"
		></div>
		<div class="error title">
			{{errorData.title ?? ('common.error' | translate)}}
		</div>
	</section>

	<p class="error message" *ngIf="errorData?.message?.length > 0">
		{{errorData.message ?? ('common.general_error' | translate)}}
	</p>

	<mat-expansion-panel class="error details" *ngIf="errorData?.debugData">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<h1 class="error subtitle" translate>common.details</h1>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<pre
			class="primary-p3 error debug-data"
			*ngIf="errorData?.debugData"
			>{{errorData.debugData}}</pre
		>
	</mat-expansion-panel>

	<div class="buttondiv">
		<eliq-basic-button
			[label]="translatedOk"
			(clicked)="okClick()"
		></eliq-basic-button>
	</div>
</div>
