import { Injectable, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { EliqApiHttpClient } from '@eliq/data-access'
import { TokenWrapper } from './ticket.service'
import { map, tap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'

@Injectable({
	providedIn: 'root',
})
export class LoginService {}
