import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-please-refresh-modal',
	templateUrl: './please-refresh-modal.component.html',
	styleUrls: ['./please-refresh-modal.component.scss'],
	standalone: true,
	imports: [TranslateModule],
})
export class PleaseRefreshModalComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<PleaseRefreshModalComponent>) {}

	ngOnInit(): void {}
}
