import { Component, HostBinding, Input, inject } from '@angular/core'
import {
	NgFor,
	NgIf,
	NgStyle,
	NgTemplateOutlet,
	NgComponentOutlet,
	NgClass,
} from '@angular/common'
import { EliqThemeService } from '@eliq/theme'
import { ImgSrcPipe } from '@eliq/core'
import { RouterLink } from '@angular/router'
import { InlineSVGModule } from 'ng-inline-svg-2'
@Component({
	selector: 'eliq-company-logo',
	standalone: true,
	imports: [
		NgClass,
		NgTemplateOutlet,
		NgComponentOutlet,
		NgStyle,
		NgIf,
		NgFor,
		InlineSVGModule,
		RouterLink,
		ImgSrcPipe,
	],
	templateUrl: './company-logo.component.html',
	styleUrls: ['./company-logo.component.scss'],
	//encapsulation: ViewEncapsulation.ShadowDom,
})
export class CompanyLogoComponent {
	@Input() type: 'primary' | 'contrast' = 'primary'
	@Input() width = 'auto'
	@Input() maxWidth = '100%'
	@Input() maxHeight = '100%'
	@Input() height = '5.5rem' // 5.5rem is the default height of the header which is the most common use case
	@Input() padding = '1rem'
	@Input() link: string | false = false

	public themeService = inject(EliqThemeService)

	public isSvg = this.type === 'contrast' ? true : false

	public svgAttributes = {}

	public linkIsAbsolute = () => (typeof this.link['includes'] === 'function' && (this.link as string).includes('://')) ?? false

	onSVGInserted(companyLogoSvg: SVGGraphicsElement | SVGElement): void {
		const nativeSvgEl = companyLogoSvg as SVGGraphicsElement
		const originalViewBox = nativeSvgEl.getAttribute('viewBox')

		nativeSvgEl.setAttribute('height', '100%')
		nativeSvgEl.setAttribute('width', '100%')
		nativeSvgEl.setAttribute('preserveAspectRatio', 'xMidYMid meet')
		const { width, height } = nativeSvgEl.getBBox()


		if (width && height) {
			nativeSvgEl.setAttribute(
				'style',
				`height: ${this.height}; width: ${this.width};`,
			)
		}


		const savedStyles = nativeSvgEl.getAttribute('style')


		if (!originalViewBox) {
			if (width && height) {
				nativeSvgEl.setAttribute('viewBox', `0 0 ${width} ${height}`)
			}
		}


		if (savedStyles) {
			nativeSvgEl.setAttribute(
				'style',
				`${savedStyles}
				padding: ${this.logoPadding};
				box-sizing: border-box;`,
			)
		}
	}

	get logoPadding() {
		return (
			(this.type === 'contrast'
				? this.themeService.getPropVal('logo-contrast-padding')
				: this.themeService.getPropVal('logo-primary-padding')) || this.padding
		)
	}

	get logoName() {
		return this.themeService.getPrefix() + ' logo'
	}

	private savedLogoSrc = ''

	getLogoSrc() {
		return this.logoSrc
	}

	get logoSrc() {
		if (this.savedLogoSrc !== '') {
			return this.savedLogoSrc
		}
		let _logoSrc = ''
		if (this.themeService) {
			_logoSrc =
				this.type === 'contrast'
					? this.themeService.getPropVal('logo-contrast')
					: this.themeService.getPropVal('logo-primary')
		}
		//if (!_logoSrc) {
		//_logoSrc = '/assets/company/images/logo-primary.svg'
		//}

		// Doing lots of checks because this caused issues for iOS 15 support. Seems unnecessary but question marks weren't enough and I'd rather be safe than sorry.
		if (
			_logoSrc['split'] &&
			_logoSrc['split']('/').length > 1 &&
			typeof _logoSrc['split']('/')['at'] === 'function'
		) {
			const logoSrcName = _logoSrc?.split('/')?.at(-1)?.split('?')[0] ?? ''
			this.isSvg = logoSrcName?.endsWith('.svg') ?? false

			if (_logoSrc) {
				this.savedLogoSrc = _logoSrc
			}
		}

		return _logoSrc
	}
}
