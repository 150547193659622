// @ts-nocheck

import { Property } from './Property.model'
import { TranslateService } from '@ngx-translate/core'
import {
	APIPossibleValue,
	APIProperty,
} from '../api-models/api-home-profile.model'

export class PropertyMultipleSelect extends Property {
	private value: string[]
	private possibleValues: APIPossibleValue[]

	constructor(apiProperty: APIProperty) {
		super(apiProperty)
		this.value = apiProperty.value
		this.possibleValues = apiProperty.possible_values
	}

	public getValue() {
		return this.value
	}

	public savePlaceholder() {
		if (this.$placeholder === undefined) return
		this.value = Object.assign([], this.$placeholder)
		this.$placeholder = undefined
	}

	public getCurrentSelectionString(
		translator: TranslateService,
		maxChars?: number,
	): string {
		maxChars = maxChars ? maxChars : 20
		const workingValue = this.$placeholder ? this.$placeholder : this.value

		if (workingValue === undefined) return undefined // we might have both value and placeholder being udnefined!

		let nameString = this.translateKeysToNameString(translator, workingValue)
		if (nameString.length > maxChars) {
			nameString = nameString.substring(0, maxChars) + '...'
		}

		return nameString
	}

	public hasChanges(): boolean {
		return this.$placeholder !== undefined && this.$placeholder !== this.value
	}

	private translateKeysToNameString(
		translator: TranslateService,
		keys: string[],
	): string {
		if (keys === undefined) return undefined
		const names = keys.map((key) => {
			return translator.instant('home_profile_options.' + key)
		})

		let returnStr = ''
		names.forEach((name) => {
			if (returnStr !== '') {
				returnStr += ', '
			}

			returnStr += name
		})

		return returnStr
	}

	public getCurrentSelection(): string[] {
		const currentSelection = this.$placeholder ? this.$placeholder : this.value
		return currentSelection
	}

	/**
	 * Getter $value
	 * @return {string[]}
	 */
	public get $value(): string[] {
		return this.value
	}

	/**
	 * Getter $possibleValues
	 * @return {APIPossibleValue[]}
	 */
	public get $possibleValues(): APIPossibleValue[] {
		return this.possibleValues
	}

	/**
	 * A multiple select is always true?
	 */
	public hasValidSelection() {
		const selection = this.getCurrentSelection()
		if (selection) {
			// its not undefined,
			return selection.length > 0
		} else return false
	}
}
