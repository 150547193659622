import { Component, OnInit } from '@angular/core'
import { Input } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'
import { NotificationDataSourceOutage } from '../../models/NotificationDataSourceOutage.model'
import { LinkComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-notification-item-outage',
	templateUrl: './notification-item-outage.component.html',
	styleUrls: ['./notification-item-outage.component.css'],
	standalone: true,
	imports: [LinkComponent],
})
export class NotificationItemOutageComponent implements OnInit {
	@Input() notification: NotificationData

	public outageNotification: NotificationDataSourceOutage

	constructor() {}

	ngOnInit(): void {
		this.outageNotification = <NotificationDataSourceOutage>(
			this.notification.$source
		)
	}
}
