<eliq-card
	*ngIf="loaded else loadingBlock"
	class="eliq-default-card-style container"
>
	<section class="top-row">
		<h6 class="primary-h6 header">
			{{ 'submit_meter_read.title' | translate }}
		</h6>
		<eliq-info-button
			(clicked)="infoButtonClicked()"
			class="info-icon"
		></eliq-info-button>
	</section>
	<div class="meter-list">
		<div class="divider" *ngFor="let meter of meters">
			<eliq-smr-card-content
				[meters]="meters"
				[meter]="meter"
			></eliq-smr-card-content>
		</div>
	</div>
	<div class="view-reads-button">
		<eliq-flexible-button
			(clicked)="viewHistoryButtonClicked()"
			buttonId="home_submit_meter_read_card_view_read_history_button"
			label="{{ 'submit_meter_read.view_read_history' | translate }}"
		></eliq-flexible-button>
	</div>
</eliq-card>

<ng-template #loadingBlock>
	<eliq-card class="eliq-default-card-style container">
		<div class="loading">
			<eliq-loading-spinner></eliq-loading-spinner>
		</div>
	</eliq-card>
</ng-template>
