<div class="container">
	<div class="x" (click)="closeClicked()">
		<eliq-x-button></eliq-x-button>
	</div>
	<div class="inline">
		<h6 class="primary-h6 header">Meter history</h6>
		<eliq-info-button
			(clicked)="infoButtonClicked()"
			class="info-icon"
		></eliq-info-button>
	</div>

	<div style="margin-top: 35px; text-align: center;">
		<eliq-smr-select-meter-dropdown
			[options]="meterIds"
			[labels]="meterLabels"
			[label]="'Select meter'"
			[selectedCtrl]="meterIdCtrl"
		></eliq-smr-select-meter-dropdown>
	</div>
	<div class="meter-list" *ngIf="loaded else loadingSpinner">
		<div class="divider" *ngFor="let meterRead of meterReads">
			<eliq-smr-view-history-modal
				[meterRead]="meterRead"
				[meter]="meter"
			></eliq-smr-view-history-modal>
		</div>
	</div>
	<div class="button">
		<eliq-basic-button
			label="{{ 'submit_meter_read.submit' | translate }}"
			[disabled]="!loaded"
			(clicked)="showSubmitReadModal()"
		></eliq-basic-button>
	</div>
</div>

<ng-template #loadingSpinner>
	<div class="loading-container">
		<eliq-spinner size="medium"></eliq-spinner>
	</div>
</ng-template>
