/**
 * @param t - The object to check
 * @param k - The key to check
 * @param v - The value to check, or a function that takes v as input and returns true if v is of the correct type.
 * If v is a function it must also return false if it should not be true.
 * @returns `true` if `typeof t[k] === v` or `v(t[k]) === true`
 * @note
 * here I would want k to be Readonly<string | symbol> but there is a bug with our current typescript version.
 * @see https://github.com/microsoft/TypeScript/issues/1863
 */
export const hasField = <
	K extends Readonly<string>,
	V extends Readonly<string> | never,
	T,
>(
	t: unknown,
	k: K, // this should be Readonly<string | symbol>
	v: V | ((v: unknown) => v is T),
): t is {
	[key in K]: T extends AllTypes ? T : MapStrTypeToType<typeof v>
} => {
	if (!!t && typeof t === 'object' && k in t) {
		const _t = t as Record<typeof k, MapStrTypeToType<typeof v>>
		// I want to remove this if statement below. Don't think I can, because of the symbol bug.
		if (typeof k !== 'string' || typeof _t !== 'object') {
			return false
		}
		if (
			(typeof v === 'string' && typeof _t[k] === v) ||
			(typeof v === 'function' && v(_t[k]))
		) {
			return true
		}
	}
	return false
}

export type AllTypesStr =
	| 'string'
	| 'number'
	| 'boolean'
	| 'object'
	| 'array'
	| 'null'
	| 'undefined'
	| 'function'
	| 'bigint'
	| 'symbol'

export type AllTypes =
	| string
	| number
	| boolean
	| ((...args: unknown[]) => unknown)
	| Record<string, unknown>
	| unknown[]
	| null
	| undefined
	| bigint
	| symbol

export type MapStrTypeToType<T> = 'string' extends T
	? string
	: 'number' extends T
	? number
	: 'boolean' extends T
	? boolean
	: 'array' extends T
	? unknown[]
	: 'null' extends T
	? null
	: 'undefined' extends T
	? undefined
	: 'function' extends T
	? (...args: unknown[]) => unknown
	: 'bigint' extends T
	? bigint
	: 'symbol' extends T
	? symbol
	: 'object' extends T
	? // eslint-disable-next-line @typescript-eslint/ban-types
	  object
	: never

export const isStringRecord = (v: unknown): v is Record<string, string> =>
	typeof v === 'object' &&
	Object.values(v as object).every((x) => typeof x === 'string')

export const whichIsStringRecord = (v: unknown): v is Record<string, string> =>
	isStringRecord(v)

export const whichIsThisString = <T extends Readonly<string>>(
	literal: T,
): ((v: unknown) => v is T) => {
	const vf = (v: unknown): v is T => (v as string) === literal
	return vf
}

export const whichIsThisLiteral = <T extends Readonly<AllTypes>>(
	literal: T,
): ((v: unknown) => v is T) => {
	return (v: unknown): v is T => {
		if (v === literal) {
			return true
		} else {
			return false
		}
	}
}

export const whichIs = <T extends Readonly<AllTypes>>(
	literal: T,
): ((v: unknown) => v is T) => {
	// types that work with ===
	return (v: unknown): v is T => {
		if (v === literal) {
			return true
		}
		if (!literal) {
			return false
		}
		if (!Object.values(v as any)['length']) {
			return false
		}
		if (Array.isArray(v) && v.every((x, i) => x === (literal as any)[i])) {
			return true
		}
		if (JSON.stringify(v) === JSON.stringify(literal)) {
			return true
		}
		return false
	}
}

//import 'reflect-metadata'
/**
 *
 * Usage: `
 * const someVar = "test"
 * const someVarIsString = new TypeC<string>().is(someVar)`
 * someVarIsString // <-- is true
 */
class TypeC<T> {
	private _meta: unknown

	public derived(other: unknown): other is T {
		return false
	}

	public is(other: unknown): other is T {
		return false
	}

	public get _type_metadata(): unknown {
		return this._meta
	}

	public set _type_metadata(value: unknown) {
		this._meta = value
	}
}

/**
 *
 * @example
 * ```
 * interface SomeType {
 *  test: boolean
 * }
 * const someVar = {test: true, somethingelse: "hmm"}
 *
 * // Check if SomeType is implemented by someVar
 * const someVarImplementsIt = Type<SomeType>().derived(someVar)
 * someVarImplementsIt == true // it is implemented.
 *
 * // Check if some interface strictly adheres to an interface without extending it further.
 * const someVarIsIt = Type<SomeType>().is(someVar)
 * someVarIsIt == false // it is not exactly the same as the interface.
 * ```
 */
export default function Type<T>(): TypeC<T> {
	return new TypeC<T>()
}
