import { Inject, Injectable } from '@angular/core'
//import { Level } from 'level'
import { TransferState, StateKey, makeStateKey } from '@angular/core'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { PLATFORM_ID } from '@angular/core'
import { Observable, delay, map, of, throwError } from 'rxjs'
import * as CryptoJS from 'crypto-js'
import { get, set, del, clear } from 'idb-keyval'
import { HttpHeaders, HttpRequest } from '@angular/common/http'

@Injectable({
	providedIn: 'root',
})
export class CacheService {
	private isServer = false
	private isBrowser = false

	private store!: {
		get: <T>(key: string) => Promise<T | null | undefined>
		set: (key: string, value: string) => any
		del: (key: string) => void
		clear: () => void
	}
	//private store!: Level
	constructor(
		private transferState: TransferState,
		@Inject(PLATFORM_ID) private platformId: object,
	) {
		this.isServer = isPlatformServer(this.platformId)
		this.isBrowser = isPlatformBrowser(this.platformId)

		if (this.isServer && !this.isBrowser) {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const cache = require('memory-cache')
			this.store = {
				get: (key) => {
					return cache.get(key)
				},
				set: (key, value) => {
					return cache.put(key, value)
				},
				del: (key) => {
					return cache.del(key)
				},
				clear: () => {
					return cache.clear()
				},
			}
		} else {
			this.store = {
				get,
				set,
				del,
				clear,
			}
		}
		/*this.store = new Level('cache', {
			valueEncoding: 'json',
			cacheSize: 8 * 1024 * 1024,
		})*/
	}

	makeHttpCacheKey(requestUrl: string): string
	makeHttpCacheKey(req: HttpRequest<unknown>): string
	makeHttpCacheKey(req: HttpRequest<unknown> | string): string {
		if (typeof req === 'string') {
			return req
		}
		if (req.url.includes('/v3/translations/')) {
			return req.url
		}
		return (
			req?.urlWithParams +
			CryptoJS.MD5(
				((
					req.headers
						?.keys()
						?.map((key) => key + req.headers?.get(key) ?? '') ?? []
				).join('') ?? '') +
					(req?.serializeBody()?.toString() ?? '') +
					(req?.urlWithParams ?? ''),
			).toString(CryptoJS.enc.Base64)
		)
	}

	public get<T>(key: string): Observable<T | null> {
		// if (this.isBrowser) {
		// 	const serverStateKey = makeStateKey<T>(key)
		// 	const serverState = this.transferState.get(serverStateKey, null)
		// 	if (serverState) {
		// 		this.set(key, serverState)
		// 		this.transferState.remove(serverStateKey)
		// 		return new Observable((observer) => {
		// 			observer.next(serverState)
		// 			observer.complete()
		// 		})
		// 	}
		// }

		return new Observable((observer) => {
			let storedValue: T | null | Promise<T | null | undefined> = null
			try {
				storedValue = this.store?.get(key) ?? null
				if (storedValue && typeof storedValue['then'] === 'function') {
					if (!storedValue) {
						observer.next(null)
						observer.complete()
					}
					storedValue
						?.then((val: any) => {
							if (val && this.isServer) {
								const serverStateKey = makeStateKey<T>(key)
								this.transferState.set(serverStateKey, JSON.parse(val))
							}

							if (val) {
								observer.next(JSON.parse(val))
								return observer.complete()
							} else {
								observer.next(void 0),
									throwError(
										() => new Error("value was empty, couldn't cache it."),
									)
								return
							}
						})
						.catch((e) => {
							observer.next(null)
							observer.complete()
						})
				} else if (typeof storedValue === 'string') {
					observer.next(JSON.parse(storedValue))
					observer.complete()
				} else if (storedValue) {
					observer.next(storedValue as T)
					observer.complete()
				} else {
					observer.next(null)
					observer.complete()
				}
			} catch (e) {
				observer.next(null)
				observer.complete()
			}
		})
	}

	public set<T>(key: string, value: T): void {
		if (this.isServer) {
			const serverStateKey = makeStateKey<T>(key)
			this.transferState.set(serverStateKey, value)
		}
		//this.store.put(key, JSON.stringify(value))
		this.store?.set(key, JSON.stringify(value))
	}

	public delete(key: string): void {
		const serverStateKey = makeStateKey<unknown>(key)
		if (this.transferState.hasKey(serverStateKey)) {
			this.transferState.remove(serverStateKey)
		}
		//this.store.del(key)
		this.store?.del(key)
	}

	public clear(): void {
		//this.store.clear()
		this.store?.clear()
	}
}
