export class APIAdvice {
	id: string
	// User feedback state string (implemented, skipped, etc)
	status: AdviceStatus
	// Title and content is already translated as of now when received
	title: string
	content: string
	// Estimated savings
	estimated_yearly_savings_energy: number
	estimated_yearly_savings_cost: number
	// These might or might not be used now
	estimated_savings?: number
	estimated_savings_energy?: number
	estimated_savings_cost?: number
	key?: string
	// Relevance
	relevance: number
	link?: {
		url: string
		text: string
	}
	activated_at: string
	updated_status_at: string
}

export enum AdviceStatus {
	None = 'none',
	Skipped = 'skipped',
	NotRelevant = 'not_relevant',
	Implemented = 'implemented',
	SaveForLater = 'save_for_later',
}

export const AdviceStatusNumber = {
	none: 0,
	skipped: 1,
	not_relevant: 2,
	implemented: 3,
	save_for_later: 4,
}
