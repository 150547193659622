import { Pipe, PipeTransform } from '@angular/core'
import { differenceInCalendarDays } from 'date-fns'

@Pipe({
	name: 'daysLeft',
	standalone: true,
})
export class DaysLeftPipe implements PipeTransform {
	transform(value: string | Date): number {
		return differenceInCalendarDays(new Date(value), new Date())
	}
}
