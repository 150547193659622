<p class="date secondary-p3" [ngClass]="{'first': index === 0}">
	{{notification.$createdDate | eliqdate:{
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: yearFormat
		}
	}}
</p>
<eliq-card class="eliq-default-card-style" style="padding: 24px;">
	<h3 class="title primary-h6">{{notification.$header}}</h3>
	<eliq-notification-item
		[notification]="notification"
		[index]="index"
	></eliq-notification-item>
	<div *ngIf="!notification.$read" class="notification-circle"></div>
</eliq-card>
