import { Property } from '../homeprofile-models/Property.model'
import { PropertyGroup } from '../homeprofile-models/PropertyGroup.model'
export class APIAppliance {
	type!: string
	count!: number
}

export class APIProperty {
	name!: string
	key!: string
	data_type!: string
	is_required!: boolean

	// it can have this structure
	possible_values!: APIPossibleValue[]

	// or this structure below
	// value can be undefined if the user hasnt selected a value yet.
	value: number | string | undefined
	limits!: APILimits
	dependent_properties!: APIPropertyDependency[]
}

export class APIPossibleValue {
	name!: string
	value!: string
}

export class APIThingCount {
	name!: string
	key!: string
	data_type!: string
	is_required!: boolean
	// here the values will always be numbers, since the data_type always is number
	value!: number
	limits!: APILimits
}

export class APIYearlyConsumption {
	fuel!: string // elec for example
	type!: string
	year!: number
	source: string | undefined
	energy_wh: number | undefined
}

export function APIYearlyConsumptionToProperty(
	yearlyCons: APIYearlyConsumption | undefined,
) {
	const apiProp = new APIProperty()
	apiProp.name = 'yearlyenergy'
	apiProp.key = 'yearlyenergy'
	apiProp.data_type = 'number'
	apiProp.is_required = true
	// it can have this structure
	apiProp.possible_values = []

	// or this structure below
	// value can be undefined if the user hasnt selected a value yet.
	apiProp.value =
		(yearlyCons?.energy_wh ?? 0) > 0
			? Math.floor((yearlyCons?.energy_wh ?? 0) / 1000)
			: undefined
	apiProp.limits = { min: 0, max: 10e3 * 3, step: 1 }
	apiProp.dependent_properties = []
	return apiProp
}

export class APIHomeProfile {
	properties!: APIProperty[]
	cooking?: APIProperty[]
	cooling?: APIProperty[]
	appliances?: APIProperty[]
	fridges_and_freezers?: APIProperty[]
	extras?: APIProperty[]
	saunas_and_hot_tubs?: APIProperty[]
	yearly_consumption?: APIProperty[]
}

export class APILimits {
	min!: number
	max!: number
	step!: number
}

export class APIPropertyDependency {
	property!: string
	accepted_values!: string[]
}
