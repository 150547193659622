import {
	Component,
	OnInit,
	HostListener,
	Input,
	Output,
	EventEmitter,
	OnChanges,
} from '@angular/core'
import { AnnualCardInfoModalComponent } from '../annual-card-info-modal/annual-card-info-modal.component'
import { ModalService } from '@eliq/ui/modal'
import { AnnualCardAddModalComponent } from '../annual-card-add-modal/annual-card-add-modal.component'
import { TranslateService } from '@ngx-translate/core'
import { VariableTranslatorService } from '@eliq/core'
import { AnnualCardSuccessModalComponent } from '../annual-card-success-modal/annual-card-success-modal.component'
import { NgIf } from '@angular/common'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'

@Component({
	selector: 'eliq-annual-card',
	templateUrl: './annual-card.component.html',
	styleUrls: ['./annual-card.component.css'],
	standalone: true,
	imports: [CardComponent, NgIf],
})
export class AnnualCardComponent implements OnInit {
	@Input() lastYear!: number
	@Input() locId!: number
	@Input() greyBorder!: boolean
	@Input() userName!: string
	@Output() hide = new EventEmitter<boolean>()
	public getScreenWidth!: number
	public addButtonText = ''
	public introductionText = ''

	constructor(
		private modal: ModalService,
		private translator: TranslateService,
		private formatter: VariableTranslatorService,
	) {}

	ngOnInit(): void {
		this.getScreenWidth = window?.innerWidth
		this.addButtonText = this.translator.instant(
			'annual_consumption.add_button',
		)
		const rawGreeting = this.translator.instant('greetings.greetings_name')
		if (this.userName) {
			const greetUser = this.formatter.replaceVariable(
				rawGreeting,
				'{{name}}',
				this.userName,
			)
			this.introductionText = greetUser + '! '
		}
		this.introductionText += this.translator.instant(
			'annual_consumption.home_question',
		)
	}

	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.getScreenWidth = window?.innerWidth
	}

	public infoClicked() {
		const dialogRef = this.modal.openModal(AnnualCardInfoModalComponent)
		const instance = <AnnualCardInfoModalComponent>dialogRef.componentInstance
		instance.greyBorder = this.greyBorder
	}

	public addClicked() {
		const dialogRef = this.modal.openModal(AnnualCardAddModalComponent)
		const instance = <AnnualCardAddModalComponent>dialogRef.componentInstance
		instance.lastYear = this.lastYear
		instance.locId = this.locId
		instance.greyBorder = this.greyBorder
		instance.energy.subscribe((value) => {
			if (value > -1) {
				this.hide.emit(true)
				const dialogRef = this.modal.openModal(AnnualCardSuccessModalComponent)
				const instance = <AnnualCardSuccessModalComponent>(
					dialogRef.componentInstance
				)
				instance.lastYear = this.lastYear
				instance.greyBorder = this.greyBorder
				instance.estimatedEnergy = value
			}
		})
	}
}
