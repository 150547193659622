import { APIInvoice, Invoice } from '@eliq/feature/bills'
import { NotificationDataSource } from './NotificationDataSources.model'

export class NotificationDataSourceBill extends NotificationDataSource {
	private invoice: Invoice

	constructor(source: any) {
		super(source)
		const billSource = <APIInvoice>source
		this.invoice = new Invoice(billSource, undefined as unknown as string)
	}

	getInvoice() {
		return this.invoice
	}
}
