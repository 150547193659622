// @ts-nocheck

import { Injectable } from '@angular/core'
import { EliqApiHttpClient } from '@eliq/data-access'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { APIAccountBalance } from '../models/api-models/api-account-balance.model'
import { APIAgreement } from '../models/api-models/api-agreement.model'
import { APIBillingAccount } from '../models/api-models/api-billing-account.model'
import { APIInvoice } from '../models/api-models/api-invoice.model'
import { APITransaction } from '../models/api-models/api-transaction.model'

@Injectable({
	providedIn: 'root',
})
export class BillsApiService {
	constructor(private http: EliqApiHttpClient) {}

	public getBillingAccounts(userId: number | null) {
		return this.http.get<APIBillingAccount[]>(
			'/v3/users/' + userId + '/billingaccounts',
		)
	}

	public getBillingAccountsForLocation(locationId: number) {
		return this.http.get<APIBillingAccount[]>(
			'/v3/locations/' + locationId + '/billingaccounts',
		)
	}

	public getInvoices(billingAccountId: string) {
		return this.http.get<APIInvoice[]>(
			'/v3/billingaccounts/' + billingAccountId + '/invoices',
		)
	}

	getAccountBalance(billingAccountId: string): Observable<APIAccountBalance> {
		return this.http
			.get('/v3/billingaccounts/' + billingAccountId + '/accountbalance')
			.pipe(map((ab) => ab['account_balance']))
	}

	public getTransactions(
		billingAccountId: string,
		cursor?: string,
		limit?: number,
		transactionType?: string,
	) {
		const params = new Map<string, string>()
		if (cursor) params.set('cursor', cursor)
		if (limit) params.set('limit', limit.toString())
		if (transactionType) params.set('transaction', transactionType)

		return this.http.get<APITransaction[]>(
			'/v3/billingaccounts/' +
				billingAccountId +
				'/transactions' +
				this.stringifyParams(params),
		)
	}

	public getTransactionFile(
		billingAccountId: string,
		invoiceId: string,
	): Observable<Blob> {
		const url = `/V3/billingaccounts/${billingAccountId}/transactions/${invoiceId}/pdf`
		return this.http.getBlob(url)
	}

	public getAgreements(billingAccountId: string) {
		return this.http.get<APIAgreement[]>(
			'/v3/billingaccounts/' + billingAccountId + '/agreements',
		)
	}

	private stringifyParams(params: Map<string, string>): string {
		let str = ''
		params.forEach((value, key) => {
			if (str == '') str += `?${key}=${value}`
			else str += `&${key}=${value}`
		})

		return str
	}
}
