import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewEncapsulation,
	ElementRef,
} from '@angular/core'
import { Router } from '@angular/router'
import { ViewChild } from '@angular/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { NotificationsPopperComponent } from '../notifications-popper/notifications-popper.component'
import { NgIf } from '@angular/common'
import { InlineSVGModule } from 'ng-inline-svg-2'
import {
	NgxPopperjsModule,
	NgxPopperjsPlacements,
	NgxPopperjsTriggers,
} from 'ngx-popperjs'

@Component({
	selector: 'eliq-notification-bell',
	templateUrl: './notification-bell.component.html',
	styleUrls: ['./notification-bell.component.scss'],
	standalone: true,
	imports: [
		InlineSVGModule,
		NgIf,
		NotificationsPopperComponent,
		ImgSrcPipe,
		NgxPopperjsModule,
	],
})
export class NotificationBellComponent {
	@Input() count = 0
	@Input() isPopperDisabled = false
	@Input() height = 30
	@Input() width = 30

	@Output() showAllClicked: EventEmitter<any> = new EventEmitter<any>()

	@ViewChild('notificationsPopper') notPopper: any

	public svgAttributes = {
		width: this.height,
		height: this.width,
		display: 'block',
	}

	public popperPlacement = NgxPopperjsPlacements.BOTTOM
	public popperTrigger = NgxPopperjsTriggers.click

	constructor(public elRef: ElementRef<HTMLElement>, private router: Router) {}

	bellClicked() {
		// if the popper is disabled and we click the bell, we go directly to all notifications.
		// so this would be the same as saying that the user clicked the "show all button"
		// which is shown when opening the popper.
		if (this.isPopperDisabled) {
			this.showAllClicked.emit()
			if (!this.router.url.includes('notifications')) {
				this.router.navigate(['notifications'])
			}
		}
	}

	showAllClick() {
		this.notPopper.hide()
		this.showAllClicked.emit()
	}
}
