<div class="button">
	<label *ngIf="label">{{label | translate}}</label>

	<mat-select
		[formControl]="selectedCtrl"
		class="dropdown"
		tabindex="0"
		panelClass="dropdown-list"
		(selectionChange)="onSelected($event.value)"
		disableOptionCentering
	>
		<mat-option *ngFor="let option of options; let i = index" [value]="option">
			{{!defaultText && i === 0 ? '' : ''}}{{ labels[i] }}
		</mat-option>
	</mat-select>
</div>
