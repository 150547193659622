import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DatePipe } from '@angular/common'
import { EliqApiHttpClient } from '../../../../../data-access/src/lib/services/http/eliq-api-http-client.service'
import { APIUser } from '../../models'
import { APILocation } from '../../models'
import { HttpHeaders } from '@angular/common/http'
@Injectable({
	providedIn: 'root',
})
export class UserHttpService {
	constructor(private http: EliqApiHttpClient) {}

	public getUser(userId: number | null): Observable<APIUser> {
		const user: Observable<APIUser> = this.http.get<APIUser>(
			`/v3/users/${userId}`,
		)
		// Below is useful for debugging
		/*let edited_user: Observable<APIUser> = new Observable(subscriber => {
      user.subscribe(user => {
        user.terms_accepted = ""
        subscriber.next(user)
      }, err => {
        console.error(err)
      })
    })*/
		return user
	}

	public getMandates(): Observable<any[]> {
		return this.http.get<any[]>('/v3/clients/kbc/mandates/')
	}

	public setUserSmartMeter(
		userId: number | null,
		smart: boolean,
	): Observable<any> {
		return this.http.post<APILocation[]>(`/v3/users/${userId}/meterinfo`, {
			meter_type: smart ? 'smart' : 'non-smart',
		})
	}

	public getUserSmartMeter(userId: number | null): Observable<any> {
		return this.http.get<APILocation[]>(`/v3/users/${userId}/meterinfo`)
	}

	private TESTING_modify_location(location: APILocation) {
		if (!location.fuels) return
		let isPvDataAllowed = localStorage.getItem('PV_DATA_ALLOWED')
		if (isPvDataAllowed == null) isPvDataAllowed = 'ALLOWED'

		if (isPvDataAllowed == 'NOT_ALLOWED') {
			if (location.fuels.elec) {
				location.fuels.elec.production = undefined
				location.fuels.elec.import = undefined
				location.fuels.elec.export = undefined
				location.pv_systems = []
			}
		} else {
			// allowed
			if (location.pv_systems) {
				location.pv_systems.forEach((pvSystem) => {
					if (!pvSystem.data_to) pvSystem.data_to = new Date().toISOString()
				})
			}
		}
	}

	/*public getNotificationSettings(userId: number | null): Observable<APINotificationSettingsData[]> {
    return this.http.get<APINotificationSettingsData[]>('/v3/users/' + userId + '/notificationsettings');
  }*/

	public patchUserTerms(
		userId: number | null,
		remove?: boolean,
	): Observable<any> {
		//this.datePipe = new DatePipe('en-US')
		const data = [
			{
				op: 'replace',
				path: '/terms_accepted',
				value: remove ? null : new Date().toISOString(),
			},
		]
		return this.http.patch('/v3/users/' + userId, data)
	}

	public patchUserEmail(userId: number | null, newEmail: string) {
		const data = [
			{
				op: 'replace',
				path: '/email',
				value: newEmail,
			},
		]
		return this.http.patch('/v3/users/' + userId, data)
	}

	public patchLanguageCode(
		userId: number | null,
		newLangCode: string,
		token?: string,
	) {
		/*let subject = new Subject<Boolean>();
    setTimeout(() => {
      subject.next(true);
      subject.complete();
    }, 2000);
    return subject.asObservable();*/ // remove this above when endpoint implemented

		const data = [
			{
				op: 'replace',
				path: '/language_code',
				value: newLangCode,
			},
		]

		const headers = new HttpHeaders()

		if (token) {
			headers.set('Authorization', 'Bearer ' + token)
		}

		return this.http.patch('/v3/users/' + userId, data, {
			headers: headers,
			body: undefined,
		})
	}

	/**
	 * Pass in all the notifications that have changed.
	 */
	/*public patchNotificationSettings(userId: number | null, notifications: NotificationSettingsData[]) {
    let data = notifications.map(not => {
      return {
        type: not.$type,
        enabled: not.$enabled
      }
    });

    return this.http.put<APINotificationSettingsData[]>('/v3/users/' + userId + '/notificationsettings', data, { body: 'response' });
  }*/
}
