<eliq-card
	style="
		display: grid;
		width: 100%; flex: 1;
		height: 100%;
		padding: 24px;
		border-radius: var(--eliq-card-border-radius);
		box-sizing: border-box;
	"
>
	<ng-content></ng-content>
</eliq-card>
