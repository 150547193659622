import { style } from '@angular/animations'
import { DOCUMENT, NgIf } from '@angular/common'
import { Component, OnInit, Input, ElementRef, inject } from '@angular/core'
import { NgxSpinnerService, NgxSpinnerModule, Size } from 'ngx-spinner'
import { WindowRef } from '@eliq/core/WindowRef'

@Component({
	selector: 'eliq-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.css'],
	standalone: true,
	imports: [NgxSpinnerModule],
})
export class SpinnerComponent implements OnInit {
	/**
	 * InvertColors is used to set background to #1d9f68 and the spinners color to white. So, an invertion :)
	 */
	@Input() size: Size = 'small'
	@Input() fullScreen = false
	@Input() contrast = false

	public bgColor = ''
	public color = ''
	private document = inject(DOCUMENT) as Document
	private window = inject(DOCUMENT).defaultView as Window

	constructor(
		private spinner: NgxSpinnerService,
		private element: ElementRef,
	) {}

	ngOnInit() {
		const style = this.window?.getComputedStyle(this.document.body)
		const midString = this.getMiddleString()
		this.bgColor = style.getPropertyValue(`--eliq-spinner-${midString}bg-color`)
		this.color = style.getPropertyValue(`--eliq-spinner-${midString}color`)

		this.spinner.show()
	}

	private getMiddleString() {
		let midString = ''
		if (this.fullScreen) midString += 'fullscreen-'
		if (this.contrast) midString += 'contrast-'
		return midString
	}

	private getColor() {
		const element = this.element.nativeElement.lastChild
		if (element === null) {
			return undefined
		}
		return this.window?.getComputedStyle(element).color
	}
}
