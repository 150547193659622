<div class="container">
	<div class="x" (click)="closeClicked()">
		<eliq-x-button></eliq-x-button>
	</div>
	<h6 class="primary-h6 header">
		{{ 'submit_meter_read.exit_dialog_title' | translate }}
	</h6>
	<div>{{ 'submit_meter_read.exit_dialog_description' | translate }}</div>
	<div class="buttons">
		<eliq-basic-button
			type="primary"
			label="{{ 'submit_meter_read.exit_dialog_back_button' | translate }}"
			(clicked)="closeClicked()"
		></eliq-basic-button>
		<eliq-basic-button
			type="secondary"
			label="{{ 'submit_meter_read.exit_dialog_exit_button' | translate }}"
			(clicked)="exitButtonClicked()"
			[variant]="'secondary'"
		></eliq-basic-button>
	</div>
</div>
