import { Observable } from 'rxjs'
import { saveAs } from 'file-saver'

export class BlobHelper {
	/**
	 *
	 * @param blob Blob object
	 * @returns Observable with Base64 encoded string containing Blob data
	 */
	public static convertToBase64(blob: Blob): Observable<string> {
		return new Observable((subscriber) => {
			if (!blob) {
				return subscriber.next(void 0)
			}

			const reader = new FileReader()
			reader.onloadend = () => {
				return subscriber.next(reader.result as string)
			}

			reader.readAsDataURL(blob)
		})
	}

	/**
	 *
	 * @param base64String Base64 encoded string containing Blob data
	 * @param mimeType Blob MIME type
	 * @returns Blob object
	 */
	public static convertToBlob(base64String: string, mimeType: string): Blob {
		if (!base64String) {
			return new Blob()
		}

		// Checking if Base64 string has prefix (e.g., 'data:application/pdf;base64,') before all the data and then taking the encoded part.
		const base64StringParts = base64String.split(',')
		const hasBase64Prefix = base64StringParts.length > 1
		const decodedString = hasBase64Prefix
			? atob(base64StringParts[1])
			: atob(base64StringParts[0])

		const byteNumbers = new Array(decodedString.length)
		for (let i = 0; i < decodedString.length; i++) {
			byteNumbers[i] = decodedString.charCodeAt(i)
		}

		const data = new Uint8Array(byteNumbers)
		return new Blob([data], { type: mimeType })
	}

	public static downLoadFile(name: string, data: Blob, type: string) {
		const blob = new Blob([data], { type: type })
		saveAs(blob, name)
	}
}
