import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core'
import { CommonModule } from '@angular/common'

interface ConsoleLogEntry {
	message: string
	type: 'log' | 'error' | 'warn'
}

@Component({
	selector: 'eliq-onscreen-console',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './onscreen-console.component.html',
	styleUrls: ['./onscreen-console.component.scss'],
	encapsulation: ViewEncapsulation.ShadowDom,
})
export class OnscreenConsoleComponent implements OnInit, OnDestroy {
	@Input() consoleLogs!: any[]
	@ViewChild('containerRef', { static: false }) containerRef: ElementRef

	constructor(private host: ElementRef<HTMLElement>) {}

	// whatEver function name you want to give
	onCloseClicked() {
		this.host.nativeElement.remove()
	}

	public interval: any = null
	ngOnDestroy() {
		clearInterval(this.interval)
	}
	ngOnInit() {
		this.interval = setInterval(
			function () {
				const elem = (this as any).containerRef.nativeElement
				elem.scrollTop = elem.scrollHeight
			}.bind(this),
			1000,
		)
	}
}
