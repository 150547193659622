import { Component, OnInit, Input } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'

@Component({
	selector: 'eliq-notification-item-anomaly',
	templateUrl: './notification-item-anomaly.component.html',
	styleUrls: ['./notification-item-anomaly.component.css'],
	standalone: true,
})
export class NotificationItemAnomalyComponent implements OnInit {
	@Input() notification: NotificationData

	constructor() {}

	ngOnInit() {}
}
