// @ts-nocheck
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { APIDynamicCapacityTariffsData } from '../models/dynamic-capacity-tariffs-data.model'
import { EliqApiHttpClient } from '@eliq/data-access'
import { DatePipe } from '@angular/common'

@Injectable({
	providedIn: 'root',
})
export class DynamicCapacityTariffsApiService {
	constructor(private http: EliqApiHttpClient, private datepipe: DatePipe) {}

	private formatStr = 'yyyy-MM-ddTHH:mm:ss'

	getDynamicCapacityTariffsData(
		locId: number,
		resolution: 'month' | 'day' | 'hour',
		fromDate: Date,
		toDate: Date,
		pv: boolean,
	): Observable<APIDynamicCapacityTariffsData> {
		const from: string = this.datepipe.transform(fromDate, this.formatStr)
		const to: string = this.datepipe.transform(toDate, this.formatStr)
		let direction = 'consumption'
		if (pv) {
			direction = 'import'
		}
		// use default 15 min pp resoultion for default Belgium. Valid values for the peak_power_resolution are hour, 30min, 15min and 6min
		return this.http.get<APIDynamicCapacityTariffsData>(
			`/v3/locations/${locId}/peakpower/${resolution}?fuel=elec&from=${from}&to=${to}&direction=${direction}&peak_power_resolution=15min`,
		)
	}
}
