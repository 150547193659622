<div *ngIf="!showSimpleBill">
	<div style="display: inline-block;">
		<div class="notification-bill-amount-box">
			<p style="margin: 0;">
				<span class="amount primary-h6">
					{{invoice.$amount | eliqcurrency}}
				</span>
				<span class="date primary-p2">
					{{'my_bills.expires' | translate}}
					{{invoice.$dueDate | date: 'dd/MM/y'}}
				</span>
			</p>
		</div>
	</div>
	<div class="button-container">
		<eliq-link
			*ngIf="!billLoading"
			(clicked)="viewBillClicked()"
		>{{'my_bills.view_bill' | translate}}</eliq-link>
		<div *ngIf="billLoading" class="loading-container">
			<eliq-spinner></eliq-spinner>
		</div>
	</div>
</div>
