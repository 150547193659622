import { Component, Input } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { NotificationData } from '../../models/NotificationData.model'
import { NotificationDataSourceMonitor } from '../../models/NotificationDataSourceMonitor.model'
import { DateHelper } from '@eliq/util'

import {
	EliqTrackingService,
	TrackingEventName,
	EliqNotificationOpenedEvent,
	ViewNotificationEvent,
	TrackingScreen,
} from '@eliq/core/tracking'
import { SpinnerComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-notification-item-monitor',
	templateUrl: './notification-item-monitor.component.html',
	styleUrls: ['./notification-item-monitor.component.css'],
	standalone: true,
	imports: [RouterLink, LinkComponent, SpinnerComponent],
})
export class NotificationItemMonitorComponent {
	// OBSERVE! This part here hides the monitor part of the notification. Used when we know we have poor readings from the backend.
	public showMonitorPart = false

	@Input() chartId: string
	@Input() notification: NotificationData

	public valueSuffix = ''
	public values: number[]
	public currentValue: number
	public notificationSource: NotificationDataSourceMonitor

	private dateCalc: DateHelper

	constructor(
		private tracking: EliqTrackingService,
		//private locHttp: LocationHttpService,
		private router: Router,
	) {
		this.dateCalc = DateHelper.getInstance()
	}

	// TODO here we used EliqNotificationOpened before,
	// look in commit history before the AT3-1455 change to see how it was done.
	// Which way is correct?
	notificationLinkClicked(href): void {
		const eventData: ViewNotificationEvent = {
			v: 2,
			from: TrackingScreen.Notifications,
			header: this.notification.$header,
			eventType: this.notification.$eventType,
		}
		this.tracking.logEvent(TrackingEventName.ViewNotification, eventData)
	}

	public redirectLinkClicked() {
		this.router.navigate(['/monitors'])
	}
}
