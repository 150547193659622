<div class="container">
	<ul class="list">
		<li
			class="notification-item"
			*ngFor="let notification of notifications; let i = index"
		>
			<small class="date secondary-p4">
				{{notification.$createdDate | eliqdate:'short'}}
			</small>
			<h6 class="primary-h6 header">{{notification.$header}}</h6>
			<eliq-notification-item
				[notification]="notification"
				[index]="i"
			></eliq-notification-item>
		</li>
	</ul>
	<div class="button-container">
		<eliq-basic-button
			[variant]="'secondary'"
			[useEntireWidth]="true"
			[label]="'notifications_feed_actions.show_all_notifications' | translate"
			(clicked)="showAllClicked()"
		></eliq-basic-button>
	</div>
</div>
