import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Location } from '@eliq/core'
import { NgFor } from '@angular/common'
import { NgxPopperjsModule } from 'ngx-popperjs'

@Component({
	selector: 'eliq-location-switch',
	templateUrl: './location-switch.component.html',
	styleUrls: ['./location-switch.component.css'],
	standalone: true,
	imports: [NgxPopperjsModule, NgFor],
})
export class LocationSwitchComponent {
	@Input() locations?: Location[]
	@Input() currentLocation?: Location
	@Output() newLocationSelected = new EventEmitter<number>() // emits the location id

	public popperShowing = false

	setCurrentLocation(locId: number) {
		if (this.currentLocation?.id !== locId) {
			this.newLocationSelected.emit(locId)
		}
	}
}
