// @ts-nocheck

import { Property } from './Property.model'
import { TranslateService } from '@ngx-translate/core'
import {
	APIPossibleValue,
	APIProperty,
} from '../api-models/api-home-profile.model'

export class PropertySingleSelect extends Property {
	private value: string
	private possibleValues: APIPossibleValue[]

	constructor(apiProperty: APIProperty) {
		super(apiProperty)
		this.value = apiProperty.value
		this.possibleValues = apiProperty.possible_values
	}

	public getValue() {
		return this.value
	}

	public savePlaceholder() {
		if (this.$placeholder === undefined) return
		this.value = this.$placeholder
		this.$placeholder = undefined
	}

	public getCurrentSelectionString(
		translator: TranslateService,
		maxChars?: number,
	): string {
		const workingValue = this.$placeholder ? this.$placeholder : this.value
		if (workingValue === undefined) return undefined

		return translator.instant('home_profile_options.' + workingValue)
	}

	public hasChanges(): boolean {
		return this.$placeholder !== undefined && this.$placeholder !== this.value
	}

	public getCurrentSelection(): string {
		return this.$placeholder ? this.$placeholder : this.value
	}

	/**
	 * Getter $value
	 * @return {string}
	 */
	public get $value(): string {
		return this.value
	}

	/**
	 * Getter $possibleValues
	 * @return {APIPossibleValue[]}
	 */
	public get $possibleValues(): APIPossibleValue[] {
		return this.possibleValues
	}

	public hasValidSelection() {
		return this.getCurrentSelection() !== undefined
	}
}
