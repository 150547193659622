import { Component, OnInit, Input } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { XButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-annual-card-info-modal',
	templateUrl: './annual-card-info-modal.component.html',
	styleUrls: ['./annual-card-info-modal.component.scss'],
	standalone: true,
	imports: [XButtonComponent],
})
export class AnnualCardInfoModalComponent implements OnInit {
	@Input() greyBorder!: boolean
	public infoText = ''
	public infoTitle = ''
	constructor(
		private dialogRef: MatDialogRef<AnnualCardInfoModalComponent>,
		private translator: TranslateService,
	) {}

	ngOnInit(): void {
		this.infoText = this.translator.instant('annual_consumption.info')
		if (this.infoText === 'annual_consumption.info') {
			this.infoText =
				'Your consumption data is based on your home profile and ' +
				'historical data. We need your annual energy consumption in order for us ' +
				'to create better estimations of your energy consumption.'
		}
		this.infoTitle = this.translator.instant('annual_consumption.info_title')
		if (this.infoTitle === 'annual_consumption.info_title') {
			this.infoTitle = 'Annual energy consumption'
		}
		this.dialogRef
			.backdropClick()
			.pipe(take(1))
			.subscribe(() => this.closeClicked())
	}

	closeClicked() {
		this.dialogRef.close()
	}
}
