import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CardComponent, LinkComponent } from '@eliq/ui/common'
import { TranslateModule } from '@ngx-translate/core'
@Component({
	selector: 'eliq-simple-homecard',
	standalone: true,
	imports: [CommonModule, TranslateModule, CardComponent, LinkComponent],
	templateUrl: './simple-homecard.component.html',
	styleUrls: ['./simple-homecard.component.scss'],
})
export class SimpleHomecardComponent {}
