import { Injectable } from '@angular/core'
import { EliqApiHttpClient } from '@eliq/data-access'
import { CoreDataStoreService } from '@eliq/core'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { APILocationNotificationSettings } from '../../models/api-models/api-location-notification-settings.model'
import { APIUserNotificationSettings } from '../../models/api-models/api-user-notification-settings.model'
import { NotificationSettingsWrapper } from '../../models/NotificationSettings.model'
import { AccountConfigService } from '../config/account-config.service'

@Injectable({
	providedIn: 'root',
})
export class NotificationSettingsService {
	constructor(
		private cds: CoreDataStoreService,
		private http: EliqApiHttpClient,
		private accountConfig: AccountConfigService,
	) {}

	/**
	 * Get the account's notification settings
	 * @returns the API response. Undefined if failure.
	 */
	getNotificationSettings(): Observable<NotificationSettingsWrapper[]> {
		return this.shouldUseLocationBasedNotificationSettings().pipe(
			switchMap((useLocationBased) => {
				if (useLocationBased) return this.getLocationBasedNotificationSettings()
				else
					return this.getUserBasedNotificationSettings().pipe(
						map((res) => [res]),
					)
			}),
		)
	}

	/**
	 * Pass in a modified version of the NotificationSettingsWrapper, updates are made, and you receive an updated version
	 * which has been verified by the API
	 * @param notificationSettings the modified object
	 * @returns a new object representing the successful modification. Returns undefined if failure.
	 */
	updateNotificationSettings(
		notificationSettings: NotificationSettingsWrapper,
	): Observable<NotificationSettingsWrapper> {
		if (notificationSettings.userId)
			return this.updateUserNotificationSettings(notificationSettings)
		else return this.updateLocationNotificationSettings(notificationSettings)
	}

	private updateUserNotificationSettings(
		notificationSettings: NotificationSettingsWrapper,
	): Observable<NotificationSettingsWrapper> {
		const data = notificationSettings.settings.map((setting) => ({
			type: setting.type,
			enabled: setting.enabled,
		}))
		return this.http
			.put<APIUserNotificationSettings[]>(
				`/v3/users/${notificationSettings.userId}/notificationsettings`,
				data,
			)
			.pipe(
				map((response) =>
					this.userNotificationSettingsToModel(
						notificationSettings.userId ?? 0,
						response,
					),
				),
				catchError((err) => of({} as NotificationSettingsWrapper)),
			)
	}

	private updateLocationNotificationSettings(
		notificationSettings: NotificationSettingsWrapper,
	): Observable<NotificationSettingsWrapper> {
		const requests = notificationSettings.settings.map((setting) => {
			const data = { enabled: setting.enabled }
			return this.http
				.put<{ enabled: boolean }>(
					`/v3/locations/${notificationSettings.locationId}/notifications/settings/${setting.typeId}`,
					data,
				)
				.pipe(
					map((response) => setting), // if success, then we know our prev requested version was correct. keep it. and the api is behaving strangely so this is needed. come back to this.
				)
		})
		return forkJoin(requests).pipe(
			map((responses) => ({ ...notificationSettings, settings: responses })),
			catchError((err) => of({} as NotificationSettingsWrapper)),
		)
	}

	private getLocationBasedNotificationSettings(): Observable<
		NotificationSettingsWrapper[]
	> {
		return this.cds.locations.pipe(
			switchMap((locations) => {
				return forkJoin(
					locations.map((loc) =>
						this.getNotificationSettingsForLocation(loc?.id as number).pipe(
							map((locNotSettings) =>
								this.locationNotificationSettingsToModel(
									loc?.id as number,
									loc.name,
									locNotSettings,
								),
							),
						),
					),
				)
			}),
		)
	}

	private getUserBasedNotificationSettings(): Observable<NotificationSettingsWrapper> {
		return this.cds.user.pipe(
			switchMap((user) => {
				return this.getNotificationSettingsForUser(user.id).pipe(
					map((apiNotSettings) =>
						this.userNotificationSettingsToModel(user.id, apiNotSettings),
					),
				)
			}),
		)
	}

	private userNotificationSettingsToModel(
		userId: number | null,
		notSettings: APIUserNotificationSettings[],
	): NotificationSettingsWrapper {
		return {
			userId: userId ?? 0,
			settings: notSettings.map((apiSetting, i) => ({
				type: apiSetting.type,
				enabled: apiSetting.enabled,
				index: i,
			})),
		}
	}

	private locationNotificationSettingsToModel(
		locId: number,
		locName: string,
		notSettings: APILocationNotificationSettings[],
	): NotificationSettingsWrapper {
		return {
			locationId: locId,
			locationName: locName,
			settings: notSettings.map((apiSetting) => ({
				type: apiSetting.type,
				enabled: apiSetting.enabled,
				typeId: apiSetting.type_id,
			})),
		}
	}

	private shouldUseLocationBasedNotificationSettings(): Observable<boolean> {
		return this.accountConfig
			.getAccountConfig()
			.pipe(map((response) => response.location_based_notification_settings))
	}

	private getNotificationSettingsForLocation(
		locId: number,
	): Observable<APILocationNotificationSettings[]> {
		return this.http.get(`/v3/locations/${locId}/notifications/settings`)
	}

	private getNotificationSettingsForUser(
		userId: number | null,
	): Observable<APIUserNotificationSettings[]> {
		return this.http.get(`/v3/users/${userId}/notificationsettings`)
	}
}
