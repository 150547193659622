import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class VariableTranslatorService {
	constructor() {}

	public replaceVariable(raw: string, variable: string, value: string): string {
		const valueIndex = raw.indexOf(variable)
		const prefix = raw.substring(0, valueIndex)
		const suffix = raw.substring(valueIndex + variable.length)
		return prefix + value + suffix
	}

	public innerHtmlToPrefixLinkSuffix(
		raw: string,
		prefixSuffixEnd: string,
		linkMarker: string,
		suffixStart: string,
	): string[] {
		const prefix = raw.substring(0, raw.indexOf(prefixSuffixEnd))
		const fromLink = raw.substring(raw.indexOf(linkMarker) + linkMarker.length)
		const link = fromLink.substring(0, raw.indexOf(linkMarker) + 1)
		const suffix = fromLink.substring(
			fromLink.indexOf(suffixStart) + suffixStart.length,
			fromLink.indexOf(prefixSuffixEnd),
		)
		return [prefix, link, suffix]
	}
}
