<ng-template #companyLogo>
	<div class="companyLogoContainer" [ngStyle]="{width, height}">
		<img
			*ngIf="!isSvg"
			[src]="logoSrc | imgSrc"
			[alt]="logoName"
			[ngStyle]="{
			display: 'block',
			boxSizing: 'border-box',
			padding,
			width, maxHeight,
			minWidth: maxHeight,
			maxWidth: '100%'
		}"
		/>
		<i
			*ngIf="isSvg"
			class="companyLogoSvgContainer"
			[inlineSVG]="logoSrc | imgSrc"
			(onSVGInserted)="onSVGInserted($event)"
			[attr.aria-label]="logoName"
			[ngStyle]="{
			height: '100%',
			width: '100%',
			boxSizing: 'border-box',

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}"
		></i>
	</div>
</ng-template>

<ng-container *ngIf="logoSrc">
	<ng-container *ngIf="link !== false; else withoutLink">
		<a *ngIf="!linkIsAbsolute()" [href]="link" [routerLink]="link">
			<ng-container *ngTemplateOutlet="companyLogo"></ng-container>
		</a>
		<a *ngIf="linkIsAbsolute()" [href]="link">
			<ng-container *ngTemplateOutlet="companyLogo"></ng-container>
		</a>
	</ng-container>
</ng-container>

<ng-template #withoutLink>
	<div>
		<ng-container *ngTemplateOutlet="companyLogo"></ng-container>
	</div>
</ng-template>
