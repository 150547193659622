import { Component, OnInit, Input } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Property, PropertyGroup } from '@eliq/core'
import { HomedetailsSetupComponent } from '../homedetails-setup/homedetails-setup.component'

@Component({
	selector: 'eliq-homedetails-setup-modal-container',
	templateUrl: './homedetails-setup-modal-container.component.html',
	styleUrls: ['./homedetails-setup-modal-container.component.css'],
	standalone: true,
	imports: [HomedetailsSetupComponent],
})
export class HomedetailsSetupModalContainerComponent implements OnInit {
	@Input() properties: Property[]
	@Input() propertyGroups: PropertyGroup[]
	@Input() locationName: string
	@Input() locationId: number

	constructor(
		public dialogRef: MatDialogRef<HomedetailsSetupModalContainerComponent>,
	) {}

	ngOnInit(): void {}

	setupDone() {
		this.dialogRef.close()
	}
}
