import { NgFor, NgIf } from '@angular/common';
import {
    AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import {
    FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators
} from '@angular/forms';
import { PropertySingleSelect } from '@eliq/core';
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe';
import {
    PropertyIconComponent
} from '@eliq/ui/common/components/property-icon/property-icon.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'eliq-homedetails-radiobuttons',
	templateUrl: './homedetails-radiobuttons.component.html',
	styleUrls: ['./homedetails-radiobuttons.component.css'],
	standalone: true,
	imports: [
		PropertyIconComponent,
		FormsModule,
		ReactiveFormsModule,
		NgFor,
		ImgSrcPipe,
		TranslateModule,
		NgIf,
	],
})
export class HomedetailsRadiobuttonsComponent
	implements OnInit, AfterViewInit, OnChanges
{
	@Output() formValueChange = new EventEmitter<string>()
	@Output() formStatusChange = new EventEmitter<boolean>()

	@Input() property: PropertySingleSelect

	@Input() title: string

	@Input() options: string[]
	@Input() selection = ''

	public form: FormGroup

	public showNotApplicable = false

	constructor(private fb: FormBuilder, private translator: TranslateService) {}

	ngOnInit() {
		this.setupForm()
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.form) {
			this.setupForm()
			this.setupFormEmitters()
		}
	}

	ngAfterViewInit() {
		this.setupFormEmitters()
	}

	private setupForm() {
		this.form = this.fb.group({
			option: [this.property.getCurrentSelection(), [Validators.required]],
		})
	}

	translateOption(opt): string {
		const translationKey = `home_profile_options.${opt.value}`;
		const translation = this.translator.instant(translationKey);

		if (translation === translationKey) {
			return `${opt.value}`;
		}
		return translation;
	}

	private setupFormEmitters() {
		this.form.valueChanges.subscribe(() => {
			this.formValueChange.emit(this.form.value.option)
		})

		this.form.statusChanges.subscribe(() => {
			this.formStatusChange.emit(this.form.valid)
		})
	}

	public radioClicked(value: any) {
		if (
			!this.property.$is_required &&
			value === this.form.controls['option'].value
		) {
			this.form.controls['option'].setValue(null)
		}
	}
}
