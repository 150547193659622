import { Injectable } from '@angular/core'
import { forkJoin, Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { APIConsumption } from '@eliq/core/models/src/api-models/api-consumption.model'
import { APIResponseWrapper } from '@eliq/core/models/src/api-models/api-response-wrapper.model'
import { LocationHttpService } from '../http-services/location-http.service'
import { FuelType } from '../..'

@Injectable({
	providedIn: 'root',
})
export class ConsumptionHandlerService {
	constructor(private locationHttp: LocationHttpService) {}

	public getConsumptionForFuels(
		locationId: number,
		fuels: string[],
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<Map<string, number[]>> {
		if (!fuels.length) {
			return of(new Map<string, number[]>())
		}
		// create list of "threads"
		const forks = forkJoin(
			fuels.map((fuel) => {
				return this.locationHttp.getLocationConsumption(
					locationId,
					fuel,
					unit,
					resolution,
					fromDt,
					toDt,
				)
			}),
		)

		// when "tasks" are complete, put them in a map with key and value
		return forks.pipe(
			map((allTasks) => {
				const consumptions: Map<string, number[]> = new Map<string, number[]>()
				allTasks.forEach((element) => {
					consumptions.set(element.fuel, element.consumption)
				})
				return consumptions
			}),
			catchError((err) => {
				const consumptions: Map<string, number[]> = new Map<string, number[]>()
				fuels.forEach((f) => {
					consumptions.set(f, [])
				})

				return of(consumptions)
			}),
		)
	}

	public getForecastForFuels(
		locationId: number,
		fuels: string[],
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<Map<string, number[]>> {
		// create list of "threads"
		const forks = forkJoin(
			fuels.map((fuel) => {
				return this.locationHttp.getLocationForecast(
					locationId,
					fuel,
					unit,
					resolution,
					fromDt,
					toDt,
				)
			}),
		)

		// when tasks are complete, put them in a map with key and value
		return forks.pipe(
			map((allTasks) => {
				const forecasts: Map<string, number[]> = new Map<string, number[]>()
				allTasks.forEach((element) => {
					forecasts.set(element.fuel, element.forecast)
				})
				return forecasts
			}),
			catchError((err) => {
				const forecasts: Map<string, number[]> = new Map<string, number[]>()
				fuels.forEach((f) => {
					forecasts.set(f, [])
				})

				return of(forecasts)
			}),
		)
	}

	// This method should probably not be here?
	public getTemperature(
		locationId: number,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<number[]> {
		return this.locationHttp
			.getLocationTemperature(locationId, resolution, fromDt, toDt)
			.pipe(
				map((res) => res.temperature),
				catchError((err) => []),
			)
	}

	/**
	 * Similar homes consumption always returns the total consumption for all fuels in the homes. Instead of returning a map like for forecast and consumption, return the list
	 * @param locationId
	 * @param unit
	 * @param resolution
	 * @param fromDt
	 * @param toDt
	 */
	public getSimilarHomesConsumption(
		locationId: number,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<number[]> {
		return this.locationHttp
			.getSimilarHomesConsumption(
				locationId,
				'elec',
				unit,
				resolution,
				fromDt,
				toDt,
			)
			.pipe(
				map((response) => {
					return response.result === undefined
						? []
						: response.result.consumption
				}),
				catchError((err) => of([])),
			)
	}

	public getSimilarHomesConsumptionForFuels(
		locationId: number,
		fuels: string[],
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<Map<string, number[]>> {
		//resolution = 'month' // already set to month in getSimilarHomesConsumption as of now
		const forks = forkJoin(
			fuels.map((fuel) => {
				return this.locationHttp
					.getSimilarHomesConsumption(
						locationId,
						fuel,
						unit,
						resolution,
						fromDt,
						toDt,
					)
					.pipe(
						map((x) => x),
						catchError((err) => of({} as APIResponseWrapper<APIConsumption>)),
					)
			}),
		)

		return forks.pipe(
			map((allResponses) => {
				const consumptions = new Map<string, number[]>()
				if (
					allResponses?.some(
						(response) => response?.result_status?.code != 'ok',
					)
				) {
					return consumptions
				} else {
					allResponses?.forEach((response) => {
						consumptions.set(
							response?.result?.fuel,
							response?.result?.consumption,
						)
					})
					return consumptions
				}
			}),
		)
	}

	/**
	 * Similar homes consumption always returns the total consumption for all fuels in the homes. Instead of returning a map like for forecast and consumption, return the list
	 * @param locationId
	 * @param fuel
	 * @param unit
	 * @param resolution
	 * @param fromDt
	 * @param toDt
	 */
	public getBreakdown(
		locationId: number,
		fuel: FuelType,
		unit: 'energy' | 'cost' | 'm3',
		fromDt: Date,
		toDt: Date,
	): Observable<Map<string, number> | null> {
		return this.locationHttp
			.getLocationEuc(locationId, fuel, unit, fromDt, toDt)
			.pipe(
				map(
					(response) => {
						const breakdownResult =
							response === undefined ? null : response.breakdown
						if (breakdownResult === null) return null
						const breakdown: Map<string, number> = new Map<string, number>()
						breakdownResult.map((x) => {
							breakdown.set(x.category, x.value)
						})
						return breakdown
					},
					catchError((err) => of(null)),
				),
			)
	}
}
