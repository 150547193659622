<div class="container">
	<h6 class="primary-h6" style="text-align: center; margin: 0;">{{ title }}</h6>
	<p class="secondary-p1" style="text-align: center;">{{ description }}</p>
	<div class="button-container">
		<eliq-basic-button
			[label]="acceptLabel"
			class="button1"
			(clicked)="accept()"
			[useEntireWidth]="true"
		></eliq-basic-button>
		<eliq-basic-button
			[label]="rejectLabel"
			[variant]="'secondary'"
			(clicked)="reject()"
			[useEntireWidth]="true"
		></eliq-basic-button>
	</div>
</div>
