import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class ArrayService {
	private total?: number

	public findMaximumAmountAndIndexTuple(numbers: number[]) {
		if (!numbers) {
			return [-1, -1]
		}
		if (numbers.length < 1) {
			return [-1, -1]
		}
		this.total = numbers[0]
		let maxAmount = this.total
		let maxIndex = 0
		for (let i = 1; i < numbers.length; i++) {
			this.total += numbers[i]
			if (numbers[i] > maxAmount) {
				maxAmount = numbers[i]
				maxIndex = i
			}
		}
		return [maxAmount, maxIndex]
	}

	getTotalFromLastIteration(): number {
		return this.total ?? 0
	}
}
