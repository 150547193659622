import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
} from '@angular/core'
import { Fuel, FuelType } from '@eliq/core'
import { APIBudget } from '../../models/api-budget.model'
import { FlexibleButtonComponent } from '@eliq/ui/common/components/buttons/flexible-button/flexible-button.component'
import { BudgetHomeCardItemComponent } from '../budget-home-card-item/budget-home-card-item.component'
import { FuelSwitchComponent } from '@eliq/ui/app-fuel-switch/fuel-switch.component'
import { NgIf, NgFor } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'

@Component({
	selector: 'eliq-budget-home-card',
	templateUrl: './budget-home-card.component.html',
	styleUrls: ['./budget-home-card.component.scss'],
	standalone: true,
	imports: [
		CardComponent,
		TranslateModule,
		NgIf,
		FuelSwitchComponent,
		NgFor,
		BudgetHomeCardItemComponent,
		FlexibleButtonComponent,
	],
})
export class BudgetHomeCardComponent implements OnChanges {
	@Input() budgets: APIBudget[]
	@Input() currentFuel: Fuel // the currently selected fuel
	@Input() possibleFuelTypes: FuelType[]
	@Output() budgetClicked = new EventEmitter<APIBudget>()
	@Output() createBudgetClicked = new EventEmitter()
	@Output() changeFuelClicked = new EventEmitter()

	public currentFuelBudgets: APIBudget[]
	public currentFuelEnabledBudgets: APIBudget[]

	constructor() {}

	ngOnChanges() {
		this.setCurrentFuelBudgets()
	}

	fuelChanged(newFuelType: FuelType) {
		this.changeFuelClicked.emit(newFuelType)
	}

	private setCurrentFuelBudgets() {
		this.currentFuelBudgets = this.budgets.filter(
			(b) => b.fuel === this.currentFuel.type,
		)
		this.currentFuelEnabledBudgets = this.currentFuelBudgets.filter(
			(b) => b.enabled,
		)
	}
}
