import { Pipe, PipeTransform } from '@angular/core'
import { Inject, LOCALE_ID } from '@angular/core'
import { formatDate } from '@angular/common'
import {
	DateTranslatorService,
	DateOptions,
	SimpleOptions,
} from '../public_api'
@Pipe({
	name: 'eliqdate',
	standalone: true,
})
export class EliqDatePipe implements PipeTransform {
	private myLocale: string
	constructor(
		@Inject(LOCALE_ID) input_locale: string,
		private dateTranslator: DateTranslatorService,
	) {
		this.myLocale = input_locale
	}

	/**
	 * @param date
	 * @param simpleStyle Defaults to 'short.
	 *
	 * Possible values:
	 * 'time' | 'full' | 'long' | 'medium' | 'short' | 'short+short' | ... and etc.
	 *
	 * 'short' will just print the date
	 * 'short+short' Will add a short time additionally
	 * 'short+long' Will make a short date with a long time
	 * 'time+long' Will only print a long time (no date)
	 * 'time' Will only print a short time (no date), equivalent to 'time+short'
	 *
	 * @param overrideOptions option object.
	 *
	 * To print time also for example: {timeStyle: 'short'}.
	 *
	 * See more in DateTranslatorService
	 * .
	 * @param format
	 * @returns
	 */
	transform(
		date: Date,
		options: SimpleOptions | DateOptions = 'short',
		timeOnlyIfNotZero = false,
	): string {
		let opts = {} as DateOptions | SimpleOptions

		if (typeof options !== 'string') {
			opts = { ...options }
		}

		return this.dateTranslator.getDateTimeString(
			date,
			options,
			timeOnlyIfNotZero,
		)
	}
}
