<div class="container">
	<ng-container *ngIf="activeDataStream;else other_content">
		<div class="section">
			<h6 class="primary-h6 section-header">{{dayKey | translate}}</h6>
			<div class="section-items">
				<eliq-pv-trend-item
					*ngIf="dailyComparison"
					[unit]="unit"
					class="item-1 section-item"
					[value]="dailyComparison.import"
					type="import"
				></eliq-pv-trend-item>
				<eliq-pv-trend-item
					*ngIf="dailyComparison"
					[unit]="unit"
					class="section-item"
					[value]="dailyComparison.export"
					type="export"
				></eliq-pv-trend-item>
				<eliq-pv-trend-item
					*ngIf="!dailyComparison"
					[unit]="unit"
					class="item-1 section-item"
					[value]="null"
					type="import"
				></eliq-pv-trend-item>
				<eliq-pv-trend-item
					*ngIf="!dailyComparison"
					[unit]="unit"
					class="section-item"
					[value]="null"
					type="export"
				></eliq-pv-trend-item>
			</div>
		</div>

		<div class="section" *ngIf="monthlyComparison">
			<h6 class="primary-h6 section-header">{{month | titlecase}}</h6>
			<div class="section-items clickable" (click)="monthlyComparisonClicked()">
				<eliq-pv-trend-item
					[unit]="unit"
					class="item-1 section-item"
					[value]="monthlyComparison?.import?.value ?? 0"
					[diff]="monthlyComparison?.import?.diffPercentage ?? 0"
					type="import"
				></eliq-pv-trend-item>
				<eliq-pv-trend-item
					[unit]="unit"
					class="section-item"
					[value]="monthlyComparison?.export?.value ?? 0"
					[diff]="monthlyComparison?.export?.diffPercentage ?? 0"
					type="export"
				></eliq-pv-trend-item>
			</div>
		</div>
	</ng-container>

	<ng-template #other_content>
		<div class="section" *ngIf="dailyComparison">
			<h6 class="primary-h6 section-header">{{dayKey | translate}}</h6>
			<div class="section-items">
				<eliq-pv-trend-item
					[unit]="unit"
					class="item-1 section-item"
					[value]="dailyComparison.consumption"
					type="consumption"
				></eliq-pv-trend-item>
				<eliq-pv-trend-item
					[unit]="unit"
					class="section-item"
					[value]="dailyComparison.production"
					type="production"
				></eliq-pv-trend-item>
			</div>
		</div>

		<div class="section" *ngIf="monthlyComparison">
			<h6 class="primary-h6 section-header">{{month | titlecase}}</h6>
			<div class="section-items clickable" (click)="monthlyComparisonClicked()">
				<eliq-pv-trend-item
					[unit]="unit"
					class="item-1 section-item"
					[value]="monthlyComparison.consumption.value"
					[diff]="monthlyComparison.consumption.diffPercentage"
					type="consumption"
				></eliq-pv-trend-item>
				<eliq-pv-trend-item
					[unit]="unit"
					class="section-item"
					[value]="monthlyComparison.production.value"
					[diff]="monthlyComparison.production.diffPercentage"
					type="production"
				></eliq-pv-trend-item>
			</div>
		</div>
	</ng-template>

	<div class="link-section-container">
		<div class="link-section">
			<a routerLink="/insights" class="insights-link">
				<eliq-link label="home_pv_card.view_insight"></eliq-link>
			</a>
		</div>
	</div>
</div>
