import { Injectable } from '@angular/core'
import { EliqApiHttpClient } from '@eliq/data-access'
import { Observable } from 'rxjs'
import { APINotificationsData } from '../models/api-notification.model'
import { NotificationData } from '../models/NotificationData.model'

@Injectable({
	providedIn: 'root',
})
export class NotificationsApiService {
	constructor(private http: EliqApiHttpClient) {}

	public getNotifications(
		userId: number | null,
	): Observable<APINotificationsData[]> {
		return this.http.get<APINotificationsData[]>(
			'/v3/users/' + userId + '/notifications',
		)
	}

	public patchNotificationRead(
		userId: number | null,
		notification: NotificationData,
	): Observable<any> {
		const data = [
			{
				op: 'replace',
				path: '/read',
				value: true,
			},
		]

		return this.http.patch(
			'/v3/users/' + userId + '/notifications/' + notification.$id,
			data,
		)
	}

	public patchNotificationReads(
		userId: number | null,
		notifications: NotificationData[],
		value = true,
	) {
		const data = [
			{
				op: 'replace',
				path: '/read',
				value: value,
			},
		]

		const idList = notifications.map((not) => not.$id.toString()).join(',')

		return this.http.patch(
			'/v3/users/' + userId + '/notifications?notification_ids=' + idList,
			data,
		)
	}
}
