import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Property, PropertyGroup } from '@eliq/core'
import { HomedetailsSetupStepperComponent } from '@eliq/feature/homeprofile/components/homedetails-setup-stepper/homedetails-setup-stepper.component'
import { BasicButtonComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-insights-homeprofile-setup',
	templateUrl: './insights-homeprofile-setup.component.html',
	styleUrls: ['./insights-homeprofile-setup.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		TranslateModule,
		BasicButtonComponent,
		HomedetailsSetupStepperComponent,
	],
})
export class InsightsHomeprofileSetupComponent implements OnInit {
	@Input() locationName: string
	@Input() locationId: number
	@Input() properties: Property[]
	@Input() propertyGroups: PropertyGroup[]

	@Output() done = new EventEmitter()

	public state = ''
	constructor() {}

	ngOnInit(): void {
		this.state = 'starting'
	}

	startClick() {
		this.state = 'started'
	}

	dontStartClick() {
		this.done.emit()
	}

	stepperDone(status: string) {
		this.state = status
	}

	stepperLoading(loading: boolean) {
		if (this.state === 'success' || this.state === 'failure') {
			return
		}
		this.state = loading ? 'loading' : 'started'
	}

	closeClick() {
		this.done.emit()
	}
}
