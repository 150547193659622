import { Injectable } from '@angular/core'
import { PeriodType } from '@eliq/core'
import { DateTranslatorService, Period } from '@eliq/core'
import { TranslateService } from '@ngx-translate/core'
import { APIBudget } from '../models/api-budget.model'

@Injectable({
	providedIn: 'root',
})
export class BudgetHelperService {
	constructor(
		private dateTranslator: DateTranslatorService,
		private translator: TranslateService,
	) {}

	getDateStringFromBudget(budget: APIBudget): string {
		if (budget.resolution === 'week') {
			const parts = this.dateTranslator.getWeekStringParts(
				new Date(budget.status.period_start),
				true,
			)
			return `${parts.startDay} ${parts.startMonth} - ${parts.endDay} ${parts.endMonth}`
		} else if (budget.resolution === 'month') {
			return this.dateTranslator.getTranslatedMonth(
				new Date(budget.status.period_start).getMonth(),
				false,
			)
		} else return ''
	}

	getBudgetDateStringFromPeriod(period: Period): string {
		if (period.getPeriodType() === PeriodType.Week) {
			const parts = this.dateTranslator.getWeekStringParts(
				period.getFirstDate(),
				true,
			)
			return `${parts.startDay} ${parts.startMonth} - ${parts.endDay} ${parts.endMonth}`
		} else if (period.getPeriodType() === PeriodType.Month) {
			return this.dateTranslator.getTranslatedMonth(
				period.getFirstDate().getMonth(),
				false,
			)
		} else return ''
	}

	/**
	 * Gets the translated title of the budget like "Weekly cost" or "Monthly consumption"
	 * @param budget
	 */
	getTranslatedBudgetTitle(budget: APIBudget): string {
		return this.translator.instant(
			`budget.${budget.resolution + 'ly'}_${budget.unit}`,
		)
	}
}
