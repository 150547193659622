import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ConsumptionCardConfig } from '../models/config.model'

@Injectable({
	providedIn: 'root',
})
export class ConsumptionCardConfigService {
	constructor(private config: JsonGetterService) {}

	showSoFar(): Observable<boolean> {
		return this.getConsumptionCardConfig().pipe(
			map((config) => {
				if (config.show_so_far === undefined) return true
				// default to true if Not specified at all
				else return config.show_so_far
			}),
		)
	}

	private getConsumptionCardConfig(): Observable<ConsumptionCardConfig> {
		return this.config.getConfig('home_consumption_card').pipe(
			map((config) => (config ? config : {})), // if undefined, replace with empty object to avoid crashes.
		)
	}
}
