// the location http service has a function that gets data in this format
export class APIData {
	data!: number[]
	dataType!: string
	resolution!: string
	from!: string
	to!: string
	fuel!: string
	unit!: string
}
