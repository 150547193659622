import { NgModule, Provider, Type, inject } from '@angular/core'
import {
	Routes,
	Route,
	Router,
	CanMatchFn,
	UrlSegment,
	CanActivateFn,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router'
import { AuthService } from '@eliq/feature/auth' //authguard and loginguard exists here too
import { TicketsComponent } from '@eliq/feature/tickets'
import { ChatComponent } from '@eliq/feature/chat/components/chat/chat.component'
import { environment } from 'src/environments/environment'
import { HomeComponent } from './views/home/home/home.component'
import { LoginComponent } from './views/login/login/login.component'
import { EnvironmentService } from '@eliq/data-access'
import { FlowService } from '@eliq/feature/integrationless/flow/flow-service/flow.service'
import { NotFoundComponent } from '@eliq/ui/layout/404/404.component'
import { PageNotFoundComponent } from './views/404/page-not-found.component'
import { SessionExpiredComponent } from './views/login/session-expired/session-expired.component'

const IS_INTEGRATIONLESS =
	(environment as any)?.integrationless?.enabled === true

const ENABLE_PAYPOINT_TICKETS = (environment as any)?.enablePaypointTickets || false

const DISABLED_ROUTES = (environment as any)?.disabledRoutes || []

const AuthGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot,
): boolean => {
	const authService = inject(AuthService)
	if (authService.isLoggedIn()) {
		if (!IS_INTEGRATIONLESS) {
			return true
		} //else // TODO this flowService here was never used so I uncommented this out but this feels weird, should look at this more closely. Everything works so not the priority right now.
			//const flowService = inject(FlowService)
		//}
		return true
	}
	if (DISABLED_ROUTES.includes('login')) {
		return false
	}
	inject(Router).navigate(!IS_INTEGRATIONLESS ? ['/login'] : ['/'])

	return false
}

const NotLoggedInGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot,
): boolean => {
	const authService = inject(AuthService)

	if (!authService.isLoggedIn()) {
		return true
	}
	inject(Router).navigate(IS_INTEGRATIONLESS ? ['/welcome'] : ['/home'])
	return false
}

export const routes: Routes = [
	{
		path: 'webview-agreements',
		loadChildren: () =>
			import('./views/webview-agreements/webview-agreements.module').then(
				(m) => m.WebviewAgreementsModule,
			),
		canActivate: [],
	},
	{
		path: 'login/:ticketId',
		loadComponent: () =>
			import('./views/login/autologin/autologin.component').then(
				(m) => m.AutoLoginComponent,
			),
		canActivate: [],
	}, //canActivate: [/*LoginGuard*/] },
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'pv',
		loadComponent: () =>
			import('./views/pv/pv/pv.component').then((m) => m.PVComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'embed-with-ticket',
		loadComponent: () =>
			import(
				'./views/embed-with-ticket/embed-with-ticket/embed-with-ticket.component'
			).then((m) => m.EmbedWithTicketComponent),
		canActivate: [],
	},
	{ path: 'chat', component: ChatComponent },
	{
		path: 'insights',
		loadComponent: () =>
			import('./views/insights/insights/insights.component').then(
				(m) => m.InsightsComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'bills',
		loadComponent: () =>
			import('./views/bills/bills/bills.component').then(
				(m) => m.BillsComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'notifications',
		loadComponent: () =>
			import(
				'./views/notifications/notifications/notifications.component'
			).then((m) => m.NotificationsComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'account',
		loadComponent: () =>
			import('./views/account/account/account.component').then(
				(m) => m.AccountComponent,
			),
		canActivate: [AuthGuard],
	},
	{ path: 'tickets', component: TicketsComponent },
	{
		path: 'energy-advisor',
		loadComponent: () =>
			IS_INTEGRATIONLESS ? import(
				'@eliq/feature/integrationless/il-energy-advisor-container/il-energy-advisor-container.component'
			).then((m) => m.ILEnergyAdvisorContainerComponent) : import(
				'@eliq/feature/energy-advisor/components/energy-advisor-container/energy-advisor-container.component'
			).then((m) => m.EnergyAdvisorContainerComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'energy-advisor/:adviceId',
		loadComponent: () =>
			IS_INTEGRATIONLESS ? import(
				'@eliq/feature/integrationless/il-energy-advisor-container/il-energy-advisor-container.component'
			).then((m) => m.ILEnergyAdvisorContainerComponent) : import(
				'@eliq/feature/energy-advisor/components/energy-advisor-container/energy-advisor-container.component'
			).then((m) => m.EnergyAdvisorContainerComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'renewal',
		loadComponent: () =>
			import('./views/renewal/renewal/renewal.component').then(
				(m) => m.RenewalComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'budget',
		loadComponent: () =>
			import('./views/budget/budget/budget.component').then(
				(m) => m.BudgetComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'electric-vehicle',
		loadComponent: () =>
			import(
				'./views/electric-vehicle/electric-vehicle/electric-vehicle.component'
			).then((m) => m.ElectricVehicleComponent),
		canActivate: [AuthGuard],
	},
]

if (!DISABLED_ROUTES.includes('login')) {
	routes.push({
		path: 'login',
		component: LoginComponent,
		canActivate: [NotLoggedInGuard],
	})
} else {
	routes.push({
		path: 'login',
		component: SessionExpiredComponent,
		canActivate: [NotLoggedInGuard],
	})
}

export const dynamicRoutes: Routes = (() => {
	return [
		{
			path: '',
			pathMatch: 'full',
			...(!IS_INTEGRATIONLESS
				? { redirectTo: '/login' }
				: {
						canActivate: [NotLoggedInGuard],
						loadComponent: () =>
							import(
								'@eliq/feature/integrationless/integration-less-home/il-home/il-home.component'
							).then((m) => m.ILHomeComponent),
				  }),
		},
		...(ENABLE_PAYPOINT_TICKETS ? [
			{
				path: 'paypoint-ticket',
				loadComponent: () =>
					import(
						'@eliq/feature/paypoint-ticket/paypoint-ticket.component'
					).then((m) => m.PaypointTicketComponent),
				canActivate: [],
			},
		] : []),
		...(IS_INTEGRATIONLESS
			? [
					{
						path: 'flow',
						loadComponent: () =>
							import(
								'@eliq/feature/integrationless/flow/flow/flow.component'
							).then((m) => m.FlowComponent),
						canActivate: [AuthGuard],
					},
					{
						path: 'flow/:state',
						loadComponent: () =>
							import(
								'@eliq/feature/integrationless/flow/flow/flow.component'
							).then((m) => m.FlowComponent),
						canActivate: [AuthGuard],
					},
					{
						path: 'flow/homeprofile/:step',
						loadComponent: () =>
							import(
								'@eliq/feature/integrationless/flow/flow/flow.component'
							).then((m) => m.FlowComponent),
						canActivate: [AuthGuard],
					},
					{
						path: 'welcome',
						loadComponent: () =>
							import(
								'@eliq/feature/integrationless/il-welcome/il-welcome.component'
							).then((m) => m.ILWelcomeComponent),
						canActivate: [AuthGuard],
					},
					{
						path: 'welcome-2',
						loadComponent: () =>
							import(
								'@eliq/feature/integrationless/il-welcome/il-welcome.component'
							).then((m) => m.ILWelcomeComponent),
						canActivate: [AuthGuard],
					},
					/*{
						path: 'luna-insights',
						loadComponent: () =>
							import(
								'@eliq/feature/integrationless/luna-insights/luna-insights.component'
							).then((m) => m.ILWelcomeComponent),
						canActivate: [AuthGuard],
					},*/
			  ]
			: []),
		...routes,
		{
			path: '**',
			component: NotFoundComponent,
		},
	]
})()
