import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core'
import { Output } from '@angular/core'
import { EventEmitter } from '@angular/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
@Component({
	selector: 'eliq-x-button',
	templateUrl: './x-button.component.html',
	styleUrls: ['./x-button.component.scss'],
	standalone: true,
	imports: [
		InlineSVGModule,
		TranslateModule,
		NgIf,
		NgFor,
		AsyncPipe,
		ImgSrcPipe,
	],
	//encapsulation: ViewEncapsulation.ShadowDom,
})
export class XButtonComponent {
	//implements OnInit {
	@Output() clicked = new EventEmitter<any>()
	//ngOnInit(): void {}
}
