<div class="container">
	<div class="language-toggle-holder">
		<eliq-translation-picker
			*ngIf="translationPicker && ((languages | async)?.length ?? 0) > 1"
			[languages]="languages | async"
			[selectedLang]="selectedLang"
			(langSelected)="langSelected($event)"
		></eliq-translation-picker>
	</div>
	<div class="box greeting" *ngIf="!expandLogin">
		<eliq-login-greeting class="box-element"></eliq-login-greeting>
	</div>
	<div class="box login">
		<eliq-signin-container
			[integrationless]="integrationless"
			class="box-element"
			(maximizeLogin)="maximizeLogin($event)"
		></eliq-signin-container>
	</div>
</div>
