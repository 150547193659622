<div class="selection">
	<eliq-notification-item-report
		*ngIf="notification.$eventType.includes('report_created')"
		[chartId]="'not-chart-id-'+index"
		[notification]="notification"
	></eliq-notification-item-report>
	<eliq-notification-item-monitor
		*ngIf="notification.$eventType === 'monitor_triggered'"
		[chartId]="'not-chart-id-'+index"
		[notification]="notification"
	></eliq-notification-item-monitor>
	<eliq-notification-item-info
		*ngIf="notification.$eventType === 'info'"
		[notification]="notification"
	></eliq-notification-item-info>
	<eliq-notification-item-bill
		*ngIf="notification.$eventType === 'bill_created'"
		[notification]="notification"
	></eliq-notification-item-bill>
	<eliq-notification-item-anomaly
		*ngIf="notification.$eventType === 'alert_triggered'"
		[notification]="notification"
	></eliq-notification-item-anomaly>
	<eliq-notification-item-outage
		*ngIf="notification.$eventType === 'power_outage_update'"
		[notification]="notification"
	></eliq-notification-item-outage>
	<eliq-notification-item-claim-and-request
		*ngIf="notification.$eventType === 'claim_and_request_update'"
		[notification]="notification"
	></eliq-notification-item-claim-and-request>
	<eliq-notification-item-abnormal
		*ngIf="notification.$eventType === 'daily_anomaly'"
		[notification]="notification"
	></eliq-notification-item-abnormal>
	<eliq-notification-item-target
		*ngIf="notification.$eventType === 'budget'"
		[notification]="notification"
	></eliq-notification-item-target>
	<eliq-notification-item-monthly-insights
		*ngIf="notification.$eventType === 'monthly_insight'"
		[notification]="notification"
	></eliq-notification-item-monthly-insights>
	<div *ngIf="notification.$eventType === 'custom'">
		<p *ngIf="notification.$content.length" class="content">
			{{notification.$content}}
		</p>
		<a
			*ngIf="notification.$source['action'] === 'open_url'"
			(click)="linkClicked(notification.$source['action_payload']?.url)"
			class="action_payload_button"
		>
			<eliq-link
				[label]="notification.$source['action_button_label'] | translate"
			></eliq-link>
		</a>
	</div>
</div>
