<div class="outer-container">
	<eliq-card class="eliq-default-card-style" *ngIf="transactionList.length > 0">
		<div *ngIf="billingAccounts; else loadingBlock">
			<div class="top-part">
				<eliq-transaction-big-invoice
					*ngIf="topElementType === 'latest-transaction'"
					[transaction]="latestTransaction"
					[billingAccountId]="currentBA?.id"
				></eliq-transaction-big-invoice>
				<eliq-account-balance
					*ngIf="topElementType === 'account-balance'"
					[accountBalance]="currentBA.accountBalance"
				></eliq-account-balance>
			</div>
			<div class="bottom-part">
				<p
					*ngIf="showHeading"
					class="secondary-p2"
					style="margin: 30px 0; font-weight: bold;"
				>
					{{'my_bills.previous_bills' | translate}}
				</p>
				<eliq-transactions-list
					[options]="options"
					[billingAccountId]="currentBA?.id"
					[transactions]="transactionsShowed"
				></eliq-transactions-list>
				<eliq-pager
					*ngIf="nCurrentTotalPages > 1 && !pagerOutside"
					[nPages]="nCurrentTotalPages"
					(selectedPageEmitter)="newPageClicked($event)"
				></eliq-pager>
			</div>
		</div>
	</eliq-card>

	<div *ngIf="nCurrentTotalPages > 1 && pagerOutside" style="margin: 32px 0;">
		<eliq-pager
			[nPages]="nCurrentTotalPages"
			(selectedPageEmitter)="newPageClicked($event)"
		></eliq-pager>
	</div>
</div>
<ng-template #loadingBlock>
	<div class="loading-container">
		<eliq-spinner size="medium"></eliq-spinner>
	</div>
</ng-template>
