<div class="container">
	<div class="x" (click)="closeClicked()">
		<eliq-x-button></eliq-x-button>
	</div>
	<h5 class="primary-h5 header" translate>budget.create_budget_title</h5>
	<p class="secondary-p2" translate>budget.create_budget_description</p>

	<form [formGroup]="form">
		<mat-radio-group formControlName="resolution" class="my-radio-group">
			<mat-radio-button
				value="week"
				[disabled]="!weekSelectable"
				[class.disabled]="!weekSelectable"
				[disableRipple]="true"
				class="my-radio-button"
			>
				<span class="primary-p2" translate>budget.weekly</span>
			</mat-radio-button>
			<mat-radio-button
				value="month"
				[disabled]="!monthSelectable"
				[class.disabled]="!monthSelectable"
				[disableRipple]="true"
				class="my-radio-button"
			>
				<span class="primary-p2" translate>budget.monthly</span>
			</mat-radio-button>
		</mat-radio-group>

		<div class="limit-area">
			<p class="primary-p1 budget-amount-header" translate>
				budget.budget_amount
			</p>
			<p
				class="secondary-p3 amount-forecasted-subtext"
				*ngIf="selectedResolutionTranslationKey && selectedResolutionSuggestedLimit "
			>
				{{'budget.'+selectedResolutionTranslationKey + '_average' | translate}}:
				{{selectedResolutionSuggestedLimit}}
			</p>

			<div class="input-field-container">
				<mat-form-field appearance="outline">
					<span
						*ngIf="unitSymbol && prefixUnitSymbol"
						class="secondary-p3"
						style="font-weight: bold;"
						matPrefix
					>
						{{unitSymbol}}
					</span>

					<input
						matInput
						[id]="randomId"
						type="number"
						class="input-text primary-p2"
						formControlName="limit"
						inputmode="numeric"
						pattern="[0-9]*"
					/>
					<span
						*ngIf="unitSymbol && !prefixUnitSymbol"
						class="secondary-p3"
						style="font-weight: bold;"
						matSuffix
					>
						{{unitSymbol}}
					</span>
					<mat-error
						*ngIf="form.get('limit')?.hasError('required')"
						class="secondary-p4"
						translate
					>
						common.field_cannot_not_empty
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="button-area">
			<eliq-basic-button
				label="budget.button_create_budget"
				(clicked)="createBudgetClicked()"
				[disabled]="!form.valid || createLoading"
				[loading]="createLoading"
			></eliq-basic-button>
		</div>
	</form>
</div>
