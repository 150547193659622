import { Component, Input, OnInit } from '@angular/core'
import { InfoCardContentComponent } from '../info-card-content/info-card-content.component'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-info-card',
	templateUrl: './info-card.component.html',
	styleUrls: ['./info-card.component.scss'],
	standalone: true,
	imports: [NgIf, InfoCardContentComponent],
})
export class InfoCardComponent {
	@Input() title: string
	@Input() text: string
	@Input() imgSrc: string
	@Input() link: string
	@Input() linkText: string
	@Input() cardStyle: Record<string, string>
	@Input() imageStyle: Record<string, string>
	@Input() imageLongShadow: string | boolean
	@Input() linkStyle: Record<string, string> = {} // variant or record
	@Input() fullLink = false
	@Input() kbcPostMessageLink = false
	@Input() paddingBottom = false
}
