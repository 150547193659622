<div class="outer-container">
	<h5 class="primary-h5 header" *ngIf="advice?.key" [innerHTML]='(("energy_advisor." + advice.key + "_title") | translate) === ("energy_advisor." + advice.key + "_title") ? advice.title : (("energy_advisor." + advice.key + "_title") | translate)'></h5>
	<h5 class="primary-h5 header" *ngIf="!advice?.key && advice?.title" [innerHTML]='advice?.title'></h5>

	<div class="savings-box-wrapper">
		<div class="savings-box">
			<div
				class="savings-box-icon"
				aria-label="Energy saving icon"
				[inlineSVG]="'/assets/images/pv_flash.svg' | imgSrc"
			></div>
			<p
				class="primary-p1 savings-box-text"
				translate
				[translateParams]="{amount: numberPipe.transform(advice.estimated_yearly_savings_energy, 'energy', '1.0-0')}"
			>energy_advisor.savings_year</p>
		</div>
	</div>

	<p class="primary-p2 content" [innerHTML]='(("energy_advisor." + advice.key + "_text") | translate) === ("energy_advisor." + advice.key + "_text") ? advice.content : (("energy_advisor." + advice.key + "_text") | translate)'></p>
	<p class="primary-p2 content" *ngIf="!advice.key && advice?.content" [innerHTML]="advice?.content"></p>
	<eliq-link
		*ngIf="(advice?.link?.url?.length ?? 0) > 0"
		[href]="advice?.link?.url ?? '#'"
		(clicked)="linkClicked($event)"
		style="margin-bottom: 2em;"
		[label]="(advice.link?.text || 'Learn more')  | translate"
	></eliq-link>

	<div class="buttons">
		<eliq-basic-button
			*ngIf="showMarkAsDoneButton"
			(clicked)="markAsDoneClicked()"
			class="button"
			[variant]="'secondary'"
		>
			<div class="button-content">
				<svg
					width="17"
					height="17"
					viewBox="0 0 36 36"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill="currentColor"
						d="M33.089 7.41089C33.9679 8.28979 33.9679 9.71714 33.089 10.596L15.089 28.596C14.2101 29.475 12.7827 29.475 11.9038 28.596L2.90381 19.596C2.0249 18.7171 2.0249 17.2898 2.90381 16.4109C3.78271 15.532 5.21006 15.532 6.08896 16.4109L13.4999 23.8148L29.9108 7.41089C30.7897 6.53198 32.2171 6.53198 33.096 7.41089H33.089Z"
					/>
				</svg>
				&nbsp;
				<span translate>energy_advisor.mark_as_done</span>
			</div>
		</eliq-basic-button>

		<eliq-basic-button
			*ngIf="showAddToTodoButton"
			(clicked)="moveToTodoClicked()"
			[custom]="{'padding': '11px 32px'}"
			class="button"
		>
			<div class="button-content">
				<inline-svg
					class="energy-advisor-todo-button-icon"
					[inlineSVG]="'/assets/images/add_todo.svg' | imgSrc"
					[setSVGAttributes]="{'style': 'height: 24px; width: auto; max-width: 24px; max-height: 24px;  margin-right: 8px;'}"
					alt=""
					style="max-height: 24px;"
				></inline-svg>

				<span translate>energy_advisor.add_to_todo</span>
			</div>
		</eliq-basic-button>
	</div>
	<span
		*ngIf="showDiscardButton"
		[ngStyle]="{display: 'block', width: 'max-content', margin: '20px auto 0 auto', color: 'red', cursor: 'pointer'}"
		(click)="discardClicked()"
		class="button"
		translate
	>
		energy_advisor.discard
	</span>
</div>
