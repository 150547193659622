import { Component, Input, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Property, PropertyGroup } from '@eliq/core'
import { InsightsHomeprofileSetupComponent } from '../insights-homeprofile-setup/insights-homeprofile-setup.component'

@Component({
	selector: 'eliq-insights-home-profile-wizard-modal',
	templateUrl: './insights-home-profile-wizard-modal.component.html',
	styleUrls: ['./insights-home-profile-wizard-modal.component.scss'],
	standalone: true,
	imports: [InsightsHomeprofileSetupComponent],
})
export class InsightsHomeProfileWizardModalComponent {
	@Input() properties: Property[]
	@Input() propertyGroups: PropertyGroup[]
	@Input() locationName: string
	@Input() locationId: number

	constructor(
		public dialogRef: MatDialogRef<InsightsHomeProfileWizardModalComponent>,
	) {}

	setupDone() {
		this.dialogRef.close()
	}
}
