// @ts-nocheck

import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { HomechartConfig } from './homechart-config.model'

@Injectable({
	providedIn: 'root',
})
export class HomechartConfigService {
	constructor(private config: JsonGetterService) {}

	getCategoryType(): Observable<string> {
		return this.getHomechartConfig().pipe(
			map((config) => config.category_type),
			catchError(() => of('standard')),
		)
	}

	getIsTemperatureVisible(): Observable<boolean> {
		return this.getHomechartConfig().pipe(
			map((config) => config.show_temp),
			catchError(() => of(false)),
		)
	}

	private getHomechartConfig(): Observable<HomechartConfig> {
		return this.config.getConfig().pipe(
			map((config) => config?.enabled_home_cards?.homechart),
			catchError((e) => {
				console.error('Could not get homechart config')
				return of({})
			}),
		)
	}
}
