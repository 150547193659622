import {
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core'
import { OnscreenConsoleComponent } from '../onscreen-console.component'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'eliq-onscreen-console-container',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './onscreen-console-container.component.html',
	styleUrls: ['./onscreen-console-container.component.scss'],
	encapsulation: ViewEncapsulation.ShadowDom,
})
export class OnscreenConsoleContainerComponent implements OnInit, OnDestroy {
	@ViewChild('eliqOnscreenConsoleRef', { read: ViewContainerRef })
	eliqOnscreenConsoleRef!: ViewContainerRef

	public consoleLogs: any[] = []

	private hiddenOpenConsoleClicks = 0
	hiddenOpenConsoleClicked() {
		this.hiddenOpenConsoleClicks += 1
		if (this.hiddenOpenConsoleClicks > 10) {
			this.showOnscreenConsole()
		}
		setTimeout(
			(() => {
				this.hiddenOpenConsoleClicks = 0
			}).bind(this),
			4000,
		)
	}

	showOnscreenConsole() {
		this.eliqOnscreenConsoleRef.clear()
		const componentRef = this.eliqOnscreenConsoleRef.createComponent(
			OnscreenConsoleComponent,
		)
		componentRef.instance.consoleLogs = this.consoleLogs
	}

	ngOnDestroy() {
		;(window as any).console.log = this.oldConsoleLog
		;(window as any).console.error = this.oldConsoleError
		;(window as any).console.warn = this.oldConsoleWarn
		;(window as any).console.trace = this.oldConsoleTrace
	}

	public oldConsoleLog
	public oldConsoleError
	public oldConsoleWarn
	public oldConsoleTrace
	ngOnInit(): void {
		this.oldConsoleLog = (window as any).console?.log
		this.oldConsoleError = (window as any).console?.error
		this.oldConsoleWarn = (window as any).console?.warn
		this.oldConsoleTrace = (window as any).console?.trace

		if ((window as any)?.console?.log) {
			;(window as any).console.log = function (e: Error, ...args) {
				this.consoleLogs.push(JSON.stringify(e))
				this.consoleLogs.push(...args)
			}.bind(this)
		}

		if ((window as any)?.console?.error) {
			;(window as any).console.error = function (e: Error, ...args) {
				this.consoleLogs.push({
					message: JSON.stringify(e.message ?? e.name ?? e, null, 2),
					stack: JSON.stringify(
						{ stack: e.stack ?? e.name ?? e.message ?? null, args: args },
						null,
						2,
					).replace(/\\n/g, '\n'),
					type: 'error',
				})
				//this.oldConsoleError(e)
			}.bind(this)
		}

		if ((window as any)?.console?.trace) {
			;(window as any).console.trace = function (...args) {
				this.consoleLogs.push({
					message: JSON.stringify(args, null, 2),
					stack: 'this is a trace()',
					type: 'error',
				})
				//this.oldConsoleError(e)
			}.bind(this)
		}

		if ((window as any)?.console?.warn) {
			;(window as any).console.warn = function (e) {
				this.consoleLogs.push({
					message: JSON.stringify(e, null, 2),
					type: 'warn',
				})
				//this.oldConsoleWarn(e)
			}.bind(this)
		}
	}
}
