import { Injectable } from "@angular/core";
import { APIFuelData } from "@eliq/core/models/src/api-models/api-data-raw.modal";
import { APILocation, CoreDataStoreService, DATA_STREAMS, DataStreamType, FuelType, LocationHttpService, ResolutionType, UnitType } from "../..";
import { AsyncSubject, Observable, OperatorFunction, map, of, switchMap, takeUntil, takeWhile, tap, timer } from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class WaitForDataService {

	public specificDataMap: {
		[locationId: number]: {
			[dataStream in DataStreamType]: {
				[unitType in UnitType]: {
					[resolutionType in ResolutionType]: {
						[FuelType.ELEC]: boolean
					}
				}
			}
		}
	} = {}

	constructor(private locationService: LocationHttpService) {}


	public locationGetFuelData(
		locationId,
		fuelType = FuelType.ELEC,
		dataStream: Readonly<DataStreamType> = 'consumption' as const,
		unitType: Readonly<UnitType> = 'energy' as const,
		resolutionType = ResolutionType.Month,
		fromDt = (() => {
			const date = new Date()
			;(date as any).setUTCHours(0, 0, 0, 0)
			date.setMonth(date.getMonth() - 12)
			return date
		})(),
		toDt = (() => {
			const date = new Date()
			;(date as any).setUTCHours(0, 0, 0, 0)
			return date
		})(),
	): Observable<APIFuelData<typeof dataStream> | null> {
		return this.locationService.getLocationFuelData(
			locationId,
			fuelType,
			dataStream,
			unitType,
			resolutionType,
			fromDt,
			toDt
		)
	}

	public locationHasElecConsumption(
		locationId,
	): Observable<boolean> {
		return this.locationGetFuelData(locationId, FuelType.ELEC, 'consumption', 'energy', ResolutionType.Month ).pipe(
			this.hasFuelDataOp(),
		)
	}

	public locationHasSpecificData(
		locationId,
		fuelType = FuelType.ELEC,
		dataStream: Readonly<DataStreamType> = 'consumption' as const,
		unitType: Readonly<UnitType> = 'energy' as const,
		resolutionType = ResolutionType.Month,
		cache = false
	): Observable<boolean> {
		const cachedResult = this.specificDataMap[locationId]?.[dataStream]?.[unitType]?.[resolutionType]?.[fuelType];
		if (cache && cachedResult !== undefined) {
			return of(cachedResult);
		}

		return this.locationGetFuelData(locationId, fuelType, dataStream, unitType, resolutionType).pipe(
			this.hasFuelDataOp(),
			tap(hasData => {
				this.specificDataMap[locationId] = {
					...this.specificDataMap[locationId],
					[dataStream]: {
						[unitType]: {
							[resolutionType]: {
								[fuelType]: hasData
							}
						}
					}
				};
			})
		);
	}

	public locationWaitForSpecificData(
		locationId,
		fuelType = FuelType.ELEC,
		dataStream: Readonly<DataStreamType> = 'consumption' as const,
		unitType: Readonly<UnitType> = 'energy' as const,
		resolutionType = ResolutionType.Month,
	): Observable<boolean> {
		const subject$ = new AsyncSubject<boolean>()
		let attempts = 0
		timer(0, 2000).pipe(
			takeUntil(subject$),
			switchMap(() => this.locationHasSpecificData(locationId, fuelType, dataStream, unitType, resolutionType, false)),
			tap((hasData) => {
				subject$.next(hasData)
				if (hasData || attempts > 10) {
					subject$.complete()
				}
				attempts++
			}),
		).subscribe()
		return subject$
	}


	private hasFuelDataOp<T extends DataStreamType>(): OperatorFunction<
	APIFuelData<T> | null,
	boolean
> {
		return (
			fuelDataObs: Observable<APIFuelData<T> | null>,
		): Observable<boolean> => {
			if (!fuelDataObs) {
				return of(false)
			}
			return fuelDataObs.pipe(
				switchMap((fuelData: APIFuelData<T> | null): Observable<boolean> => {
					if (
						DATA_STREAMS.some((x) => {
							const data = fuelData?.[x]
							return Array.isArray(data) && data.some((y) => y !== null && y !== 0)
						})
					) {
						return of(true)
					}

					return of(false)
				}),
			)
		}
	}

}
