import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { map } from 'rxjs/operators'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { LinkComponent } from '@eliq/ui'
import { NgIf, AsyncPipe } from '@angular/common'
import { BasicButtonComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-signin-magiclink-having-trouble',
	templateUrl: './signin-magiclink-having-trouble.component.html',
	styleUrls: ['./signin-magiclink-having-trouble.component.scss'],
	standalone: true,
	imports: [
		TranslateModule,
		BasicButtonComponent,
		NgIf,
		LinkComponent,
		AsyncPipe,
		ImgSrcPipe,
	],
})
export class SigninMagiclinkHavingTroubleComponent implements OnInit {
	@Input() userEmail: string
	@Output() sendNewEmail = new EventEmitter()

	public number = this.jsonGetter
		.getContact()
		.pipe(map((contact) => contact.phone))

	constructor(private jsonGetter: JsonGetterService) {}

	ngOnInit(): void {}
}
