import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

interface HomeProfileSetupConfig {
	intro_illustration: string
	show_intro_disclaimer: boolean
}

@Injectable({
	providedIn: 'root',
})
export class HomeProfileSetupConfigService {
	constructor(private config: JsonGetterService) {}

	getHomeProfileSetupIllustrationSrc(): Observable<string> {
		return this.hpConfig().pipe(map((res) => res.intro_illustration))
	}

	showHomeProfileIntroDisclaimer(): Observable<boolean> {
		return this.hpConfig().pipe(
			map((res) => {
				if (res.show_intro_disclaimer == undefined) return true
				// default to true
				else return res.show_intro_disclaimer
			}),
		)
	}

	private hpConfig(): Observable<HomeProfileSetupConfig> {
		return this.config.getConfig('home_profile_setup').pipe(
			map((response) => (response != null ? response : {})), // catch undefined, return empty obj
		)
	}
}
