<div class="icons">
	<eliq-property-icon
		[imgPath]="'/assets/images/' + property.$key + '.svg' | imgSrc"
		[altText]="property.$key"
	></eliq-property-icon>
</div>
<p class="secondary-p2 question">
	{{'home_profile_prompt.question_' + property.$key | translate}}
</p>
<form [formGroup]="form">
	<label
		*ngFor="let option of property.$possibleValues"
		class="input-radio-container"
		[class.selected]="form.value.option === option.value"
	>
		<span class="primary-p2">{{ translateOption(option) }}</span>
		<input
			type="radio"
			[id]="option.name"
			[value]="option.value"
			(click)="radioClicked(option.value)"
			formControlName="option"
		/>
		<span class="input-radio-checkmark"></span>
	</label>
</form>
