import { Component, Input, OnInit } from '@angular/core'
import { APICO2Emission } from '@eliq/core'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'

@Component({
	selector: 'eliq-co2-card',
	templateUrl: './co2-card.component.html',
	styleUrls: ['./co2-card.component.css'],
	standalone: true,
	imports: [CardComponent],
})
export class Co2CardComponent {
	@Input() header = ''
	@Input() text = ''
	@Input() imgSrc = ''
}
