<eliq-simple-homecard>
	<img src="assets/images/connect-meter.svg" class="homecard-image" />
	<div class="homecard-content">
		<h6 class="primary-h6 header" translate>
			{{headerKey}}
		</h6>
		<p class="primary-p1" translate>
			{{descriptionKey}}
		</p>
	</div>
	<eliq-link
		class="homecard-link"
		[href]="linkUrl"
		[label]="linkKey"
		(clicked)="linkClicked($event)"
	></eliq-link>
</eliq-simple-homecard>
