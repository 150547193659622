import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { Property, PropertyGroup } from '@eliq/core'
import { HomeProfileSetupConfigService } from '../../services/home-profile-setup-config.service'
import { HomedetailsSetupStepperComponent } from '../homedetails-setup-stepper/homedetails-setup-stepper.component'
import { BasicButtonComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'
import { NgIf, AsyncPipe } from '@angular/common'

@Component({
	selector: 'eliq-homedetails-setup',
	templateUrl: './homedetails-setup.component.html',
	styleUrls: ['./homedetails-setup.component.css'],
	standalone: true,
	imports: [
		NgIf,
		TranslateModule,
		BasicButtonComponent,
		HomedetailsSetupStepperComponent,
		AsyncPipe,
	],
})
export class HomedetailsSetupComponent implements OnInit {
	@Input() locationName: string
	@Input() locationId: number
	@Input() properties: Property[]
	@Input() propertyGroups: PropertyGroup[]
	@Input() askToStart = false
	@Input() thank = true
	@Input() noX = false

	@Output() done = new EventEmitter<string>()
	@Output() loading = new EventEmitter<boolean>()

	public state = ''
	public illustration$ = this.config.getHomeProfileSetupIllustrationSrc()
	public showDisclaimer$ = this.config.showHomeProfileIntroDisclaimer()

	public enableThanks = true

	constructor(private config: HomeProfileSetupConfigService) {}

	ngOnInit(): void {
		if (this.askToStart) {
			this.state = 'starting'
		} else {
			this.state = 'started'
		}
	}

	startClick() {
		this.state = 'started'
	}

	dontStartClick() {
		this.done.emit('dontStart')
	}

	stepperDone(status: string) {
		this.state = status
		if (!this.enableThanks) {
			this.done.emit('done')
		}
		this.loading.emit(false)
	}

	stepperLoading(loading: boolean) {
		this.loading.emit(loading)
	}

	closeClick() {
		this.done.emit('closed')
	}
}
