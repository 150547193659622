import { registerLocaleData } from '@angular/common'
import { bootstrapApplication } from '@angular/platform-browser'
import { AppComponent } from '@eliq/app'
import { appConfig } from '@eliq/app'
//import { enableProdMode } from '@angular/core'
import { environment } from './environments/environment'

if (Array.isArray(environment.locales)) {
	for (const locale of environment.locales) {
		import(`node_modules/@angular/common/locales/${locale}.mjs`).then(
			(locale) => {
				registerLocaleData(locale.default)
			},
		)

		//import(
		/* webpackInclude: /^(sv|de|en|es|fr|it|nl|no|pl|pt-BR|pt|fi|sv|ko|ru|zh|zh-Hans|zh-Hant|ja)\.js/ */
		/* webpackMode: "lazy-once" */
		/* webpackChunkName: "i18n-base" */
		//	`@angular/common/locales/${locale}`
		//).then((m) => {
		//	const basePkg = `locale${locale[0].toUpperCase()}${locale.slice(1)}`
		//
		//	registerLocaleData(m[basePkg])
		//})
	}
}

//if (environment.production) {
//	enableProdMode()
//}

const bootstrap = () => bootstrapApplication(AppComponent, appConfig)

bootstrap()
