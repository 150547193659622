<eliq-card
	class="annual-card-style"
	[style.border-style]=" greyBorder ? 'solid' : 'none'"
	[style.border-width]=" greyBorder ? '1px' : '0px'"
	[style.border-color]=" greyBorder ? '#DCDCDC' : '#0000ffff'"
	[style.max-width]=" greyBorder ? '98%' : '100%'"
	[style.color]=" greyBorder ? 'black' : '#003665'"
>
	<div *ngIf="getScreenWidth > 1249; then desktop; else mobile"></div>

	<ng-template #desktop>
		<div class="desktop-container">
			<img
				[src]="'/assets/images/annual_energy_consumption.svg'"
				alt=""
				height="65"
				class="desktop-icon"
			/>
			<div class="text-part desktop-text">
				<p>{{introductionText}}</p>
				<p class="add-yearly" (click)="addClicked()" (done)="hide($event)">
					<b>
						<u>{{addButtonText}}</u>
					</b>
				</p>
			</div>
			<img
				[src]="'/assets/images/information_circle_outline.svg'"
				alt=""
				height="40"
				class="desktop-info info"
				(click)="infoClicked()"
			/>
		</div>
	</ng-template>

	<ng-template #mobile>
		<div *ngIf="getScreenWidth > 865; then big; else small"></div>

		<ng-template #big>
			<div class="pad-container">
				<img
					[src]="'/assets/images/annual_energy_consumption.svg'"
					alt=""
					height="65"
					class="desktop-icon"
				/>
				<div class="text-part pad-text">
					<p>{{introductionText}}</p>
					<p class="add-yearly" (click)="addClicked()" (done)="hide($event)">
						<b>
							<u>{{addButtonText}}</u>
						</b>
					</p>
				</div>
				<img
					[src]="'/assets/images/information_circle_outline.svg'"
					alt=""
					height="40"
					class="desktop-info info"
					(click)="infoClicked()"
				/>
			</div>
		</ng-template>

		<ng-template #small>
			<div class="mobile-container">
				<div class="mobile-top">
					<div class="mobile-description">
						<img
							[src]="'/assets/images/annual_energy_consumption.svg'"
							alt=""
							height="52"
							class="mobile-icon"
						/>
						<p class="text-part mobile-text">{{introductionText}}</p>
					</div>
					<img
						class="mobile-info info"
						[src]="'/assets/images/information_circle_outline.svg'"
						alt=""
						height="25"
						(click)="infoClicked()"
					/>
				</div>
				<p
					class="add-yearly mobile-add"
					(click)="addClicked()"
					(done)="hide($event)"
				>
					<b>
						<u>{{addButtonText}}</u>
					</b>
				</p>
			</div>
		</ng-template>
	</ng-template>
</eliq-card>
