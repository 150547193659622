<div class="inputContainer" *ngFor="let reg of registers; let reg_i = index">
	<div class="label">
		<span>
			{{ 'submit_meter_read.' +  reg.type + '_register_read' | translate }}
		</span>
	</div>

	<div *ngIf="reg.digits < 10">
		<input
			#inputFieldsReference
			(keydown)="shortReadDigitsChanged($event)"
			*ngFor="let empty of [].constructor(reg.digits); let digit_i = index"
			[attr.data-index]="reg_i*reg.digits+digit_i"
			class="digit"
			type="text"
			maxlength="1"
			inputmode="numeric"
		/>
	</div>
	<div *ngIf="reg.digits > 9">
		<input
			[attr.data-reg-index]="reg_i.toString()"
			[attr.maxlength]="reg.digits"
			(keyup)="longReadDigitsChanged($event)"
			class="textField"
			type="text"
			eliqDigitOnly
			inputmode="numeric"
			pattern="[0-9]*"
		/>
	</div>

	<div
		*ngIf="reg.previous_read else noPrevRead"
		class="secondary-p3 previous-read"
	>
		{{ 'submit_meter_read.last_read' | translate : { date: reg.previous_read_date | eliqdate:'short' , id: reg.previous_read} }}
		<!--dd/MM/Y-->
	</div>
</div>

<ng-template #noPrevRead>
	<div class="secondary-p3 previous_read">
		{{ 'submit_meter_read.no_previous_read' | translate }}
	</div>
</ng-template>
