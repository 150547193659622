<div class="container">
	<h1 class="primary-title was-title" translate>
		notifications_view.notifications
	</h1>

	<div class="toggle-buttons-container">
		<div class="shadow shadow-left"></div>
		<div class="shadow shadow-right"></div>
		<div
			(onload)="toggleScroll($event)"
			(scroll)="toggleScroll($event)"
			class="togglebuttons"
		>
			<div
				class="notification-toggle-button"
				*ngFor="let toggle of toggleButtons"
			>
				<eliq-toggle-button
					[toggleButton]="toggle"
					[translatorPath]="'notifications_categories.'"
					(clickEmitter)="toggleButtonClicked(toggle.$label)"
				></eliq-toggle-button>
			</div>
		</div>
	</div>

	<div class="list">
		<div
			class="list-item"
			*ngFor="let notification of showedNotifications; let i = index"
		>
			<eliq-notification-item-wrapper
				[index]="i"
				[notification]="notification"
			></eliq-notification-item-wrapper>
		</div>
		<eliq-pager
			*ngIf="nPages > 1"
			[nPages]="nPages"
			[currentPage]="currentPage"
			(selectedPageEmitter)="updatePages($event)"
		></eliq-pager>
	</div>
</div>
