import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	OnDestroy,
} from '@angular/core'
import {
	FormGroup,
	FormBuilder,
	FormControl,
	FormGroupDirective,
	NgForm,
	Validators,
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { TicketStatusType } from '@eliq/feature/auth'
import { LoginHelperService } from '@eliq/feature/login/services/login-helper/login-helper.service'
import { ModalService } from '@eliq/ui/modal'
import { of, Subscription } from 'rxjs'
import { catchError, switchMap, take, tap } from 'rxjs/operators'
import { SigningMagiclinkAccountnrHelpComponent } from '../signing-magiclink-accountnr-help/signing-magiclink-accountnr-help.component'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { SigninMagiclinkHavingTroubleComponent } from '../signin-magiclink-having-trouble/signin-magiclink-having-trouble.component'
import { SigninMagiclinkVerificationComponent } from '../signin-magiclink-verification/signin-magiclink-verification.component'
import { BasicButtonComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { NgIf } from '@angular/common'
import { SigninCardTwoComponent } from '../../signin-card-two/signin-card-two.component'
import { InlineSVGModule } from 'ng-inline-svg-2'

export class SigninErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null,
	): boolean {
		const isSubmitted = form && form.submitted
		return !!(control && control.invalid && isSubmitted)
	}
}

enum Headers {
	Login = 'sign_in_start.sign_in',
	VerifyMagiclink = 'sign_in_verify_magic_link.check_email_inbox',
	TroubleLoggingIn = 'sign_in_verify_magic_link.having_trouble_logging_in',
}

enum SigninAccNrStateType {
	Started = 'started',
	Initiating = 'initiating',
	Initiated = 'initiated',
	Trouble = 'trouble',
	Complete = 'complete'
}
@Component({
	selector: 'eliq-signin-magiclink-accountnr',
	templateUrl: './signin-magiclink-accountnr.component.html',
	styleUrls: ['./signin-magiclink-accountnr.component.scss'],
	standalone: true,
	imports: [
		SigninCardTwoComponent,
		NgIf,
		FormsModule,
		ReactiveFormsModule,
		InlineSVGModule,
		MatFormFieldModule,
		MatInputModule,
		TranslateModule,
		BasicButtonComponent,
		SigninMagiclinkVerificationComponent,
		SigninMagiclinkHavingTroubleComponent,
		ImgSrcPipe,
	],
})
export class SigninMagiclinkAccountnrComponent implements OnInit, OnDestroy {
	@Output() logInDone = new EventEmitter()

	public inputText = ''
	public showErrors = false
	public isSubmitButtonClicked = false

	public form: FormGroup

	public signinErrorStateMatcher = new SigninErrorStateMatcher()

	public state: SigninAccNrStateType = SigninAccNrStateType.Started

	public emailControl: AbstractControl
	public accountNumberControl: AbstractControl
	public userEmail = ''
	// signin card variables
	public currentHeader = ''
	public showBackButton: boolean

	// subs
	private ticketSub: Subscription

	constructor(
		private formBuilder: FormBuilder,
		private loginHelper: LoginHelperService,
		private modal: ModalService,
	) {}

	ngOnInit() {
		this.currentHeader = Headers.Login

		this.form = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			account_number: ['', [Validators.required, Validators.minLength(5)]],
		})

		this.emailControl = this.form.get('email') as any
		this.accountNumberControl = this.form.get('account_number') as any
	}

	onSubmit(resend = false) {
		if (!this.form.valid) {
			this.showErrors = true
		} else {
			this.showErrors = false

			if (!resend) {
				this.setState('initiating')
			}

			if (this.ticketSub) {
				this.ticketSub?.unsubscribe()
				this.ticketSub = undefined as any
			}

			this.ticketSub = this.loginHelper
				.getMagiclinkTicketEmailAccountnr(
					this.emailControl.value,
					this.accountNumberControl.value,
				)
				.pipe(
					take(1),
					tap((result) => this.setState('initiated')),
					switchMap((result) => {
						return this.loginHelper.startTicketVerificationAndLogin(
							result.ticket.id,
						)
					}),
					catchError((err, c) => {
						this.ticketSub?.unsubscribe()
						this.setState('started')
						this.loginHelper.handleLoginError(err)
						return c
					}),
				)
				.subscribe((ticketStatus) => {
					if (ticketStatus == TicketStatusType.Completed) {
						this.ticketSub?.unsubscribe()
						this.logInDone.emit()
					}
				})
		}
	}

	accountNumberHelpClicked() {
		this.modal.openModal(SigningMagiclinkAccountnrHelpComponent)
	}

	setState(state: string) {
		this.state = state as SigninAccNrStateType
		switch (state) {
			case 'started': {
				this.showBackButton = false
				this.currentHeader = Headers.Login
				return
			}

			case 'initiating': {
				this.showBackButton = false
				this.currentHeader = Headers.Login
				return
			}

			case 'initiated': {
				this.showBackButton = true
				this.currentHeader = Headers.VerifyMagiclink
				return
			}

			case 'trouble': {
				this.showBackButton = true
				this.currentHeader = Headers.TroubleLoggingIn
				return
			}

			default:
				console.error("Invalid SigninAccNrStateType:", state)
				return
		}
	}

	sendNewEmail() {
		this.onSubmit(true)
		this.setState('initiated')
	}

	backClicked() {
		if (this.state === 'initiated') {
			this.setState('started')
		} else if (this.state === 'trouble') {
			this.setState('initiated')
		}
	}

	ngOnDestroy() {
		if (this.ticketSub) {
			this.ticketSub?.unsubscribe()
		}
	}
}
