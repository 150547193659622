import { Injectable } from '@angular/core'
import { AngularFireAnalytics } from '@angular/fire/compat/analytics'
import { PeriodType } from '../../../models'
import {
	EventType,
	TrackingEventName,
	TrackingScreen,
	getAccountScreenNameFromSetting,
} from '../models'

@Injectable({
	providedIn: 'root',
})
export class EliqTrackingService {
	private currentPage = 'not yet set'

	constructor(private fa: AngularFireAnalytics) {}

	setUserId(userId: number | null) {
		this.fa.setUserId(userId?.toString() ?? 'null')
	}

	/**
	 * Takes in all navigation events and sends a firebase tracking event if it matches some specified criteria
	 * Currently only handles one-deep routes, as in /home, /insights. /account/xyz is not supported for example
	 * @param route a string which can contain both the leading route slash (eg /account) and following parameters
	 * @param params a json object with all params
	 */
	trackScreenOnRouterEvent(route: string, params: any) {
		route = route.split('?')[0]
		this.sendScreenViewEvent(route, params)
	}

	logEvent(eventName: TrackingEventName, data: EventType) {
		data.page_title = this.currentPage
		this.fa.logEvent(eventName, data)
	}

	logScreenViewEvent(screenName: TrackingScreen) {
		this.currentPage = screenName
		this.fa.logEvent('screen_view', {
			firebase_screen: screenName,
		})
	}

	parseRoute(route: string) {
		const regex = /[?&]([^=#]+)=([^&#]*)/g,
			url = route,
			params = {}

		let match: any = null

		while ((match = regex.exec(url))) {
			;(params as any)[match[1]] = match[2]
		}
	}

	logInsightsScreenViewEvent(periodType: PeriodType) {
		switch (periodType) {
			case PeriodType.Minute:
				return this.logScreenViewEvent(TrackingScreen.InsightsMinute)
			case PeriodType.Day:
				return this.logScreenViewEvent(TrackingScreen.InsightsDay)
			case PeriodType.Week:
				return this.logScreenViewEvent(TrackingScreen.InsightsWeek)
			case PeriodType.Month:
				return this.logScreenViewEvent(TrackingScreen.InsightsMonth)
			case PeriodType.Year:
				return this.logScreenViewEvent(TrackingScreen.InsightsYear)
			default: {
				console.warn(
					'Invalid periodType in logInsightsScreenViewevent:',
					periodType,
				)
				return
			}
		}
	}

	private sendScreenViewEvent(route: string, params: any) {
		switch (route) {
			case '/account':
				return this.sendAccountScreenViewEvent(params)
			case '/login':
				return this.logScreenViewEvent(TrackingScreen.Login)
			case '/home':
				return this.logScreenViewEvent(TrackingScreen.Home)
			case '/notifications':
				return this.logScreenViewEvent(TrackingScreen.Notifications)
			case '/bills':
				return this.logScreenViewEvent(TrackingScreen.Bills)
			case '/budget':
				return this.logScreenViewEvent(TrackingScreen.Budget)
			case '/monitors':
				return this.logScreenViewEvent(TrackingScreen.WebMonitors)
			case '/energy-advisor':
				return this.logScreenViewEvent(TrackingScreen.WebTips)
			case '/tickets':
				return this.logScreenViewEvent(TrackingScreen.WebTickets)
			//case '/insights':
			//return this.logScreenViewEvent(TrackingScreen.InsightsMonth)
			case '/webview-agreements':
				return this.logScreenViewEvent(TrackingScreen.WebViewAgreements)
			default: {
				if (route.includes('/insights')) {
					return
				}
				console.warn('No screen view event sent! Route:', route)
				return
			}
		}
	}

	private sendAccountScreenViewEvent(params: any) {
		if (params.setting) {
			this.logScreenViewEvent(
				getAccountScreenNameFromSetting(params.setting) ??
					TrackingScreen.AccountMenu,
			)
		}
	}
}
