import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	inject,
	AfterViewInit,
	OnChanges,
} from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { filter, take } from 'rxjs/operators'
import { NavigationEnd } from '@angular/router'
import { Category } from './../../models/Category.model'
import { HeaderObject } from './../../models/HeaderObject.model'
import { HeaderStateService } from '../../services/header-state.service'
import { CoreDataStoreService, Location } from '@eliq/core'
import { EnvironmentService } from '@eliq/data-access'
import { HeaderConfigService } from '@eliq/core/config'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { LocationSwitchComponent } from '../location-switch/location-switch.component'
import { TranslateModule } from '@ngx-translate/core'
import { NotificationBellComponent } from '../notification-bell/notification-bell.component'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common'
import { EliqThemeService } from '@eliq/theme'
import { CompanyLogoComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'
import { Observable } from 'rxjs'
@Component({
	selector: 'eliq-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		NgFor,
		RouterLink,
		NgClass,
		InlineSVGModule,
		NotificationBellComponent,
		TranslateModule,
		LocationSwitchComponent,
		CompanyLogoComponent,
		AsyncPipe,
		ImgSrcPipe,
		LinkComponent,
	],
})
export class HeaderComponent implements OnChanges {
	@Input() showMenuOptions = true
	@Input() currentPath?: string
	@Input() accountCategories?: Category[]
	@Input() headers!: HeaderObject[]
	@Input() showNotificationBell = true
	@Input() locations?: Location[]
	@Input() currentLocation?: Location
	@Input() options: any = {}
	@Input() kbcTypeHeader = false
	@Input() kbcNavIcons = false

	@Output() logout: EventEmitter<any> = new EventEmitter()

	private hamburgerWidth = 1122

	public isMenuToggle = false
	public showNotifications = false
	public username? = ''
	public isPopperDisabled = false
	public accountExpanded = false

	public middleSvgAttributes = {
		width: '30',
		height: '30',
	}

	private currentPage?: string

	public headerStateService = inject(HeaderStateService)
	private coreDS = inject(CoreDataStoreService)
	private router = inject(Router)
	private headerConfig = inject(HeaderConfigService)
	public showAccountLink = false

	public companyLogoUrl$ = this.headerConfig.getCompanyLogoUrl() || '/home'
	public companyLogoStyle$: Observable<Record<string, string>> = this.headerConfig.getCompanyLogoStyle() || {}
	public themeService = inject(EliqThemeService)
	public env = inject(EnvironmentService)

	public noLogoWhenHamburger$ = this.headerConfig.getNoLogoWhenHamburger()

	ngOnChanges(): void {
		if (!this.showMenuOptions || !this.headers) return

		this.headers = this.headers?.filter((val) => {
			if (val.link === 'monitors' || val.link === '/monitors') {
				return false
			}
			return true
		})

		if (this.options.hamburgerWidth)
			this.hamburgerWidth = this.options.hamburgerWidth
		this.headerStateService.isHamburger =
			this.env.isBrowser() && window?.innerWidth < this.hamburgerWidth

		this.coreDS.user.subscribe((user) => {
			if (user.forname && user.surname) {
				this.username = user.forname + ' ' + user.surname
			}
		})

		//this.coreDS.locations.subscribe(locations => { this.locations = locations });
		// this.coreDS.getActiveLocation().subscribe(location => {
		// 	console.log("DEBUG getActiveLocation in header:", location)
		// 	this.currentLocation = location
		// })

		this.headerStateService.setHeaders(this.headers)
		this.headerConfig.getShowAccount().subscribe((showAccount) => {
			this.showAccountLink = showAccount
		})

		this.headerConfig.getShowNotifications().subscribe((showNotifications) => {
			this.showNotificationBell = showNotifications
		})

		//
		this.checkHeaderRoute()
	}

	OnMenuToggle() {
		this.isMenuToggle = !this.isMenuToggle
	}

	headerResized(event: any) {
		this.setSizingVariables(event)
	}

	checkHeaderRoute() {
		this.navClicked(this.router.url)
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event) => {
				event = <NavigationEnd>event
				this.navClicked(event.url)
			})
	}

	accountLinkClicked() {
		this.isMenuToggle = false
		this.accountExpanded = false
	}

	showLocationSwitch(): boolean {
		if (!this.showMenuOptions) {
			return false
		}
		return (
			(!!this.locations &&
				!!this.currentLocation &&
				!this.headerStateService?.accountSelected &&
				!this.headerStateService?.notificationsSelected) ||
			false
		) //&&
		//this.currentPage !== "/bills";
	}

	navClicked(link: string) {
		this.currentPage = link
		this.headerStateService.accountSelected = link.includes('/account')
		this.headerStateService.notificationsSelected =
			link.includes('/notifications')

		this.headers?.forEach((header) => {
			if (link.includes('/' + header.link)) {
				header.selected = true
			} else {
				header.selected = false
			}
		})

		this.headerStateService.setHeaders(this.headers)

		this.isPopperDisabled = this.headerStateService.notificationsSelected
	}

	showNotificationsClicked() {
		this.router.navigate(['/notifications'])
	}

	accountArrowClick() {
		this.accountExpanded = !this.accountExpanded
	}

	toggleNotifications() {
		if (!this.headerStateService.notificationsSelected) {
			this.showNotifications = !this.showNotifications
		}
	}

	newLocationSelected(locId: number) {
		this.coreDS.locations.pipe(take(1)).subscribe((locations) => {
			if (locations.length > 0) {
				this.coreDS.setActiveLocation(locId)
			}
		})
	}

	clickedOutside() {
		if (this.showNotifications) {
			this.showNotifications = false
		}
	}

	public get selectedHeader(): HeaderObject {
		const result = this.headers?.find((header) => header.selected)
		if (!result) {
			if (this.headerStateService.accountSelected) {
				return new HeaderObject(
					'/account',
					'settings_menu_options.my_account',
					'account',
				)
			} else if (this.headerStateService.notificationsSelected) {
				return new HeaderObject(
					'/notifications',
					'notifications_view.notifications',
					'notifications',
				)
			} else {
				return new HeaderObject('/home', 'menu_options.home', 'home')
			}
		}
		return result
	}
	setPopperDisabledValue(event: any) {
		// disable popper if we 1. are on small screen or 2. are already in /notifications.
		this.isPopperDisabled =
			window?.innerHeight < 450 ||
			event.target.innerWidth < 450 ||
			this.headerStateService.notificationsSelected
	}

	setSizingVariables(event: any) {
		this.setPopperDisabledValue(event)
		this.setIsHamburger(event)
	}

	setIsHamburger(event: any) {
		this.headerStateService.isHamburger =
			event.target.innerWidth < this.hamburgerWidth
		if (!this.headerStateService.isHamburger) {
			this.accountExpanded = false
		}
	}

	logoutClicked() {
		this.logout.emit()
	}

	headerItemClicked() {
		this.isMenuToggle = false
		this.accountExpanded = false
	}
}
