<div class="icons">
	<eliq-property-icon
		[imgPath]="'/assets/images/' + property.$key + '.svg' | imgSrc"
		[altText]="property.$key"
	></eliq-property-icon>
</div>
<p class="question secondary-p2">
	{{'home_profile_prompt.question_' + property.$key | translate}}
</p>
<table>
	<tbody>
		<tr *ngFor="let option of property.$possibleValues">
			<td>
				<label
					class="middle-text primary-p2"
					[class.selected]="isKeySelected(option.value)"
					translate
				>
					<mat-checkbox
						id="checkbox-{{option.value}}"
						[disableRipple]="true"
						[checked]="isKeySelected(option.value)"
						(change)="valueChange(option.value, $event.checked)"
					></mat-checkbox>
					home_profile_options.{{option.value}}
				</label>
			</td>
		</tr>
	</tbody>
</table>
