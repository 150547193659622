import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { DayString } from '../../public_api'
import { MonthYearString } from '../../public_api'
import { Period } from '../../public_api'
import { WeekStringParts } from '../../public_api'
import { PeriodType } from '../../public_api'
import { endOfWeek, startOfWeek } from 'date-fns'
import { LanguageService } from '../../public_api'
import { RequireAtLeastOne } from '../../public_api'
import { CommonConfig } from '../../public_api'
import { CommonConfigService } from '../../public_api'

type _simpleStyles = 'full' | 'long' | 'medium' | 'short'

// This works in newer typescript versions but not here unfortunately :/
//export type SimpleStyles =
//  | `${'time' | _simpleStyles}`
//  | `${'time' | _simpleStyles}+${_simpleStyles}`

export type SimpleStyles =
	| 'full'
	| 'long'
	| 'medium'
	| 'short'
	| 'time'
	| 'time+short'
	| 'time+medium'
	| 'time+long'
	| 'time+full'
	| 'short+short'
	| 'short+medium'
	| 'short+long'
	| 'short+full'
	| 'medium+short'
	| 'medium+medium'
	| 'medium+long'
	| 'medium+full'
	| 'long+short'
	| 'long+medium'
	| 'long+long'
	| 'long+full'
	| 'full+short'
	| 'full+medium'
	| 'full+long'
	| 'full+full'

// Intl.DateTimeFormatOptions that doesn't exist in typescript for some reason
export type DateStyle = _simpleStyles
export type TimeStyle = _simpleStyles

//const _superSimpleStyles = permutations(_simpleStyles, _simpleStyles).map((v) => v[0] + '+' + v[1])

export type SimpleOptions = SimpleStyles //(DateStyle | TimeStyle) "+" + (DateStyle | TimeStyle)

// Intl.DateTimeFormatOptions that do exist, but I define the most common ones anyways.
export type YearStyle = 'numeric' | '2-digit' // Intl.DateTimeFormatOptions['year'] but cant be undefined, same for the rest of these
export type MonthStyle = 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'
export type WeekdayStyle = 'long' | 'short' | 'narrow'
export type DayStyle = 'numeric' | '2-digit'
export type HourMinuteSecondStyle = DayStyle

export type SimpleDateOptions = RequireAtLeastOne<
	{
		dateStyle?: DateStyle
		timeStyle?: TimeStyle
	},
	'dateStyle' | 'timeStyle'
>

export type AdvancedDateOptions = RequireAtLeastOne<
	{
		year?: YearStyle
		month?: MonthStyle
		week?: WeekdayStyle
		day?: DayStyle
		weekday?: WeekdayStyle
		hour?: HourMinuteSecondStyle
		minute?: HourMinuteSecondStyle
		second?: HourMinuteSecondStyle
	},
	'year' | 'month' | 'week' | 'day' | 'weekday' | 'hour' | 'minute' | 'second'
>

export type DateOptions = (SimpleDateOptions | AdvancedDateOptions) &
	Intl.DateTimeFormatOptions

@Injectable({
	providedIn: 'root',
})
export class DateTranslatorService {
	constructor(
		private language: LanguageService,
		private commonConf: CommonConfigService,
		private translator: TranslateService,
	) {}

	private conf: CommonConfig['dates'] | undefined = undefined

	/**
	 * @returns the locale information we need for creating nice date and time strings
	 */
	private getLocale() {
		const language = this.language.getCurrentLanguage()
		return language
	}

	// I'm just making this synchronous with an interval because it's easier that way.
	// It's not great but it's probably better than the alternative imo
	private dateConf() {
		if (this.conf) {
			return this.conf
		}

		this.commonConf.getDatesConf().subscribe((conf) => {
			this.conf = conf
		})

		// TODO this is a bit temporary but it works.
		// Not great for performance but it should not be super horrible either,
		// and it will make it so that we most likely get the config.
		// I've experimented a lot with Atomics.wait() but had some issues with that too,
		// it seems like I will have to convert all functions to async functions or change the way date config options
		// work.
		// If this.conf is undefined then the only bad thing that happens is that the only config option for dates currently
		// (hideGermanDots) doesn't work. This is not a critical error.
		// On my laptop the below loop executes for at most 186.151123046875 ms
		// On a somewhat modern phone it would be no more than 1 second
		// The config would be returned from cache earlier than this time unless it's the first call.

		for (let i = 0; !this.conf && i < 10e5; i++) {
			/* empty */
		}

		return this.conf
	}

	/**
	 * @param date
	 * @param style - Possible values are: 'full' | 'long' | 'medium' | 'short'
	 * @returns something like 2023-03-24 or 03/24/23 depending on locale
	 */
	public getDateString(date: Date, style: DateStyle = 'short'): string {
		return new Intl.DateTimeFormat(this.getLocale(), {
			dateStyle: style,
		} as DateOptions).format(date)
	}

	/**
	 * @param date
	 * @param options defaults to 'short'
	 *
	 * Possible values are:
	 *
	 * 'full' | 'long' | 'medium' | 'short' | 'time' |
	 * 'time+short' | 'time+medium' | 'time+long' | 'time+full' |
	 * 'short+short' | 'short+medium' | 'short+long' | 'short+full' |
	 * 'medium+short' | 'medium+medium' | 'medium+long' | 'medium+full' |
	 * 'long+short' | 'long+medium' | 'long+long' | 'long+full' |
	 * 'full+short' | 'full+medium' | 'full+long' | 'full+full' |
	 *
	 * The style before the + is the style for the date.
	 *
	 * The style after the + is the style for the time.
	 *
	 * If string options are passed and 'time' is included, then only the time will be printed.
	 *
	 * Or DateOptions (for example: {week: "long"} to only display the week in a long format)
	 *
	 * @param timeOnlyIfNotZero If timeOnlyIfNotZero is true and the time
	 *  is 00:00:00 then it returns only the date part, e.g. 03/24/23.
	 *
	 * @returns something like 2023-03-24 23:00 or 03/24/23 14:00
	 *  depending on locale and options.
	 *
	 * Month names are always written like it should be in a sentence.
	 */
	public getDateTimeString(
		date: Date,
		options: DateOptions | SimpleOptions = 'short',
		timeOnlyIfNotZero = false,
	) {
		let opts = {} as DateOptions

		if (typeof options === 'string') {
			const split = options.split('+')
			opts = (
				split[0] === 'time'
					? // If time is specified then only return time and default to short if nothing after +
					  { timeStyle: split[1] ?? 'short' }
					: {
							// Else interpret things before + as month only and after + as time additionally.
							dateStyle: split[0],
							...(split.length === 2 && { timeStyle: split[1] }),
					  }
			) as DateOptions
		} else {
			opts = { ...options } // copy options just to be safe
		}

		if (
			timeOnlyIfNotZero &&
			date.getHours() == 0 &&
			date.getMinutes() == 0 &&
			date.getSeconds() == 0
		) {
			if ('timeStyle' in opts) {
				delete opts['timeStyle'] // delete time if it's 00:00:00
			}
		}

		let formattedStr = new Intl.DateTimeFormat(this.getLocale(), opts).format(
			date,
		)
		if (this.dateConf()?.hideGermanDots === true) {
			// eslint-disable-next-line no-useless-escape
			if (formattedStr.search(/[^\d\. ]/) >= 0) {
				formattedStr = formattedStr.replace(/\./g, '')
			}
		}
		return formattedStr
	}

	/**
	 * @param date
	 * @param monthStyle defaults to 'short'.
	 *
	 * Possible values are:
	 *
	 * 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'
	 * @returns The name of the month for the date, as it should be written in a sentence.
	 */
	public getMonthStringInSentence(date: Date, monthStyle: MonthStyle = 'long') {
		return this.getDateTimeString(date, { month: monthStyle })
	}

	/**
	 * @param number 0 january to 11 december
	 * @param monthStyle defaults to 'short'.
	 *
	 * Possible values are:
	 *
	 * 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'
	 * @returns The name of the month for the number, as it should be written in a sentence.
	 */
	public getMonthStringInSentenceFromZeroIndex(
		number: number,
		monthStyle: MonthStyle = 'long',
	) {
		return this.getDateTimeString(new Date(2023, number, 15), {
			month: monthStyle,
		})
	}

	/**
	 * @param date
	 * @param weekStyle defaults to 'short'.
	 *
	 * Possible values are:
	 *
	 * 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'
	 * @returns The name of the week for the date, as it should be written in a sentence.
	 */
	public getWeekStringInSentence(date: Date, weekStyle: WeekdayStyle = 'long') {
		return this.getDateTimeString(date, {
			week: weekStyle,
		} as AdvancedDateOptions)
	}

	/**
	 * @returns true if months should always be written with capital letters in the current locale.
	 */
	public monthIsCapitalized() {
		const monthName = this.getDateTimeString(new Date(), { month: 'long' })
		return monthName === monthName.toUpperCase()
	}

	public getMonths(
		monthStyle: MonthStyle = 'long',
		in_sentence = false,
	): string[] {
		return Array(12)
			.fill(0)
			.map((_, n) => {
				const date = new Date()
				date.setMonth(n)
				let monthName = this.getMonthStringInSentence(date, monthStyle)
				if (!in_sentence) {
					monthName = this.capitalize(monthName)
				}
				return monthName
			})
	}

	/**
	 * @todo This function will not support Javenese as they do not have a 7 day week, but we should be good for now :)
	 */
	public getWeekdays(
		weekStyle: WeekdayStyle = 'long',
		in_sentence = false,
	): string[] {
		return Array(7)
			.fill(0)
			.map((_, n) => {
				const date = new Date()
				date.setDate(n)
				let dayName = this.getWeekStringInSentence(date, weekStyle)
				if (!in_sentence) {
					dayName = this.capitalize(dayName)
				}
				return dayName
			})
	}

	public getWeekday(day: number, short = false): string {
		const weekdays = this._old_getWeekdays(short)
		return weekdays[day]
	}

	public getWeekdaysForDates(dates: Date[], short = false): string[] {
		return dates.map((date) => this._old_getWeekday(date.getDay(), short))
	}

	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	public _old_getMonths(short = false, in_sentence = false): string[] {
		let months: string[]

		if (in_sentence) {
			months = [
				this.translator.instant('common.month_january_in_sentence'),
				this.translator.instant('common.month_february_in_sentence'),
				this.translator.instant('common.month_march_in_sentence'),
				this.translator.instant('common.month_april_in_sentence'),
				this.translator.instant('common.month_may_in_sentence'),
				this.translator.instant('common.month_june_in_sentence'),
				this.translator.instant('common.month_july_in_sentence'),
				this.translator.instant('common.month_august_in_sentence'),
				this.translator.instant('common.month_september_in_sentence'),
				this.translator.instant('common.month_october_in_sentence'),
				this.translator.instant('common.month_november_in_sentence'),
				this.translator.instant('common.month_december_in_sentence'),
			]
		} else if (short) {
			months = [
				this.translator.instant('common.month_january_short'),
				this.translator.instant('common.month_february_short'),
				this.translator.instant('common.month_march_short'),
				this.translator.instant('common.month_april_short'),
				this.translator.instant('common.month_may_short'),
				this.translator.instant('common.month_june_short'),
				this.translator.instant('common.month_july_short'),
				this.translator.instant('common.month_august_short'),
				this.translator.instant('common.month_september_short'),
				this.translator.instant('common.month_october_short'),
				this.translator.instant('common.month_november_short'),
				this.translator.instant('common.month_december_short'),
			]
		} else {
			months = [
				this.translator.instant('common.month_january'),
				this.translator.instant('common.month_february'),
				this.translator.instant('common.month_march'),
				this.translator.instant('common.month_april'),
				this.translator.instant('common.month_may'),
				this.translator.instant('common.month_june'),
				this.translator.instant('common.month_july'),
				this.translator.instant('common.month_august'),
				this.translator.instant('common.month_september'),
				this.translator.instant('common.month_october'),
				this.translator.instant('common.month_november'),
				this.translator.instant('common.month_december'),
			]
		}

		return months
	}

	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	public _old_getWeekdays(short = false): string[] {
		let weekdays: string[]
		if (short) {
			weekdays = [
				this.translator.instant('common.weekday_sunday_short'),
				this.translator.instant('common.weekday_monday_short'),
				this.translator.instant('common.weekday_tuesday_short'),
				this.translator.instant('common.weekday_wednesday_short'),
				this.translator.instant('common.weekday_thursday_short'),
				this.translator.instant('common.weekday_friday_short'),
				this.translator.instant('common.weekday_saturday_short'),
			]
		} else {
			weekdays = [
				this.translator.instant('common.weekday_sunday'),
				this.translator.instant('common.weekday_monday'),
				this.translator.instant('common.weekday_tuesday'),
				this.translator.instant('common.weekday_wednesday'),
				this.translator.instant('common.weekday_thursday'),
				this.translator.instant('common.weekday_friday'),
				this.translator.instant('common.weekday_saturday'),
			]
		}

		return weekdays
	}
	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	public _old_getWeekdaysForDates(dates: Date[], short = false): string[] {
		return dates.map((date) => this._old_getWeekday(date.getDay(), short))
	}
	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	public _old_getWeekday(day: number, short = false): string {
		const weekdays = this._old_getWeekdays(short)
		return weekdays[day]
	}
	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	public getFormattedDayDate(date: Date) {
		return (
			this._old_getWeekdays()[date.getDay()] +
			', ' +
			this.getFormattedDate(date)
		)
	}

	/**
	 * @deprecated until _old_getMonths and _old_getWeekdays are fixed
	 */
	public getDayStringParts(
		date: Date,
		shortDay?: boolean,
		shortMonth?: boolean,
	) {
		return {
			year: date.getFullYear(),
			weekday: this._old_getWeekdays(shortDay)[date.getDay()],
			day: date.getDate(),
			month: this._old_getMonths(shortMonth)[date.getMonth()],
		}
	}

	/**
	 * @deprecated until _old_getMonths is fixed
	 */
	public getYearMonthDateStringParts(date: Date, shortMonth?: boolean) {
		return {
			year: date.getFullYear(),
			month: this._old_getMonths(shortMonth)[date.getMonth()],
		}
	}
	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	public getFormattedDate(date: Date) {
		return (
			date.getDate() +
			' ' +
			this.getTranslatedMonth(date.getMonth()) +
			' ' +
			date.getFullYear()
		)
	}

	public getWeekStringParts(
		date: Date,
		shortMonths?: boolean,
	): WeekStringParts {
		const weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 1 // 0 = sunday
		const weekOption = { weekStartsOn: weekStartsOn }

		const startOfWeekDate = startOfWeek(date, weekOption)
		const endOfWeekDate = endOfWeek(date, weekOption)

		const startMonth = this.getMonthStringInSentence(startOfWeekDate)
		const endMonth = this.getMonthStringInSentence(endOfWeekDate)
		const startDay = startOfWeekDate.getDate()
		const endDay = endOfWeekDate.getDate()
		const startYear = startOfWeekDate.getFullYear()
		const endYear = endOfWeekDate.getFullYear()

		return {
			startOfWeekDate,
			endOfWeekDate,
			startDay: startDay,
			endDay: endDay,
			startMonth: startMonth,
			endMonth: endMonth,
			startYear: startYear,
			endYear: endYear,
		}
	}
	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	getTranslatedDayString(dayString: DayString) {
		dayString.weekday = this.capitalize(dayString.weekday)

		return (
			dayString.weekday +
			', ' +
			dayString.day +
			' ' +
			dayString.month +
			' ' +
			dayString.year
		)
	}
	/**
	 * @deprecated Should probably use Intl functions based on locale instead (like Intl.DateTimeFormat)
	 */
	getTranslatedMonthYearString(dayString: MonthYearString) {
		dayString.month = this.capitalize(dayString.month)

		return dayString.month + ' ' + dayString.year
	}

	getRangeInYearString(
		start: Date,
		end: Date,
		includeYear = true,
		options: AdvancedDateOptions = {
			day: '2-digit',
			month: 'short',
			year: 'numeric',
		},
	): string {
		if (typeof Intl.DateTimeFormat !== 'undefined') {
			const formatter = new Intl.DateTimeFormat(this.getLocale(), {
				...(includeYear && options.year && { year: options.year }),
				...{ month: options.month, day: options.day },
			})
			// This function definitively exists in all modern browsers but typescript has a bug here,
			// probably because our version is very old.
			// https://github.com/Microsoft/TypeScript/issues/1911
			if (typeof (formatter as any).formatRange === 'function') {
				let formattedStr: string = (formatter as any).formatRange(start, end)

				if (this.dateConf()?.hideGermanDots === true) {
					// eslint-disable-next-line no-useless-escape
					formattedStr = formattedStr.replace(/\./g, '')
				}

				return formattedStr
			}
		}

		// I made a fallback without the above function just to be sure.
		const startMonth = this.getMonthStringInSentence(start)
		const endMonth = this.getMonthStringInSentence(end)
		return `${this.getDateTimeString(start, {
			...(options.day! && { day: options.day }),
			...(options.month && startMonth !== endMonth && { month: 'short' }),
		})} - ${this.getDateTimeString(end, {
			...(options.day! && { day: options.day }),
			...(options.month && { month: options.month }),
			...(includeYear && { year: 'numeric' }),
		})}`
	}

	/**
	 * @deprecated You should probably use getRangeInYearString(start, end) instead.
	 * @param weekString
	 * @returns
	 */
	getTranslatedWeekString(weekString: WeekStringParts): string {
		return this.getRangeInYearString(
			weekString.startOfWeekDate,
			weekString.endOfWeekDate,
		)
	}

	/**
	 * @deprecated until dependency to _old_getWeekdays is removed
	 * @param date the date we take the day from and translate
	 * @param variant get either the long or short version of the weekday
	 * @param sundayFirst not required, defaults to undefined (as in false), meaning that we start days on monday.
	 * @returns the translated weekday
	 */
	getTranslatedWeekday(date: Date, variant: 'long' | 'short'): string {
		return this._old_getWeekdays(variant === 'short')[date.getDay()]
	}

	/**
	 * @deprecated This is not used anywhere except for in monitors, which is also deprecated, and it shouldn't be used
	 * @param period "day", "hour", "week"
	 *
	 * returns "daily", "weekly"
	 */
	public periodToPeriodly(period: string) {
		if (period === 'day') return 'daily'
		else return period + 'ly'
	}

	/**
	 * @deprecated use this.getMonthStringInSentence() and this.getMonthString()
	 */
	getTranslatedMonth(
		month: number,
		short = false,
		in_sentence = false,
	): string {
		return this._old_getMonths(short, in_sentence)[month]
	}

	/**
	 * @deprecated until getTranslatedMonth is fixed
	 */
	getTranslatedMonthString(date: Date) {
		return this.getTranslatedMonth(date.getMonth()) + ' ' + date.getFullYear()
	}

	/**
	 * @deprecated until dependency to _old_getMonths is removed
	 */
	getAllTranslatedForNMonths(period: Period, short?: boolean) {
		const categories: string[] = []
		const months = this._old_getMonths(short)

		period.getDates().forEach((date) => {
			const currentMonth = date.getMonth()
			categories.push(months[currentMonth])
		})

		return categories
	}

	/**
	 * Returns string of period categories,
	 * @example
	 * Year: 2022
	 * Month: März 2022
	 * Day: 27 März 2022
	 */
	getTranslatedPeriodString(period: Period) {
		const date = period.getFirstDate()

		switch (period.getPeriodType()) {
			case PeriodType.Year:
				return this.getDateTimeString(date, {
					year: 'numeric',
				})
			case PeriodType.Month:
				return this.getDateTimeString(date, {
					month: 'long',
					year: 'numeric',
				})
			case PeriodType.Week:
				return this.getTranslatedWeekString(
					this.getWeekStringParts(period.getFirstDate(), true),
				)
			default:
				return this.getDateTimeString(date, {
					day: '2-digit',
					month: 'long',
					year: 'numeric',
				})
		}
	}

	private capitalize(s: string): string {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}
}
