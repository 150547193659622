import { map } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreDataStoreService } from '@eliq/core';
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe';
import { Location as ModelsLocation } from '@eliq/models';
import { ModalService } from '@eliq/ui/modal';
import { TranslateService } from '@ngx-translate/core';

import {
    TipWalkthroughModalContainerComponent
} from '../../components/tip-walkthrough-modal-container/tip-walkthrough-modal-container.component';
import { AdviceStatus, APIAdvice } from '../../models/api-advice.model';
import { AdviceMap, EnergyAdvisorApiService } from '../../services/energy-advisor-api.service';
import { TipsTrackingService } from '../../tracking/tips-tracking.service';
import { EnergyAdvisorCardComponent } from '../energy-advisor-card/energy-advisor-card.component';

@Component({
	selector: 'eliq-energy-advisor-card-container',
	templateUrl: './energy-advisor-card-container.component.html',
	styleUrls: ['./energy-advisor-card-container.component.css'],
	standalone: true,
	imports: [EnergyAdvisorCardComponent, ImgSrcPipe],
})

export class EnergyAdvisorCardContainerComponent implements OnInit {
	@Input() comingSoon = false
	@Output() loading = new EventEmitter<boolean>()

	public loaded = false

	public header = ''
	public text = ''
	public buttonLabel = ''
	public imgSrc = '/assets/images/home_tips.svg'
	public buttonRoute = ''
	private adviceMap: AdviceMap
	private allAdvices: APIAdvice[]
	private newAdvices: APIAdvice[]

	public loc: ModelsLocation

	constructor(
		private translator: TranslateService,
		private api: EnergyAdvisorApiService,
		private cds: CoreDataStoreService,
		private router: Router,
		private modal: ModalService,
		private tracking: TipsTrackingService,
	) {}

	getTranslationWithFallback(key: string, fallback: string) {
		return this.translator.get(key).pipe(
			map((res: string) => {
				if (res === key) {
					return fallback
				}
				return res
			}),
		)
	}

	getAdviceTitle(advice: APIAdvice) {
		return this.getTranslationWithFallback(
			`energy_advisor.${advice.key}_title`,
			advice.title,
		)
	}
	getAdviceContent(advice: APIAdvice) {
		return this.getTranslationWithFallback(
			`energy_advisor.${advice.key}_text`,
			advice.content,
		)
	}

	ngOnInit(): void {
		if (this.comingSoon) {
			this.setupComingSoon()
			return
		} else {
			this.setLoaded(false)
			this.api.getAdviceMap('energy').subscribe({
				next: (advices) => {
					this.loc = advices.usedLocation as ModelsLocation
					this.setup(advices)
					this.setLoaded(true)
				},
				error: (err) => {
					this.header = this.translator.instant(
						'energy_advisor.fill_out_home_profile_for_tips',
					)
					this.text = ''
					this.newAdvices = []
					this.allAdvices = []
					this.buttonLabel = ''
					this.setLoaded(true)
				},
			})
		}
	}

	private setup(advices: AdviceMap) {
		this.adviceMap = advices
		if (advices.allAdvices.length > 0) {
			if (
				advices.allAdvices.some((advice) => advice.status === AdviceStatus.None)
			) {
				this.allAdvices = advices.allAdvices
				this.newAdvices = advices.newAdvices

				this.translator.get('energy_advisor.new_tips_for_you').subscribe(
					(res) => {
						this.header = res
					},
					(err) => {},
				)

				this.getAdviceTitle(this.newAdvices[0]).subscribe((res) => {
					this.text = res
				})

				this.translator.get('energy_advisor.view_new_tips').subscribe((res) => {
					this.buttonLabel = res
				})
			} else {
				this.newAdvices = []
				this.translator.get('energy_advisor.your_top_tip').subscribe((res) => {
					this.header = res
				})
				this.getAdviceTitle(advices.allAdvices[0]).subscribe((res) => {
					this.text = res
				})
				this.translator.get('energy_advisor.view_all_tips').subscribe((res) => {
					this.buttonLabel = res
				})
			}
		} else {
			this.newAdvices = []
			this.translator
				.get('energy_advisor.no_tips_available')
				.subscribe((res) => {
					this.header = res
				})
			this.translator
				.get('energy_advisor.no_tips_available_check_back_later')
				.subscribe((res) => {
					this.text = res
				})
			this.buttonLabel = ''
		}
	}

	private setupComingSoon() {
		this.translator.get('energy_advisor.coming_soon').subscribe((res) => {
			this.header = res
		})
		this.translator
			.get('energy_advisor.coming_soon_description')
			.subscribe((res) => {
				this.text = res
			})
	}

	public cardClicked() {
		if (this.newAdvices.length > 0) {
			// view new advices
			this.tracking.viewNewTips()

			const dialogRef = this.modal.openModal(
				TipWalkthroughModalContainerComponent,
				{
					disableClose: true,
				},
			)
			const instance = <TipWalkthroughModalContainerComponent>(
				dialogRef.componentInstance
			)
			instance.advices = this.newAdvices
			instance.locId = this.loc?.id as number

			dialogRef.afterClosed().subscribe((res) => {
				if (res === undefined) return
				if (res.length === 0) return

				const mapOfChanges: Map<string, APIAdvice> = new Map()
				res.forEach((changedAdvice: APIAdvice) =>
					mapOfChanges.set(changedAdvice.id, changedAdvice),
				)
				this.allAdvices = this.allAdvices.map((advice) => {
					if (mapOfChanges.has(advice.id)) {
						return mapOfChanges.get(advice.id) ?? new APIAdvice()
					} else {
						return advice
					}
				})

				this.setup(this.adviceMap)
			})
		} else {
			// view all advices
			this.tracking.viewAllTips()

			this.router.navigate(['energy-advisor'])
		}
	}

	private setLoaded(loaded: boolean) {
		// Checking if load status has changed to avoid emiting same status multiple times
		if (loaded !== this.loaded) {
			this.loaded = loaded
			this.loading.emit(!loaded)
		}
	}
}
