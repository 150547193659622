import { Directive, ElementRef, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { EliqTranslatePipeArgs, TranslateOrFallbackPipe } from "../public_api";



@Directive({
	standalone: true,
	selector: '[eliqTranslatePreWrap]',
})
export class TranslatePreWrapDirective implements OnInit, OnDestroy {
	private args: { key: string } & EliqTranslatePipeArgs

	set setArgs(args: { key: string } & EliqTranslatePipeArgs) {
		this.args = args
		this.updateTextContent()
	}

	@Input() set eliqTranslatePreWrap(key: string) {
		this.setArgs = { ...this.args, key }
	}

	@Input() set eliqTranslatePreWrapValues(values: { [key: string]: any }) {
		this.setArgs = { ...this.args, values }
	}

	@Input() set eliqTranslatePreWrapFallbackKey(fallbackKey: string) {
		this.setArgs = { ...this.args, fallbackKey }
	}

	@Input() set eliqTranslatePreWrapFallbackString(fallbackString: string) {
		this.setArgs = { ...this.args, fallbackString }
	}

	// @Input('eliqTranslatePreWrap') args: {key: string} & EliqTranslatePipeArgs
	// @Input('eliqTranslatePreWrapValues') values: { [key: string]: any }
	// @Input('eliqTranslatePreWrapKey') key: string
	// @Input('eliqTranslatePreWrapFallbackKey') fallbackKey: string
	// @Input('eliqTranslatePreWrapFallbackString') fallbackString: string

	constructor(private elem: ElementRef) {}

	private translatePipe = inject(TranslateOrFallbackPipe)
	private translate = inject(TranslateService)

	private subscription: Subscription;

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	updateTextContent() {
		this.elem.nativeElement.textContent = this.translatePipe.transform(this.args.key, this.args);
		this.elem.nativeElement.textContent = this.elem.nativeElement.textContent.replaceAll('\\n', '\n')
		this.elem.nativeElement.textContent = this.elem.nativeElement.textContent.replaceAll('<br />', '\n')
		this.elem.nativeElement.textContent = this.elem.nativeElement.textContent.replaceAll(/​+/g, '\n')
	}

	ngOnInit() {
		this.elem.nativeElement.style.whiteSpace = 'pre-wrap'
		this.subscription = this.translate.onLangChange.subscribe(() => {
			this.updateTextContent()
		});
	}
}
