<!--<div class="container">-->
<div
	id="form"
	*ngIf="chatProvider === 'maxcontact'"
	data-auto-open="true"
	data-size="full"
	class="default-form"
></div>
<div *ngIf="chatProvider === 'drift'">
	<h1>drift</h1>
	<br />
	{{params["email"]}}
</div>

<!--<div class="ticket-info-container">
  <div class="ticket-info-box">
    <h1>Params</h1>
    <p *ngFor="let param of params | keyvalue">{{param.key}}: {{param.value}}</p>
  </div>
</div>-->
