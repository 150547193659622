import { Inject, Injectable } from '@angular/core'
import { TransferState, StateKey, makeStateKey } from '@angular/core'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { PLATFORM_ID } from '@angular/core'
import {
	Observable,
	BehaviorSubject,
	timer,
	of,
	Subject,
	AsyncSubject,
} from 'rxjs'
import {
	map,
	catchError,
	switchMap,
	takeUntil,
	takeWhile,
	filter,
	tap,
} from 'rxjs/operators'
import * as CryptoJS from 'crypto-js'
import { get, set, del, clear } from 'idb-keyval'
import { HttpHeaders, HttpRequest } from '@angular/common/http'
import { EliqApiHttpClient } from '../http/eliq-api-http-client.service'
import { CacheService } from '../cache/cache.service'
import { CoreDataStoreService } from '@eliq/core'
import { JsonGetterService } from '../config'
import { EnvironmentService } from '../environment/environment.service'

/**
__ok__ -
The connection related to the location is all ok. Connection entities (at least one) have been configured and data is available. No actions needed by the user.

__not_connected__ -
There are no connection related to the location. The user should be prompted with a message asking to setup a connection. Send the user of to Eliq Connect.

__action_required__ -
A connection exist, but there is an action required by the user to make it work. The user should be prompted with a message saying he/she needs to fill in some more information, and send them of to Eliq Connect.
This could be in cases:
A connection exist but no connection entities has been configured for the location
The user need to refresh the access Send the user of to Eliq Connect. Configuration is required

__awaiting_confirmation__ -
Connection created, but not yet validated by the provider. E.g. awaiting approval from DSO. At this stage, there are no actions that can be done to change the status. Prompt message that a process has been started.

__awaiting_first_data_sync__ -
The connection has been configured and is ready to go. Energy data has though not been synced for the at least one of the connection entities.
 */
export enum LocationConnectionStatus {
	OK = 'ok',
	NOT_CONNECTED = 'not_connected',
	ACTION_REQUIRED = 'action_required',
	AWAITING_CONFIRMATION = 'awaiting_confirmation',
	AWAITING_FIRST_DATA_SYNC = 'awaiting_first_data_sync',
}

export enum UserConnectionStatus {
	CONNECTED = 'connected',
	AWAITING_CONFIRMATION = 'awaiting_confirmation',
	DISCONNECTED = 'disconnected',
}

/**
 * ```
 *	{
 *		"status":"ok"
 *	}
 * ```
 */
export interface LocationConnectionMetaData {
	status: LocationConnectionStatus
}

/**
 *  ```
 * [
 * 		{
 * 			"id": "20b4adc8-2ae9-4f99-b50d-af0933042f58",
 * 			"display_name": "Connection display name",
 * 			"provider": "provider_name",
 * 			"status": "connected"
 *   	}
 * ]
 * ```
 */
export interface UserConnection {
	id: string
	display_name: string
	provider: string
	status: UserConnectionStatus
}

/**
 * ```
 * [
 *   {
 *     "id":"949471d8-e597-4a5b-bd4d-2ecc3e72aaf6",
 *     "display_name":"Connection display name",
 *     "type":"meter",
 *     "location_id":1234,
 *     "meter":{
 *       "fuel":"elec",
 *       "type":"import",
 *       "data_from":"2021-01-01",
 *       "data_to":"2022-01-01"
 *     }
 *   }
 * ]
 * ```
 */
export interface ConnectionEntity {
	id: string
	display_name: string
	type: string
	location_id: number
	meter: {
		fuel: string
		type: string
		data_from: string
		data_to: string
	}
}

@Injectable({
	providedIn: 'root',
})
export class EliqConnectService {
	constructor(
		private env: EnvironmentService,
		private config: JsonGetterService,
		private coreDS: CoreDataStoreService,
		private http: EliqApiHttpClient,
		@Inject(PLATFORM_ID) private platformId: object,
	) {}

	public enableEliqConnect$ = this.env.isIntegrationless()
		? this.config
				.getInsightsConfig()
				.pipe(map((conf) => conf.enableEliqConnect || false))
		: of(false)

	/**
	 * Emitted when getLocationConnectionStatus receives a value that is different from the last recorded value.
	 *
	 * getLocationConnectionStatus() is called on every tick in pollLocationConnectionStatus()
	 */
	public locationStatus$ = new BehaviorSubject<LocationConnectionStatus | null>(
		null,
	)

	/**
	 *  Get location connection status
	 *
	 * {@link https://eliqdev.atlassian.net/wiki/spaces/PD/pages/1134854147/Open+Data+Eliq+Connect#Get-location-connection-meta-data Read more on confluence}
	 *
	 * @description
	 * GET locations/:id/connection-meta-data
	 *
	 * ```
	 *	{
	 *		"status":"ok"
	 *	}
	 *```
	 *
	 * @param {number} userId
	 */
	getLocationConnectionStatus(
		locationId: number,
	): Observable<LocationConnectionStatus | null> {
		return this.http
			.get<LocationConnectionMetaData>(
				`/v3/locations/${locationId.toString()}/connection-meta-data`,
			)
			.pipe(
				map((res) => {
					if (this.locationStatus$.value !== res?.status) {
						this.locationStatus$.next(res?.status ?? null)
					}
					return res?.status ?? null
				}),
				catchError(() => of(null)),
			)
	}

	private alreadyPollingConnectionStatus = false
	/**
	 * Calls getLocationConnectionStatus in a interval and sets locationStatus$ and locationStatusFound$ accordingly.
	 * If locationStatus$ is already set, it will return it's last value and stop polling
	 *
	 * @param until$
	 * Defaults to locationStatus$ === LocationConnectionStatus.OK
	 * ```
	 *	this.locationStatus$.pipe(
	 *		filter((status) => status === LocationConnectionStatus.OK),
	 *		map((_) => true),
	 *	)
	 * ```
	 */
	pollLocationConnectionStatusUntilObs(
		locationId: number,
		startDelay = 0,
		interval = 3000,
		until$: Observable<boolean> = this.locationStatus$.pipe(
			filter((status) => status === LocationConnectionStatus.OK),
			map((_) => true),
		),
	): Observable<LocationConnectionStatus | null> {
		if (this.alreadyPollingConnectionStatus) {
			return this.locationStatus$.asObservable()
		}
		this.alreadyPollingConnectionStatus = true
		return this.locationStatus$.asObservable().pipe(
			switchMap((status) =>
				status && status === LocationConnectionStatus.OK
					? of(status)
					: timer(startDelay, interval).pipe(
							switchMap((_) => this.getLocationConnectionStatus(locationId)),
							takeUntil(
								until$.pipe(
									tap((_) => {
										this.alreadyPollingConnectionStatus = false
									}),
								),
							),

							catchError(() => of(null)),
					  ),
			),
		)
	}

	/**
	 *
	 * @param locationId
	 *
	 * @param untilFn @default status => status === LocationConnectionStatus.OK
	 *
	 * @param startDelay @default 0
	 *
	 * @param interval @default 3000
	 *
	 * @returns `Observable<LocationConnectionStatus | null>`

	 */
	pollLocationConnectionStatus(
		locationId: number,
		startDelay = 0,
		interval = 3000,
		untilFn: (status: LocationConnectionStatus | null) => boolean = (status) =>
			status === LocationConnectionStatus.OK,
	): Observable<LocationConnectionStatus | null> {
		return this.pollLocationConnectionStatusUntilObs(
			locationId,
			startDelay,
			interval,
			this.locationStatus$.pipe(
				filter(untilFn),
				map((_) => true),
			),
		)
	}

	/**
	 *
	 *  Get user connections
	 *
	 * {@link https://eliqdev.atlassian.net/wiki/spaces/PD/pages/1134854147/Open+Data+Eliq+Connect#Get-user-connections Read more on confluence}
	 *
	 * @description
	 * GET users/:id/connection
	 *
	 * Endpoint can be used to list all connections for a user. Each connection have a status (connected, awaiting_confirmation, disconnected)
	 * ```
	 *	[
	 *		{
	 *			"id": "20b4adc8-2ae9-4f99-b50d-af0933042f58",
	 *			"display_name": "Connection display name",
	 *			"provider": "provider_name",
	 *			"status": "connected"
	 *		}
	 *	]
	 * ```
	 *
	 * @param {number} userId
	 *
	 **/
	getUserConnections(userId: number): Observable<UserConnection[]> {
		return this.http
			.get<UserConnection[]>(`/v3/users/${userId.toString()}/connection`)
			.pipe(
				map((res) => {
					if (res?.length > 0) {
						return res
					} else {
						return []
					}
				}),
				catchError(() => of([])),
			)
	}

	/**
	 * Get connection entities
	 *
	 * {@link https://eliqdev.atlassian.net/wiki/spaces/PD/pages/1134854147/Open+Data+Eliq+Connect#Get-connection-entities Read more on confluence}
	 *
	 * @description
	 * GET /connections/:connection_id/connection-entities
	 *
	 * ```
	 * [
	 *   {
	 *     "id":"949471d8-e597-4a5b-bd4d-2ecc3e72aaf6",
	 *     "display_name":"Connection display name",
	 *     "type":"meter",
	 *     "location_id":1234,
	 *     "meter":{
	 *       "fuel":"elec",
	 *       "type":"import",
	 *       "data_from":"2021-01-01",
	 *       "data_to":"2022-01-01"
	 *     }
	 *   }
	 * ]
	 * ```
	 * Get all (configured) entities associated with the connection.
	 * This can be used to get insights into what is added for the specified connection.
	 *
	 * @param {number} connectionId
	 */
	getConnectionEntities(connectionId: number): Observable<ConnectionEntity[]> {
		return this.http
			.get<ConnectionEntity[]>(
				`/v3/connections/${connectionId.toString()}/connection-entities`,
			)
			.pipe(map((res) => res ?? []))
	}

	/**
	 * Get Eliq Connect Web URI
	 *
	 *{@link https://eliqdev.atlassian.net/wiki/spaces/PD/pages/1134854147/Open+Data+Eliq+Connect#Get-Eliq-Connect-Web-URI Read more on confluence}

	 * @description
	 * GET users/:user_id/connections/web-portal-uri?callback_uri={callbackUri}
	 * @important
	 * OBS! Make sure to HTML encode the callback uri.
	 *
	 * ```
	 *	{
	 *		"uri":"https://connect.eliq.io/login?ticket={SSO TICKET}"
	 *	}
	 ```
	 */
	getWebUri(
		userId: number | null = null,
		callbackUri = '',
	): Observable<string | null> {
		// default callbackUri
		callbackUri = callbackUri || window.location.href

		if (userId) {
			return this.http
				.get<{ uri: string }>(
					`/v3/users/${userId.toString()}/connections/web-portal-uri?callback_uri=${encodeURIComponent(
						callbackUri,
					)}`,
				)
				.pipe(map((res) => res?.uri ?? null))
		}

		// default userId
		return this.coreDS.user.pipe(
			switchMap((user) => {
				if (!user.id) {
					throw new Error('User not logged in')
				}
				return this.getWebUri(user.id, callbackUri)
			}),
		)
	}

	private isValidUrl(url: string): boolean {
		try {
			new URL(url)
			return true
		} catch (_) {
			return false
		}
	}

	openWebUri(
		userId: null | number = null,
		callbackUri = '',
	): Observable<boolean> {
		return this.getWebUri(userId, callbackUri).pipe(
			switchMap((res) => {
				if (typeof res === 'string' && this.isValidUrl(res)) {
					window.location.href = res
					return of(true)
				} else {
					return of(false)
				}
			}),
		)
	}
}
