import { Injectable } from '@angular/core'
import { APIConsumption } from '@eliq/core/models/src/api-models/api-consumption.model'
import { Fuel, FuelType, Location, ResolutionType } from '@eliq/core'
import {
	AsyncSubject,
	BehaviorSubject,
	Observable,
	Subject,
	forkJoin,
	iif,
	map,
	of,
	retry,
	switchMap,
	take,
	tap,
	throwError,
} from 'rxjs'
import { LocationHttpService } from '../http-services/location-http.service'
import { CoreDataStoreService } from '../data-stores/core-data-store.service'

export interface PVInfo {
	hasPV: boolean
	waitingForPVDisagg: boolean
	hasPVDisagg: boolean
	hasPVCost: boolean
}
@Injectable({
	providedIn: 'root',
})
export class PvHelperService {
	constructor(
		private coreDS: CoreDataStoreService,
		private locHttp: LocationHttpService,
	) {}

	public pvInfo = {
		hasPV: false,
		waitingForPVDisagg: false,
		hasPVDisagg: false,
		hasPVCost: false,
	}
	public pvInfoLoaded = false
	public pvInfoFetched = false
	public pvInfo$ = new BehaviorSubject<typeof this.pvInfo>({hasPV: false, waitingForPVDisagg: false, hasPVDisagg: false, hasPVCost: false})

	public getPvInfo(location: Location): Observable<typeof this.pvInfo> {
		return of(this.getPvInfo__Implementation(location))

	}

	public getPvInfo__Implementation(
		location: Location,
	): typeof this.pvInfo {
		if (!location || !location.fuels) {
			throw new Error('No location or fuels provided')
		}
		if (this.pvInfoFetched) {
			return this.pvInfo
		}
		this.pvInfoLoaded = false
		this.pvInfoFetched = true

		this.pvInfo.hasPV = this._locationHasPV(location)
		this.pvInfo.hasPVDisagg = this.fuelsHasElecPVDisagg(location.fuels)

		// TODO (was nicer before, now you need to refresh/reload)
		this.pvInfo.waitingForPVDisagg = this.fuelsWaitingForElecPVDisagg(
			location.fuels,
		)
		this.pvInfoLoaded = true
		this.pvInfo$.next(this.pvInfo)
		this.pvInfo$.complete()
		return this.pvInfo
	}

	private _locationHasPV(location: Location) {
		if (!location) {
			return false
		}
		if (location?.pvSystems?.length) {
			return true
		}
		if (location.fuels) {
			return this.fuelsHasPV(location.fuels)
		}

		return false
	}

	locationHasPV(): Observable<boolean>
	locationHasPV(locationId: number): Observable<boolean>
	locationHasPV(location: Location): boolean
	locationHasPV(location?: number | Location): Observable<boolean> | boolean {
		if (location && location instanceof Location) {
			return this._locationHasPV(location)
		}
		return this.coreDS.getActiveLocation()
			.pipe(
				map((location) => {
					return location ? this._locationHasPV(location) : false
				}),
			)
	}

	currentLocationHasPV() {
		return this.locationHasPV()
	}

	fuelIsPV(fuel: Fuel) {
		return fuel?.type === FuelType.ELEC && (fuel?.production || fuel?.export)
	}

	fuelsHasPV(fuels: Fuel[]) {
		return Object.values(fuels).some((fuel) => this.fuelIsPV(fuel))
	}

	fuelHasPVDisagg(fuel: Fuel) {
		return this.fuelIsPV(fuel)
		// TODO:
		// I think it would be better to check for the
		// "pv_disagg" source metadata but it doesn't seem to work for Energia as of now (2024-01-09)
		return (
			fuel &&
			fuel.type === FuelType.ELEC &&
			Object.values(fuel).some((ds) => {
				return (
					(Object.keys(ds ?? {})?.includes('source') ?? false) &&
					ds['source'] === 'pv_disagg'
				)
			})
		)
	}

	fuelsHasElecPVDisagg(fuels: Fuel[]) {
		const res = Object.values(fuels).some((fuel) => this.fuelHasPVDisagg(fuel))
		return res
	}

	apiConsumptionHasExport(data: APIConsumption): boolean {
		return data?.consumption?.some((cons) => cons > 0) || false
	}

	fetchApiConsumptionHasExport(
		locationId: number,
		fuel = 'elec',
		unit = 'energy',
		resolution = ResolutionType.Month,
		fromDt = new Date(new Date().getFullYear() - 2, 0, 1),
		toDt = new Date(),
	): Observable<boolean> {
		return this.locHttp
			.getLocationConsumption(locationId, fuel, unit, resolution, fromDt, toDt)
			.pipe(
				take(1),
				map((cons: APIConsumption) => {
					return this.apiConsumptionHasExport(cons)
				}),
			)
	}

	/// Checks if the elec fuel provided has populated export data but no consumption metadata.
	fuelWaitingForPVDisagg(fuel: Fuel) {
		return fuel.export && !fuel.consumption
	}

	/// Checks if we have export data.
	/// If we have export data then check if we don't have pv disagg data or consumption or production data.
	fuelsWaitingForElecPVDisagg(fuels: Fuel[]) {
		return fuels.some((fuel) => this.fuelWaitingForPVDisagg(fuel))
	}
}
