import { Component, Input } from '@angular/core'
import { SmrMeter } from '../../models/SmrMeter.model'
import { ModalService } from '@eliq/ui/modal'
//import { SubmitReadModalContainerComponent } from '../submit-read-modal-container/submit-read-modal-container.component'
import { NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { FlexibleButtonComponent } from '@eliq/ui'
import { ImgSrcPipe } from '@eliq/core'
import { SmrSubmitModalContainerComponent } from '../modals/submit-modal/components/submit-modal-container/submit-modal-container.component'

@Component({
	selector: 'eliq-smr-card-content',
	templateUrl: './smr-card-content.component.html',
	styleUrls: ['./smr-card-content.component.scss'],
	standalone: true,
	imports: [NgIf, TranslateModule, FlexibleButtonComponent, ImgSrcPipe],
})
export class SmrCardContentComponent {
	@Input() meter: SmrMeter
	@Input() meters: SmrMeter[]

	constructor(private modal: ModalService) {}

	showSubmitReadModal() {
		this.modal.openModal(SmrSubmitModalContainerComponent, {
			data: { meterID: this.meter.id, meter: this.meter, meters: this.meters },
		})
	}
}
