import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ModalService } from '@eliq/ui/modal'
import { InfoModalComponent } from '../../../info-modal/info-modal.component'
import { SmrMeter } from '@eliq/feature/smr-card/models/SmrMeter.model'
import { ExitModalComponent } from '../../../exit-modal/exit-modal.component'
//import { ViewHistoryModalComponent } from '../view-history-modal/view-history-modal.component'
import { ViewHistoryModalContainerComponent } from '@eliq/feature/smr-card/components/modals/view-history-modal-container/view-history-modal-container.component'
//import { SmrCardContentComponent } from '../smr-card-content/smr-card-content.component'
import { InfoButtonComponent, LinkComponent, XButtonComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'
import { NgFor, NgIf } from '@angular/common'
import { SmrSubmitModalComponent } from '../submit-modal/submit-modal.component'
@Component({
	selector: 'eliq-submit-modal-container',
	templateUrl: './submit-modal-container.component.html',
	styleUrls: ['./submit-modal-container.component.css'],
	standalone: true,
	imports: [
		NgIf,
		NgFor,
		ViewHistoryModalContainerComponent,
		//SmrCardContentComponent,
		LinkComponent,
		XButtonComponent,
		InfoButtonComponent,
		TranslateModule,
		SmrSubmitModalComponent,
	],
})
export class SmrSubmitModalContainerComponent {
	showViewHistory: boolean

	public titleKey = 'submit_meter_read.title'

	setTitleKey(key: string) {
		this.titleKey = key
	}

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { meter: SmrMeter; meters: SmrMeter[] },
		public dialogRef: MatDialogRef<SmrSubmitModalContainerComponent>,
		private modal: ModalService,
	) {}

	closeClicked() {
		const inst = this.modal.openModal(ExitModalComponent)
		inst.componentInstance.clicked.subscribe((_) => {
			this.dialogRef.close()
		})
	}

	public infoButtonClicked() {
		this.modal.openModal(InfoModalComponent)
	}

	public viewHistoryButtonClicked(isClicked: boolean) {
		if (isClicked == true) {
			this.showViewHistory = true
		}
		const modalRef = this.modal.openModal(ViewHistoryModalContainerComponent, {
			data: { meter: this.data.meter, meters: this.data.meters },
		})?.componentInstance
		modalRef.meter = this.data.meter
		modalRef.meters = this.data.meters
		this.dialogRef.close()
	}
}
