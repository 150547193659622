<div *ngIf="(emailIsVerified$ | async) === false" class="wrapper-wrapper">
	<form
		class="wrapper"
		[formGroup]="registerEmailForm"
		(ngSubmit)="onEmailSubmit()"
	>
		<p class="text">
			Create an account to have access to your energy data anytime. Just enter
			your email address.
		</p>

		<div class="form-inputs">
			<div class="email-input-container">
				<mat-form-field
					appearance="outline"
					color="primary"
					class="email-input"
				>
					<input
						matInput
						placeholder="Enter your email"
						[formControl]="email"
						required
					/>

					<img
						matPrefix
						src="/assets/images/account_mail.svg"
						style="width: 1em; height: auto; padding: 0 0.2em 0 0.7em;"
						alt="email"
					/>
					<!--<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
				</mat-form-field>
			</div>

			<div class="button-container">
				<eliq-basic-button
					[variant]="'secondary'"
					[useEntireWidth]="true"
					[submit]="true"
					[label]="'Submit'"
				></eliq-basic-button>
			</div>
		</div>
	</form>
</div>
