import { Injectable } from '@angular/core'
import { EliqTrackingService } from '@eliq/core/tracking'

import {
	TrackingScreen,
	TrackingEventName,
	BudgetCreateBudgetStartedEvent,
	BudgetCreateBudgetCancelledEvent,
	BudgetEditBudgetStartedEvent,
	BudgetCreateBudgetCreatedEvent,
	BudgetEditBudgetCancelledEvent,
	BudgetEditBudgetDeletedEvent,
	BudgetEditBudgetEditedEvent,
	BudgetLeftArrowClickedEvent,
	BudgetRightArrowClickedEvent,
	BudgetHomeCardBudgetClickedEvent,
} from '@eliq/core/tracking'

import { PeriodType } from '@eliq/core'

@Injectable({
	providedIn: 'root',
})
export class BudgetTrackingService {
	constructor(private tracking: EliqTrackingService) {}

	createBudgetStarted(from: TrackingScreen) {
		const data: BudgetCreateBudgetStartedEvent = {
			v: 1,
			from: from,
		}
		this.tracking.logEvent(TrackingEventName.BudgetCreateBudgetStarted, data)
	}

	createBudgetCancelled() {
		const data: BudgetCreateBudgetCancelledEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.BudgetCreateBudgetCancelled, data)
	}

	createBudgetCreated(period: PeriodType) {
		const data: BudgetCreateBudgetCreatedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetCreateBudgetCreated, data)
	}

	editBudgetStarted(period: PeriodType) {
		const data: BudgetEditBudgetStartedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetEditBudgetStarted, data)
	}

	editBudgetCancelled(period: PeriodType) {
		const data: BudgetEditBudgetCancelledEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetEditBudgetCancelled, data)
	}

	editBudgetDeleted(period: PeriodType) {
		const data: BudgetEditBudgetDeletedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetEditBudgetDeleted, data)
	}

	editBudgetEdited(period: PeriodType) {
		const data: BudgetEditBudgetEditedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetEditBudgetEdited, data)
	}

	leftArrowClicked(period: PeriodType) {
		const data: BudgetLeftArrowClickedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetLeftArrowClicked, data)
	}

	rightArrowClicked(period: PeriodType) {
		const data: BudgetRightArrowClickedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetRightArrowClicked, data)
	}

	homeCardBudgetClicked(period: PeriodType) {
		const data: BudgetHomeCardBudgetClickedEvent = {
			v: 1,
			period: period,
		}
		this.tracking.logEvent(TrackingEventName.BudgetHomeCardBudgetClicked, data)
	}
}
