import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'capitalize',
	standalone: true,
})
export class CapitalizePipe implements PipeTransform {
	transform(s: string): string {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}
}
