import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class InputHelperService {
	// a little helper service to sanitize input. i think we should find some library for this though!

	// backspace, delete, 4 arrows, tab
	private okKeys: number[] = [8, 46, 38, 39, 40, 37, 9]

	public makeid(length: any): string {
		let result = ''
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		const charactersLength = characters.length
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength))
		}
		return result
	}

	// Keys that are OK to handle when the input box is full. For example backspace, delete, and arrow keys
	public isOKFullKey(keyCode: number) {
		return this.okKeys.find((myKeyCode) => {
			return myKeyCode === keyCode
		})
	}

	// ok if we are inputing number 0-9 or tab (keycode 9)
	public isOKNumberKey(keyCode: number) {
		return (keyCode >= 48 && keyCode <= 57) || keyCode === 9
	}
}
