<ng-container *ngIf="configButton$ | async as configButton">
	<eliq-basic-button
		[buttonStyle]="buttonStyle"
		*ngIf="!hidden && configButton.type !== 'underline'"
		[variant]="configButton.type"
		[label]="label"
		[useEntireWidth]="useEntireWidth"
		[disabled]="disabled"
		[variant]="variant"
		[custom]="custom"
		[iconSrc]="iconSrc"
		[submit]="submit"
		[loading]="loading"
		(clicked)="clicked.emit()"
	>
		<ng-content></ng-content>
	</eliq-basic-button>

	<eliq-link
		[buttonStyle]="buttonStyle"
		*ngIf="configButton.type === 'underline'"
		[label]="label"
		[disabled]="disabled"
		(clicked)="clicked.emit()"
		[type]="type"
		[leftIcon]="leftIcon"
	>
		<ng-content></ng-content>
	</eliq-link>
</ng-container>
