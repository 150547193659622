import { Component, Input, OnInit } from '@angular/core'
import { BlobHelper } from '@eliq/util'
import { APITransaction } from '../../models/api-models/api-transaction.model'
import { BillingHandlerService } from '../../services/billing-handler.service'
import { EliqDatePipe } from '@eliq/core/pipes/date.pipe'
import { EliqCurrencyPipe } from '@eliq/core/pipes/currency.pipe'
import { SpinnerComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'
import { NgIf, LowerCasePipe } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-transaction-big-invoice',
	templateUrl: './transaction-big-invoice.component.html',
	styleUrls: ['./transaction-big-invoice.component.scss'],
	standalone: true,
	imports: [
		TranslateModule,
		NgIf,
		LinkComponent,
		SpinnerComponent,
		LowerCasePipe,
		EliqCurrencyPipe,
		EliqDatePipe,
	],
})
export class TransactionBigInvoiceComponent implements OnInit {
	@Input() transaction: APITransaction
	@Input() billingAccountId: string

	constructor(private billingHandlerService: BillingHandlerService) {}

	public downloadingTransaction = false

	ngOnInit(): void {}

	downloadTransaction() {
		this.downloadingTransaction = true

		this.billingHandlerService
			.getTransactionFile(this.billingAccountId, this.transaction.id)
			.subscribe((data) => {
				this.downloadingTransaction = false
				BlobHelper.downLoadFile(
					`${this.transaction.transaction_number}.pdf`,
					data,
					'application/pdf',
				)
			})
	}
}
