// @ts-nocheck
import { Injectable } from '@angular/core'
import { Fuel } from '@eliq/core'

@Injectable({
	providedIn: 'root',
})
export class DctHomeSetup {
	public getStartYear(
		components: import('@eliq/core').ConfigComponent[],
		elecFuels: Fuel[],
	) {
		const dctConfig = components.find(
			(c) => c.location.moduleId === 'dynamic-capacity-tariffs',
		)
		let startYear: number | null = null
		if (dctConfig) {
			startYear = parseInt(dctConfig.inputOptions.startYear)
		}
		if (startYear) {
			if (startYear > new Date().getFullYear()) {
				return null
			}
		} else {
			let fuels = { ...elecFuels }
			//default as pv or elec fuel start year
			const pvFuels = elecFuels.filter((f) => !!f.import)
			if (pvFuels.length > 0) {
				fuels = pvFuels
			}
			//always at least one as dct card is enabled
			//Parse will end at dash https://stackoverflow.com/a/4170128
			startYear = parseInt(fuels[0].consumption.data_from)
			for (let i = 1; i < fuels.length; i++) {
				const fuelStartYear = parseInt(fuels[i].consumption.data_from)
				if (fuelStartYear < startYear) {
					startYear = fuelStartYear
				}
			}
		}
		return startYear
	}
}
