// @ts-nocheck

import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { subMonths } from 'date-fns'
import { ConsumptionHandlerService } from '../consumption-handler/consumption-handler.service'
import { DateHelper } from '@eliq/util'
import { LocationHttpService } from '../http-services/location-http.service'
import {
	resolutionSorter,
	ResolutionType,
} from '../../models/src/ResolutionType.model'
import { PeriodType } from '../../models/src/PeriodType.model'

@Injectable({
	providedIn: 'root',
})
export class CurrentPeriodService {
	private dateHelper: DateHelper

	constructor(
		private locHttp: LocationHttpService,
		private consHandler: ConsumptionHandlerService,
	) {
		this.dateHelper = DateHelper.getInstance()
	}

	/**
	 * Returns date of the month which is the last period there exists data
	 * @param locId location id
	 * @param fuel fuel
	 * @param nMonthsToCheck the amount of months to check
	 * @param getLatestDay whether or not to get latest daily data point as a date as well. defaults to false
	 */
	public getLastMonthWithData(
		locId: number,
		fuelType: string,
		fuelResolution: ResolutionType,
		nMonthsToCheck = 6,
		getLatestDay = false,
	): Observable<{ month: Date; day: Date }> {
		// If we are in september right now for example, we want to make the request to October 1st 00:00.
		const currentMonth = new Date()
		const toDate = this.dateHelper.getFirstDayOfNextMonth(currentMonth)
		// get 6 months previously
		const fromDate = subMonths(toDate, nMonthsToCheck)

		return this.locHttp
			.getLocationConsumption(
				locId,
				fuelType,
				'energy',
				ResolutionType.Month,
				fromDate,
				toDate,
			)
			.pipe(
				map((res) => {
					// The last value in the consumption array will be of current month.
					const cons = res.consumption.reverse()
					const foundIndex = cons.findIndex((val) => val !== null)

					if (foundIndex === -1) {
						return currentMonth
					}

					return subMonths(currentMonth, foundIndex)
				}),
				switchMap((date) => {
					if (
						resolutionSorter(fuelResolution, ResolutionType.Day) <= 0 &&
						getLatestDay
					) {
						// we have daily data, lets get the latest day datapoint
						const monthStartDate = this.dateHelper.startOfPeriod(
							PeriodType.Month,
							date,
						)
						const monthEndDate = this.dateHelper.addOfPeriod(
							PeriodType.Month,
							1,
							date,
						)

						return this.consHandler
							.getConsumptionForFuels(
								locId,
								[fuelType],
								'energy',
								ResolutionType.Day,
								monthStartDate,
								monthEndDate,
							)
							.pipe(
								map((consMap) => consMap.get(fuelType)),
								map((cons) => {
									const lastDataIndex = cons.findIndex((c) => c === null)
									const firstDayWithoutConsumption = lastDataIndex + 1 // if last consumption day is 15th, then date_to is YYYY-MM-16 00:00:00 to include the 15th.
									return new Date(
										monthStartDate.getFullYear(),
										monthStartDate.getMonth(),
										lastDataIndex === -1
											? cons.length - 1
											: firstDayWithoutConsumption,
									)
								}),
								map((dayDate) => {
									return {
										month: date,
										day: dayDate,
									}
								}),
							)
					} else {
						return of({ month: date, day: undefined })
					}
				}),
			)
	}

	/**
	 * A function that returns an observable of the current period the location's fuel is in.
	 * This is useful for a typical home view to determine what month's data should be shown to the user.
	 * @param locId the location id
	 * @param fuel the fuel that is to be used
	 * @returns a json with fromDate and toDate fields that can be used to make requests
	 */
	public getCurrentPeriodDates(
		locId: number,
		fuelType: string,
		fuelResolution: ResolutionType,
	): Observable<{
		fromDate: Date
		toDate: Date
		mostRecentMonthWithData: Date
	}> {
		const debug = false
		// if we are trying to determine the period for a user that only has monthly resolution, then we should return which year
		// the request should be made in. the return would then be two dates that span a year, so 2019-01-01:00:00 to 2020-01-01:00:00 for example.

		const currentDate = new Date()
		const firstDayNextMonth =
			this.dateHelper.getFirstDayOfNextMonth(currentDate)
		const oneYearBefore =
			this.dateHelper.getSameDatePreviousYear(firstDayNextMonth)
		const allMonthsBetween = this.dateHelper.getAllDatesBetween(
			oneYearBefore,
			firstDayNextMonth,
			'month',
		)

		if (debug) {
		}

		return this.locHttp
			.getLocationConsumption(
				locId,
				fuelType,
				'energy',
				ResolutionType.Month,
				oneYearBefore,
				firstDayNextMonth,
			)
			.pipe(
				map((cons) => {
					//if (!this.consHelper.arrayHasNonNullValue(cons.consumption)) {
					//throw new Error('Error: no values for the past 12 months.')
					//}

					// kind of walk backwards in the list until we find an index in the consumption array with values
					let latestMonthIndexWithValues = 0
					for (let i = cons.consumption.length - 1; i >= 0; i--) {
						if (cons.consumption[i] !== null) {
							latestMonthIndexWithValues = i
							// stop the loop
							i = -1
						}
					}

					const latestMonthWithValues =
						allMonthsBetween[latestMonthIndexWithValues]

					if (fuelResolution === ResolutionType.Month) {
						return this.monthlyDataCalculations(latestMonthWithValues)
					} else {
						return this.dailyDataCalculations(latestMonthWithValues)
					}
				}),
			)
	}

	private monthlyDataCalculations(latestMonthWithValue: Date) {
		// the idea in this algorithm is to, same as in daily data calculations,
		// make monthly data request spanning one year. if we for example right now are in june (month 06),
		// the dates we make request to are: fromdate: 2019-07-01 todate: 2020-07-01.
		// we then, in this monthly section, get the latest month that has consumption data.
		// we then take the year that month is in, and then set fromdate as 1st january of that year, and to date
		// to 1st january of next year.

		const year = latestMonthWithValue.getFullYear()
		// remember that months are 0-based, years and days are not. 0 is january, but day 1 is still day 1 of the month
		const startOfNextYear = new Date(year + 1, 0, 1)
		const startOfCurrentYear =
			this.dateHelper.getSameDatePreviousYear(startOfNextYear)

		return {
			fromDate: startOfCurrentYear,
			toDate: startOfNextYear,
			mostRecentMonthWithData: latestMonthWithValue,
		}
	}

	private dailyDataCalculations(latestMonthWithValue: Date) {
		const currentMonthStartingDate = latestMonthWithValue
		const currentMonthEndingDate = this.dateHelper.getLastDayOfMonth(
			currentMonthStartingDate,
		)

		return {
			fromDate: currentMonthStartingDate,
			toDate: currentMonthEndingDate,
			mostRecentMonthWithData: latestMonthWithValue,
		}
	}
}
