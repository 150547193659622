import { Item } from './Item.model'

export class Category {
	name: string
	items: Item[]

	constructor(items: Item[], name: string) {
		this.items = items
		this.name = name
	}
}
