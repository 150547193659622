import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http'
import {
	EnvironmentInjector,
	Inject,
	Injectable,
	Optional,
} from '@angular/core'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { Request } from 'express'
import { HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError, BehaviorSubject, of } from 'rxjs'
import { AuthService } from '../services/auth.service'
import {
	catchError,
	filter,
	take,
	switchMap,
	tap,
	map,
	delay,
	retry,
} from 'rxjs/operators'
import { ModalService } from '@eliq/ui/modal'
import { PleaseRefreshModalComponent } from '../components/please-refresh-modal/please-refresh-modal.component'
import { EnvironmentService } from '@eliq/data-access'
import { TransferState, makeStateKey, StateKey } from '@angular/core'
import { CookieService } from '@eliq/data-access/cookie'
import { Router } from '@angular/router'
import { CoreDataStoreService } from '@eliq/core'
// case insensitive check against config and value
const startsWithAny =
	(arr: string[] = []) =>
	(value = '') => {
		return arr.some((test) =>
			value.toLowerCase().startsWith(test.toLowerCase()),
		)
	}

// http, https, protocol relative
const isAbsoluteURL = startsWithAny(['http', '//'])

@Injectable({ providedIn: 'root' })
export class TokenInterceptorService implements HttpInterceptor {
	private isWebComponent: boolean

	constructor(
		@Optional() @Inject(REQUEST) protected request: Request,
		private auth: AuthService,
		@Inject('IS_WEB_COMPONENT') IS_WEB_COMPONENT: boolean,
		private modal: ModalService,
		private env: EnvironmentService,
		private router: Router,
		private transferState: TransferState,
		private cookieService: CookieService,
		private coreDS: CoreDataStoreService,
	) {
		this.isWebComponent = IS_WEB_COMPONENT
	}

	public readonly NO_AUTH_URLS = ['/login', '/embed-with-ticket']

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (this.request && !isAbsoluteURL(req.url)) {
			const protocolHost = `${this.request.protocol}://${this.request.get(
				'host',
			)}`
			const pathSeparator = !req.url.startsWith('/') ? '/' : ''
			const url = protocolHost + pathSeparator + req.url
			const serverRequest = req.clone({ url })
			return this._intercept(serverRequest, next)
		} else {
			return this._intercept(req, next)
		}
	}

	private isOnNoAuthUrl(url?: string) {
		const getFirstPart = (url: string) =>
			(url.split('?')[0].match(/((\/[^/]+)?)|(\w\/)/g) ?? []).filter(
				(x) => x,
			)[1]
		const needle1 = getFirstPart(url ?? '')
		const needle2 = getFirstPart(location?.href ?? '')
		return (
			(!needle1 && !needle2) ||
			this.NO_AUTH_URLS.includes(needle1) ||
			this.NO_AUTH_URLS.includes(needle2)
		)
	}

	/*private logoutConditions() {
		if (
			(false === this.isOnNoAuthUrl() &&
				this.isRefreshing &&
				this.env.isBrowser() &&
				Date.now() - this.auth.getLastUpdateTimestamp() > 1000 * 10) ||
			this.auth.getLastUpdateTimestamp() === 0
		) {
			return true
		}
		return false
	}*/

	hasErrored = false

	timeSinceLastError = 0
	numberOfErrors = 0
	maxErrorsPerMinute = 3

	_intercept(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (this.requestNeedsToken(request)) {
			if (this.auth.isLoggedIn()) {
				request = this.addToken(request, this.auth.getAccessToken())
			}
		}

		return next.handle(request).pipe(
			catchError((error: any) => {
				if (!this.env.isBrowser()) {
					return throwError(() => error)
				}

				if (
					error instanceof HttpErrorResponse &&
					request.url.includes('grant_type=refresh_token')
				) {
					console.warn('Errored when refreshing token so logging out')
					this.auth.logout(true)
					return throwError(() => error)
				} else if (error instanceof HttpErrorResponse && error.status === 401) {
					// if its just any normal request getting specifically a 401 error, then we should handle this!
					return this.handle401Error(request, next)
				} else {
					// else we just throw the error.
					console.warn('tokenInterceptor threw error:', error)
					return throwError(() => error)
				}
			}),
		)
	}
	private requestNeedsToken(request: HttpRequest<any>): boolean {
		if (
			request.url.includes('config.json') ||
			request.url.includes('/translations')
		)
			return false
		else if (isAbsoluteURL(request.url)) return true
		else return false
	}

	private addToken(request: HttpRequest<any>, token?: string) {
		if (token && token !== 'placeholder') {
			if (this.request && this.request?.res?.writableEnded) {
				console.error(
					'tried to add token to ',
					request.url,
					'but writeableEnded',
					this.request.res?.writableEnded,
				)
			}

			if (
				this.request &&
				this.request.hostname !== 'localhost' &&
				!this.request.res?.writableEnded
			) {
				this.request?.res?.header('Authorization', `Bearer ${token}`)
			} else if (this.env.isBrowser()) {
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${token}`,
					},
				})
			}
		}
		return request.clone({
			withCredentials: true,
		})
	}

	// part that handles 401 errors
	private isRefreshing = false
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
		null,
	)

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.env.isBrowser()) {
			return next.handle(request)
		}

		if (this.isOnNoAuthUrl(request.url)) {
			//throw new Error('401 on non-auth url')
			return next
				.handle(this.addToken(request))
				.pipe(take(1), retry({ count: 5, delay: 2000 }))
		}

		if (this.isRefreshing === false) {
			this.isRefreshing = true

			if (this.isWebComponent) {
				this.modal.openModal(PleaseRefreshModalComponent)
				return next.handle(request)
			}

			const addRefreshedTokenToRequest = this.auth.refreshAccessToken().pipe(
				switchMap((at) => {
					// 1. Get access token using refresh token
					//
					this.isRefreshing = false
					// refresh access token call returns an AT if we're running storage mode, or undefined otherwise.
					// but we still need some way to populate the observable, so just pass in some "established" string (non-null).
					this.refreshTokenSubject.next(at ? at : 'placeholder')

					return next.handle(this.addToken(request, at)) // 2. Try to do the request again with the new access token
				}),
				catchError((err: HttpErrorResponse) => {
					if (err.status === 401) {
						// 2. if the new access token doesn't work retry with whatever access token we get from the auth service
						return next
							.handle(this.addToken(request, this.auth.getAccessToken()))
							.pipe(
								map((result) => {
									return result
								}),
								catchError((err, _caught) => {
									console.warn('Logout called in token interceptor: 241')
									this.auth.logout(true)
									return throwError(() => err)
								}),
							)
					} else {
						return throwError(() => err)
					}
				}),
			)

			if (!request.headers.has('Authorization')) {
				return addRefreshedTokenToRequest
			}

			if (!this.auth.getRefreshToken() && this.auth.getAccessToken()) {
				this.auth.logout(true)
				return next.handle(request)
			}

			return addRefreshedTokenToRequest
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				switchMap((token) => {
					return next.handle(this.addToken(request, token))
				}),
			)
		}
	}
}
