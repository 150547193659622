import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ModalService } from '@eliq/ui/modal'
import { InfoModalComponent } from '../info-modal/info-modal.component'
import { SmrApiService } from '../../../services/smr-api.service'
import { SmrMeter } from '../../../models/SmrMeter.model'
import {
	BasicButtonComponent,
	InfoButtonComponent,
	SpinnerComponent,
	XButtonComponent,
} from '@eliq/ui'
import { ViewHistoryModalComponent } from '../view-history-modal/view-history-modal.component'
import { NgFor, NgForOf, NgIf } from '@angular/common'
import { SmrSelectMeterDropdownComponent } from '../../smr-select-meter-dropdown/smr-select-meter-dropdown.component'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { FormControl } from '@angular/forms'
import { SmrSubmitModalContainerComponent } from '../submit-modal/components/submit-modal-container/submit-modal-container.component'

@Component({
	selector: 'eliq-view-history-modal-container',
	templateUrl: './view-history-modal-container.component.html',
	styleUrls: ['./view-history-modal-container.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		NgForOf,
		NgFor,
		InfoModalComponent,
		XButtonComponent,
		ViewHistoryModalContainerComponent,
		SmrSubmitModalContainerComponent,
		ViewHistoryModalComponent,
		InfoButtonComponent,
		SmrSelectMeterDropdownComponent,
		BasicButtonComponent,
		SpinnerComponent,
		TranslateModule,
	],
})
export class ViewHistoryModalContainerComponent
	implements AfterViewInit, OnChanges
{
	@Input() meter: SmrMeter
	@Input() meters: SmrMeter[]

	meterReads: string[] = []
	meterOptions = [] as any[]
	loaded: boolean
	homeCardButtonClicked: boolean
	dropdownSelected: boolean

	public meterIds = [] as string[]
	public meterLabels = [] as string[]
	public meterIdCtrl = new FormControl('', [])

	constructor(
		private dialogRef: MatDialogRef<ViewHistoryModalContainerComponent>,
		private changeDetector: ChangeDetectorRef,
		private api: SmrApiService,
		private translate: TranslateService,
		private modal: ModalService,
	) {}

	showSubmitReadModal() {
		this.modal.openModal(SmrSubmitModalContainerComponent, {
			data: { meter: this.meter, meters: this.meters },
		})
		this.dialogRef.close()
	}

	ngAfterViewInit(): void {
		//this.getMeterReadHistory(this.meter?.id)

		if (this?.meter?.id) {
			this.homeCardButtonClicked = true
			this.getMeterReadHistory(this.meter.id)
			this.meterIdCtrl.setValue(this.meter?.id)
		}

		this.meterIdCtrl.valueChanges.subscribe((value) => {
			if (!value) {
				return
			}
			if (JSON.stringify(this.meter.id) === JSON.stringify(value)) {
				return
			}

			this.meter =
				this.meters.find(
					(meter) => JSON.stringify(meter.id) === JSON.stringify(value),
				) || this.meter
			/*this.meters = [
				this.meter,
				...this.meters.filter(
					(m) => JSON.stringify(m.id) !== JSON.stringify(value),
				),
			]*/

			this.getMeterReadHistory(this.meter.id)
		})
	}

	calculateDropdownLabels() {
		if (!this.meter?.id || !this.meters?.length) {
			return
		}

		this.meterIds = this.meters.map((meter) => {
			return meter?.id || (meter as unknown as string)
		})

		// make selected meter the first meter in the meterIds
		this.meterIds = [
			this.meter.id,
			...this.meterIds.filter((id) => id !== this.meter.id),
		]

		this.meterLabels = this.meterIds.map((id) => {
			const type = this.meters.find((meter) => meter.id === id)?.type || id
			return this.translate.instant('common.' + type)
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			!this.meterReads?.length &&
			this.meter?.id &&
			this.meterIds?.length &&
			this.meterLabels?.length
		) {
			this.meterIdCtrl.setValue(this.meter?.id)
		}

		if (
			changes['meter'] &&
			changes['meter']['currentValue']?.id &&
			changes['meter']['previousValue']?.id
		) {
			if (
				changes['meter']['currentValue'].id !==
				changes['meter']['previousValue'].id
			) {
				//this.homeCardButtonClicked = true
				this.getMeterReadHistory(this.meter.id)
			}
		}
	}

	getMeterReadHistory(id: string) {
		this.loaded = false
		this.changeDetector.detectChanges()
		this.changeDetector.detach()

		this.api.getReadHistory(id).subscribe((res) => {
			this.changeDetector.reattach()
			this.meterReads = res
			if (this.meterReads) {
				this.loaded = true
			}
			//this.changeDetector.reattach()
			this.calculateDropdownLabels()
			this.changeDetector.detectChanges()
		})
	}

	closeClicked() {
		this.dialogRef.close()
	}

	public infoButtonClicked() {
		this.modal.openModal(InfoModalComponent)
	}

	// getSelectedVal(value: string) {
	// 	if (value) {
	// 		this.dropdownSelected = true
	// 	}
	// 	if (!this.meter?.id) {
	// 		return
	// 	}
	// 	this.meter.id = value
	// 	this.getMeterReadHistory(this.meter?.id)
	// }

	// private getMeterOptions() {
	// 	let meters: SmrMeter[] = [] // Initialize meters as an empty array
	// 	this.api.getMeters().subscribe((res: SmrMeter[]) => {
	// 		meters = res
	// 	})

	// 	const keys: string[] = [] // Initialize keys as an empty array
	// 	const values: string[] = [] // Initialize values as an empty array

	// 	for (let i = 0; i < meters.length; i++) {
	// 		keys[i] = meters[i].id
	// 		values[i] = meters[i].type
	// 	}

	// 	for (let i = 0; i < keys.length; i++) {
	// 		const item: any = {}
	// 		item[keys[i]] = values[i]
	// 		this.meterOptions.push(item)
	// 	}
	// }
}
