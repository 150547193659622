import { formatISO } from 'date-fns'
import { DateHelper } from '@eliq/util'
import {
	APIDataStream,
	APIFuel,
	APIPVSystem,
} from './api-models/api-location.model'
import { FuelType } from './FuelType.model'
import { resolutionSorter, ResolutionType } from './ResolutionType.model'

export type UnitType = Readonly<'cost' | 'energy' | 'm3'>
export const DATA_STREAMS = [
	'import',
	'export',
	'consumption',
	'production',
] as const
export type DataStreamType = (typeof DATA_STREAMS)[number]

export class Location {
	constructor(
		public id: number,
		public name: string,
		public fuels: Fuel[],
		public pvSystems: APIPVSystem[],
		public address: LocationAddress,
	) {}
}

export class Fuel {
	import: APIDataStream
	export: APIDataStream
	consumption: APIDataStream
	production: APIDataStream
	resolution: ResolutionType
	type: FuelType

	constructor(type: FuelType, apiFuel: APIFuel) {
		this.type = type
		this.import = apiFuel.import as any
		this.export = apiFuel.export as any
		this.consumption = apiFuel.consumption as any
		this.production = apiFuel.production as any
		this.populateMissingDataToFields()
		this.resolution = this.getHighestCommonResolution([
			this.import,
			this.export,
			this.consumption,
			this.production,
		])
	}

	public getDataStreamsForUnit(unit: string): {
		in: APIDataStream
		out: APIDataStream
	} {
		// see: https://eliqdev.atlassian.net/wiki/spaces/PD/pages/647495697/Cost+Energy+data+streams
		if (unit == 'cost') {
			if (this.import) {
				return {
					in: this.import,
					out: this.export,
				}
			} else {
				return {
					in: this.consumption,
					out: undefined as any,
				}
			}
		} else if (unit == 'energy') {
			if (this.consumption) {
				return {
					in: this.consumption,
					out: this.production,
				}
			} else {
				return {
					in: this.import,
					out: this.export,
				}
			}
		} else {
			throw new Error('Unhandled unit in Location.model.ts')
		}
	}

	public getLatestDataDateForUnit(
		unit: string,
		startDailyFromYesterday: boolean,
	) {
		const dataStreams = this.getDataStreamsForUnit(unit)
		let dataTo = this.getDateExtremes([dataStreams.in, dataStreams.out]).to

		if (startDailyFromYesterday) {
			if (dataTo.getHours() === 0)
				dataTo = DateHelper.getInstance().subOfPeriod('day', 1, dataTo)
		} else {
			// There are clients for which consumptionAvailableTo is in the future, let's handle that
			const todayDate = new Date()
			if (dataTo > todayDate) {
				dataTo = new Date(
					todayDate.getFullYear(),
					todayDate.getMonth(),
					todayDate.getDate(),
				)
			}
		}

		return dataTo
	}

	public getFirstDataDateForUnit(unit: string) {
		const dataStreams = this.getDataStreamsForUnit(unit)
		const dataFrom = this.getDateExtremes([
			dataStreams.in,
			dataStreams.out,
		]).from
		//dataFrom = DateHelper.getInstance().subOfPeriod("day", 1, dataFrom)

		return dataFrom
	}

	private getDateExtremes(dataStreams: APIDataStream[]): {
		from: Date
		to: Date
	} {
		dataStreams = dataStreams.filter((ds) => ds) // null filter
		const retVal = {
			from: dataStreams.map((ds) => new Date(ds.data_from)).sort()[0],
			to: dataStreams
				.map((ds) => new Date(ds.data_to))
				.sort()
				.reverse()[0],
		}

		return retVal
	}

	private getHighestCommonResolution(
		dataStreams: APIDataStream[],
	): ResolutionType {
		return dataStreams
			.filter((ds) => ds)
			.sort((a, b) => resolutionSorter(a.resolution, b.resolution))[0]
			.resolution
	}

	// If data_to is missing, just place yesterday's date there.
	// It's missing when the data_to is not returned in API Location endpoint's response: Fuels -> ... -> Consumption -> data_to
	private populateMissingDataToFields() {
		;[this.consumption, this.production, this.import, this.export].forEach(
			(ds) => {
				if (ds) {
					// null check
					if (!ds.data_to) {
						const newToDate = new Date()
						newToDate.setHours(0, 0, 0, 0)

						ds.data_to = formatISO(newToDate)
					}
				}
			},
		)
	}
}

export class LocationAddress {
	constructor(
		public city: string,
		public country_code: string,
		public postal_code: string,
		public street_address: string,
	) {}
}
