import { Component, Input, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { XButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-signin-consent-learn-more-modal',
	templateUrl: './signin-consent-learn-more-modal.component.html',
	styleUrls: ['./signin-consent-learn-more-modal.component.css'],
	standalone: true,
	imports: [XButtonComponent],
})
export class SigninConsentLearnMoreModalComponent implements OnInit {
	@Input() title: string
	@Input() text: string

	constructor(
		public dialogRef: MatDialogRef<SigninConsentLearnMoreModalComponent>,
	) {}

	ngOnInit(): void {}

	closeClicked() {
		this.dialogRef.close()
	}
}
