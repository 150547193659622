<eliq-card class="eliq-default-card-style container">
	<ng-container *ngIf="loaded; else loadingBlock">
		<div class="top-part">
			<ng-container *ngIf="type === 'account-balance'">
				<eliq-mini-account-balance
					[accountBalance]="accountBalance"
				></eliq-mini-account-balance>
				<p class="primary-p1" style="padding: 0 24px; font-weight: bold;">
					{{'my_bills.latest_bill' | translate}}
				</p>
			</ng-container>
			<ng-container *ngIf="type === 'latest-transaction'">
				<h6
					class="primary-h6"
					style="font-weight: bold; padding: 24px; margin: 0;"
				>
					{{'my_bills.latest_bill' |
          translate}}
				</h6>
			</ng-container>
		</div>

		<div class="bottom-part">
			<div class="my-transaction">
				<eliq-transaction-item
					isMini="true"
					[options]="options"
					*ngIf="transaction"
					[billingAccountId]="billingAccountId"
					[transaction]="transaction"
				></eliq-transaction-item>
				<p *ngIf="!transaction" class="secondary-p2" style="margin: 0;">
					{{'my_bills.no_bills_yet' | translate}}
				</p>
			</div>

			<div class="link-button-container">
				<a routerLink="/bills">
					<eliq-flexible-button
						buttonId="home_transactions_card_view_all_button"
						label="my_bills.view_all_bills"
					></eliq-flexible-button>
				</a>
			</div>
		</div>
	</ng-container>

	<ng-template #loadingBlock>
		<div class="loading-container">
			<eliq-spinner size="medium"></eliq-spinner>
		</div>
	</ng-template>
</eliq-card>
