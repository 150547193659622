import { Inject, Injectable, Optional, isDevMode } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators'
import { EliqTrackingService } from '@eliq/core/tracking'
import { LoginService } from './login.service'
import { EnvironmentService } from '@eliq/data-access'
import { EliqApiHttpClient } from '@eliq/data-access'
import { CookieService } from '@eliq/data-access/cookie'
import { CoreDataStoreService } from '@eliq/core'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { TokenWrapper } from './ticket.service'
import { TransferState, makeStateKey, StateKey } from '@angular/core'
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens'
import { Request, Response } from 'express'
import { CacheService } from '@eliq/data-access'

const COOKIE_SETTINGS = {
	path: '/',
	secure: true,
	domain: !isDevMode() ? '*.eliq.io' : 'localhost',
	sameSite: 'none', //!isDevMode() ? 'Strict' : 'None',
	expires: 2592e6, // same as 1000 * 60 * 60 * 24 * 30 = 30 days
} as const

export enum UserSecretKey {
	userId = 'userId',
	accessToken = 'accessToken',
	refreshToken = 'refreshToken',
}
export interface UserSecrets {
	[UserSecretKey.userId]: number
	[UserSecretKey.accessToken]: string
	[UserSecretKey.refreshToken]: string
}

type StringUserSecrets = { [K in keyof UserSecrets]?: string }

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	// If we can't save cookies then we can still store it in runtime memory until page is refreshed

	private isLocalStorageAuth: boolean

	private loggingOutAlready = false

	private url = '/v3'
	private clientId = ''
	private localStorageAuth: boolean
	private lastUpdateTimestamp = 0

	constructor(
		@Optional() @Inject(REQUEST) private request: Request,
		@Optional() @Inject(RESPONSE) private response: Response,
		private http: EliqApiHttpClient,
		@Inject('clientId') clientId: string,
		//private http2: HttpClient,
		private router: Router,
		private cookieService: CookieService,
		//private env: EnvironmentService,
		private tracking: EliqTrackingService,
		@Inject('LOCAL_STORAGE_AUTH') localStorageAuth: boolean,
		private transferState: TransferState,
		private env: EnvironmentService,
		private cacheService: CacheService,
		private coreDS: CoreDataStoreService,
	) {
		this.isLocalStorageAuth = localStorageAuth
		this.clientId = clientId
		this.localStorageAuth = localStorageAuth
	}

	public getLastUpdateTimestamp() {
		return this.lastUpdateTimestamp
	}

	public setCookie(name: UserSecretKey, value: string | number) {
		if (typeof value !== 'string' && typeof value !== 'number') {
			console.error(
				"setCookie called with value that isn't string or number:",
				value,
			)
			return
		}
		if (typeof value === 'number') {
			value = value.toString()
		}

		if ((value as string)?.length === 0) {
			console.error('setCookie called with empty value:', value)
			return
		}

		const serverCookieKey = makeStateKey<string>(name)

		const saveCookie = (name, value) => {
			this.cookieService.put(name, value, 'necessary', {
				expires: new Date(Date.now() + COOKIE_SETTINGS.expires),
				path: COOKIE_SETTINGS.path,
				secure: COOKIE_SETTINGS.secure,
				//domain: COOKIE_SETTINGS.domain,
				sameSite: COOKIE_SETTINGS.sameSite,
				//domain: COOKIE_SETTINGS.domain,
				//secure: COOKIE_SETTINGS.secure,
				//sameSite: COOKIE_SETTINGS.sameSite,
			})
			this.lastUpdateTimestamp = Date.now()
		}

		if (!this.env.isBrowser()) {
			// if we are on server then set transferState which will be available on client
			this.transferState.set(serverCookieKey, value + '')
			saveCookie(name, value)
		} else if (this.transferState.hasKey(serverCookieKey)) {
			// if we're on browser and there is transferState already then use it
			const serverCookieValue = this.transferState.get(serverCookieKey, '')
			saveCookie(name, serverCookieValue) // set the cookie to the server value
			this.transferState.remove(serverCookieKey) // remove the transfer state so we can get updates from server
		} else {
			saveCookie(name, value) // if we're on browser and there is no transferState then just set the cookie
		}

		// Refresh location data so it doesn't infinitely load
		this.cacheService.clear()
		this.coreDS.refetchLocations().subscribe((x) => {})
	}

	public getCookie(name: UserSecretKey) {
		const serverCookieKey = makeStateKey<string>(name)
		if (this.env.isBrowser() && this.transferState.hasKey(serverCookieKey)) {
			const serverCookieValue = this.transferState.get(serverCookieKey, '')
			if (serverCookieValue?.length > 0) {
				this.setCookie(name as UserSecretKey, serverCookieValue)
				this.transferState.remove(serverCookieKey)
			}
			return serverCookieValue
		}

		return this.cookieService.get(name)
	}

	/**
	 * @param userId the user id to log in with
	 */
	public login(
		userId: number | null,
		accessToken?: string,
		refreshToken?: string,
	) {
		this.cacheService.clear()
		this.tracking.setUserId(userId)
		this.storeItems(userId ?? NaN, accessToken, refreshToken)
	}

	/**
	 * Log a user in temporarily, stores credentials in local js variable.
	 * @param accessToken
	 * @param userId
	 */
	public loginTemp(accessToken: string, userId: number | null) {
		this.cacheService.clear()
		this.login(userId, accessToken)
	}

	public logout(immediateRedirect = true) {
		const currentPath = this.router.url.split('?')[0]
		const notLoggedInGuardedPath = ['/login', '/embed-with-ticket']
		if (
			currentPath === '/' ||
			notLoggedInGuardedPath.some((ignoredPath) =>
				currentPath.includes(ignoredPath),
			)
		) {
			return
		}
		this.removeItems()
		if (!this.env.isBrowser() && this.response) {
			if (immediateRedirect) {
				this.response.status(401).redirect('/login')
			}
			this.response.end()
		}
		if (this.env.isBrowser()) {
			this.router.navigateByUrl('/login')
			this.invalidateSession$()
				.pipe(
					take(1),
					finalize(() => {
						if (immediateRedirect) {
							this.router.navigateByUrl('/login')
							const _location = (location || window?.location) as any
							if (_location?.reload) {
								_location.reload()
							} else {
								;(_location as any).href = ''
							}
						}
					}),
				)
				.subscribe()
		}
	}

	/**
	 * returns a boolean true if logged in
	 * If you are logged in with the method which is no longer specified, then
	 * you will be force-logged out.
	 */
	public isLoggedIn(): boolean {
		// if we have deprecated tokens and we are set to use httponly cookie auth, then log out.
		//if (this.hasLocalStorageAccessTokens() && !this.isLocalStorageAuth) {
		//setTimeout(this.logout, 2000)
		//return false
		//} else if (
		//	this.getUserId() &&
		//	!this.hasLocalStorageAccessTokens() &&
		//	this.isLocalStorageAuth
		//) {
		// if we have a logged in user, and we do not have local storage auth tokens present, and we are set to storage auth mode,
		// then we should log out the current httponly cookie auth method.
		//setTimeout(this.logout, 2000)
		//return false
		//}

		return !!this.getUserId()
	}

	/**
	 * Refreshes your access token based on the currently selected authentication method, either storage or cookie.
	 * If storage, returns a string with the access token in it.
	 * If cookie, returns undefined. The act of returning however still signifies completion.
	 * @returns
	 */
	public refreshAccessToken(): Observable<string> {
		if (this.isLocalStorageAuth) {
			// if we are running local storage auth mode, do this
			// Return the AT to the caller.
			return this.getAccessTokenByRefreshToken(
				this.getRefreshToken() ?? '',
				this.getUserId(),
			).pipe(
				tap((result) => {
					const accessToken =
						result?.token?.access_token ??
						(result?.token as any)?.accessToken ??
						''
					const refreshToken =
						result?.token?.refresh_token ??
						(result?.token as any)?.refreshToken ??
						''
					this.setCookie(UserSecretKey.accessToken, accessToken)
					this.setCookie(UserSecretKey.refreshToken, refreshToken)
					this.setCookie(UserSecretKey.userId, result?.user_id as number)
				}),
				map((result) => result?.token?.access_token ?? ''),
			)
		} else {
			// else do the cookie version of the same thing.
			// note that we don't return anything here since its a cookie unreadable by JS.
			return this.getNewCookieTokens(this.getUserId()).pipe(
				map((result) => undefined as any),
			)
		}
	}

	public getAccessToken() {
		return this.getCookie(UserSecretKey.accessToken)
	}

	public getRefreshToken() {
		return this.getCookie(UserSecretKey.refreshToken)
	}

	public getUserId() {
		try {
			const parsed = parseInt(this.getCookie(UserSecretKey.userId) ?? '')
			return isNaN(parsed) ? null : parsed
		} catch (e) {
			return null
		}
	}

	public storeItems(
		userId: number,
		accessToken?: string,
		refreshToken?: string,
	) {
		if (userId) this.setCookie(UserSecretKey.userId, userId.toString())
		if (accessToken) this.setCookie(UserSecretKey.accessToken, accessToken)
		if (refreshToken) this.setCookie(UserSecretKey.refreshToken, refreshToken)
	}

	private removeItems = () => {
		/*if (
			this.getLastUpdateTimestamp() !== 0 ||
			Date.now() - this.getLastUpdateTimestamp() < 1000 * 10
		) {
			return
		}*/
		for (const key of Object.keys(UserSecretKey)) {
			if (this.request && !this.env.isBrowser()) {
				this.request.res?.clearCookie(key)
			} else {
				this.cookieService.remove(key)
			}
		}
	}
	public invalidateSession$(invalidateServerSide = true): Observable<boolean> {
		this.tracking.setUserId(null as any)

		if (invalidateServerSide && !this.loggingOutAlready) {
			this.loggingOutAlready = true

			const userId = this.getUserId()
			if (!userId) {
				return of(true)
			}
			return this.disableCookiesBeforeALogout(
				this.getUserId(),
				this.getRefreshToken(),
			).pipe(
				take(1),
				switchMap(() => (this.removeItems(), of(true))),
				catchError(() => (this.removeItems(), of(true))),
			)
		} else {
			this.removeItems()
			return of(true)
		}
	}

	getAccessTokenByRefreshToken(
		refreshToken: string,
		userId: number | null,
	): Observable<TokenWrapper | null> {
		if (!isNaN(userId ?? NaN) && refreshToken?.length) {
			return this.http.get(
				`${
					this.url
				}/authentication/oauth/token?grant_type=${'refresh_token'}&client_id=${
					this.clientId
				}&user_id=${userId}&refresh_token=${
					refreshToken ?? this.getRefreshToken()
				}`,
			)
		} else {
			return of(null)
		}
	}

	getNewCookieTokens(userId: number | null): Observable<TokenWrapper | null> {
		if (!isNaN(userId ?? NaN)) {
			return this.http.get(
				`${
					this.url
				}/authentication/oauth/token/cookie?grant_type=${'refresh_token'}&client_id=${
					this.clientId
				}&user_id=${userId}`,
			)
		} else {
			return of(null)
		}
	}

	/**
	 * Returns a wrapped token as a result.
	 * Depending on the configured auth solution, either storage or cookies, it makes different requests.
	 * @param ticketId
	 * @returns
	 */
	getRefreshAndAccessTokensByTicket(
		ticketId: string,
	): Observable<TokenWrapper> {
		if (this.localStorageAuth)
			return this.getRefreshAndAccessTokensByTicketStorage(ticketId)
		else return this.getRefreshAndAccessTokensByTicketCookie(ticketId)
	}

	private getRefreshAndAccessTokensByTicketStorage(
		ticketId: string,
	): Observable<TokenWrapper> {
		return this.http.get(
			`${
				this.url
			}/authentication/oauth/token?grant_type=${'ticket'}&client_id=${
				this.clientId
			}&ticket_id=${ticketId}`,
		)
	}

	private getRefreshAndAccessTokensByTicketCookie(
		ticketId: string,
	): Observable<TokenWrapper> {
		return this.http
			.get<{ user_id: number }>(
				`${
					this.url
				}/authentication/oauth/token/cookie?grant_type=${'ticket'}&client_id=${
					this.clientId
				}&ticket_id=${ticketId}`,
				{
					withCredentials: true,
				},
			)
			.pipe(
				map((response) => {
					// create this object to conform to a standard return type between the two authentication modes.
					// returning the empty token object results in the auth service passing AT = undefined, RT = undefined to the login function.
					const wrapper: TokenWrapper = {
						user_id: response.user_id,
						token: {},
					}
					return wrapper
				}),
			)
	}

	getTokensBySSOToken(ssoToken: string): Observable<TokenWrapper> {
		return this.http.get(`${this.url}/authentication/tickets/${ssoToken}/token`)
	}

	disableCookiesBeforeALogout(
		userId: number | null,
		refreshToken: string | null = null,
	) {
		refreshToken = refreshToken || ''
		return this.http.post(`${this.url}/users/${userId}/logout`, {
			refreshToken: refreshToken,
			client_id: this.clientId,
		})
	}

	disableCookieAccessToken(userId: number | null) {
		return this.http.post(
			`${this.url}/users/${userId}/logout?invalidateAccessTokenOnly=true`,
			{
				client_id: this.clientId,
			},
		)
	}
}
