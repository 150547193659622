<div *ngIf="kbcTypeHeader" class="kbcTypeHeader">
	<nav class="kbcTypeNav">
		<a
			*ngFor="let header of headers"
			href="#"
			routerLink="/{{header.link}}"
			[ngClass]="{'hideMenuItem': header.isActive === false }"
			[class.nav--selected]="header.selected"
		>
			<span class="kbcMenuItem">
				<div
					*ngIf="kbcNavIcons"
					[inlineSVG]="'/assets/images/' + header.icon + '.svg' | imgSrc"
					[setSVGAttributes]="middleSvgAttributes"
					class=""
				></div>
				<p class="">{{header.title | translate}}</p>
			</span>
		</a>
	</nav>
</div>

<div
	class="nav-header-container mat-eliq-background my-theme"
	(window:resize)="headerResized($event)"
>
	<nav *ngIf="!kbcTypeHeader" class="navbar primary-p1">
		<span class="navbar-toggle" *ngIf="showMenuOptions">
			<div
				id="toggle"
				class="toggle-navbar-colors"
				(click)="OnMenuToggle()"
				[ngClass]="{'on': isMenuToggle === true }"
			>
				<span></span>
			</div>
		</span>
		<div
			class="header-menu-container-big"
			*ngIf="!headerStateService.isHamburger; else hamburgerBlock"
		>
			<div class="header-left-part">
				<eliq-company-logo
					[link]="showMenuOptions ? (companyLogoUrl$ | async) ?? '/' : false"
					[padding]="(companyLogoStyle$ | async)?.['padding'] ?? '1rem 1rem 1rem 2rem'"
					type="contrast"
					class="left"
				></eliq-company-logo>
			</div>

			<div class="header-middle-part">
				<ul
					*ngIf="showMenuOptions"
					class="main-nav centered"
					[ngClass]="{'active': isMenuToggle === true }"
					id="js-menu"
				>
					<a
						*ngFor="let header of headers"
						href="#"
						class="nav-links"
						routerLink="/{{header.link}}"
						[ngClass]="{'hideMenuItem': header.isActive === false }"
						[class.nav--selected]="header.selected"
					>
						<span class="menuItem">
							<div
								[inlineSVG]="'/assets/images/' + header.icon + '.svg' | imgSrc"
								[setSVGAttributes]="middleSvgAttributes"
								class="middle-icon-svg"
							></div>
							<p class="MenuText menu-text-big header-contrast">
								{{header.title | translate}}
							</p>
						</span>
					</a>
				</ul>
			</div>

			<div class="header-right-part">
				<ul
					*ngIf="showMenuOptions"
					class="profile-nav right"
					[ngClass]="{'activeProfile': isMenuToggle === true }"
				>
					<li
						*ngIf="showNotificationBell"
						class="notification notification-big"
						(click)="toggleNotifications()"
						[class.nav--selected]="headerStateService.notificationsSelected"
					>
						<div
							class="my-bell"
							[class.nav--selected]="headerStateService.notificationsSelected"
						>
							<eliq-notification-bell
								[count]="headerStateService.nNotifications"
								(showAllClicked)="showNotificationsClicked()"
								[isPopperDisabled]="isPopperDisabled"
							></eliq-notification-bell>
						</div>
					</li>
					<a
						*ngIf="showMenuOptions && showAccountLink"
						href="#"
						routerLink="/account"
						class="nav-links account-link"
						[class.nav--selected]="headerStateService.accountSelected"
					>
						<li class="profilePicture">
							<div class="account-container">
								<div
									[inlineSVG]="'/assets/images/menu_account.svg' | imgSrc"
									[setSVGAttributes]="middleSvgAttributes"
									class="middle-icon-svg"
								></div>
								<div class="account-and-name">
									<p class="account-account header-contrast" translate>
										settings_menu_options.my_account
									</p>
									<p
										class="account-name primary-p4 header-contrast"
										*ngIf="username"
									>
										{{username}}
									</p>
								</div>
							</div>
						</li>
					</a>
				</ul>
			</div>
		</div>
	</nav>

	<div *ngIf="showLocationSwitch()">
		<eliq-location-switch
			*ngIf="(locations?.length ?? 0) > 1"
			[currentLocation]="currentLocation"
			[locations]="locations"
			(newLocationSelected)="newLocationSelected($event)"
		></eliq-location-switch>
	</div>
</div>

<!-- When the header is in small mode this is what we do. This simplifies a lot of the css and makes the code easier to work with -->

<ng-template #hamburgerBlock>
	<div
		class="mobile-header header-left-part"
		[ngClass]="{'no-logo-if-mobile': (noLogoWhenHamburger$ | async) === true}"
	>
		<span class="spacer-left"></span>
		<eliq-company-logo
			*ngIf="(noLogoWhenHamburger$ | async) === false"
			[link]="showMenuOptions ? (companyLogoUrl$ | async) ?? '/' : false"
			padding="1rem"
			type="contrast"
			class="mobile-header-item"
		></eliq-company-logo>
		<span
			class="no-logo-page-title mobile-header-item"
			style="padding-left: 1rem; color: white;"
			*ngIf="(noLogoWhenHamburger$ | async) === true"
		>
			<div
				style="display: inline-block; padding-right: 0.5rem;"
				[inlineSVG]="'/assets/images/' + (selectedHeader.icon.startsWith('menu_') ? selectedHeader.icon : 'menu_'+selectedHeader.icon) + '.svg'"
				[setSVGAttributes]="{ 'style': 'width:auto; height:2.25rem;'}"
				[replaceContents]="true"
				[injectComponent]="false"
				class="centered"
				class="mobile-header-item"
			></div>
			{{selectedHeader.title | translate}}
		</span>
		<span class="spacer-right"></span>
	</div>

	<div *ngIf="showMenuOptions" class="header-list">
		<ul
			class="main-nav centered"
			[ngClass]="{'active': isMenuToggle === true }"
			id="js-menu"
		>
			<a
				*ngFor="let header of headers"
				href="#"
				class="nav-links"
				routerLink="/{{header.link}}"
				(click)="headerItemClicked()"
				[ngClass]="{'hideMenuItem': header.isActive === false }"
				[class.nav--selected]="header.selected"
			>
				<li class="menuItem">
					<div
						[inlineSVG]="'/assets/images/' + header.icon + '.svg' | imgSrc"
						[setSVGAttributes]="middleSvgAttributes"
						class="middle-icon-svg"
					></div>
					<p class="MenuText header-contrast">{{header.title | translate}}</p>
				</li>
			</a>
			<a
				*ngIf="showNotificationBell"
				routerLink="/notifications"
				class="nav-links"
				[class.nav--selected]="headerStateService.notificationsSelected"
			>
				<li class="menuItem notification" (click)="toggleNotifications()">
					<div
						class="my-bell"
						[class.nav--selected]="headerStateService.notificationsSelected"
					>
						<eliq-notification-bell
							[count]="headerStateService.nNotifications"
							[height]="30"
							[width]="30"
							class="my-bell"
							(showAllClicked)="showNotificationsClicked()"
							[isPopperDisabled]="true"
						></eliq-notification-bell>
					</div>

					<p class="MenuText header-contrast" translate>
						notifications_view.notifications
					</p>
				</li>
			</a>
		</ul>
	</div>

	<div class="header-right-part">
		<ul
			class="profile-nav right"
			[ngClass]="{'activeProfile': isMenuToggle === true }"
		>
			<span
				*ngIf="showAccountLink"
				(click)="accountArrowClick()"
				class="nav-links"
				[class.nav--selected]="headerStateService.accountSelected"
			>
				<li>
					<div class="account-container">
						<div
							[inlineSVG]="'/assets/images/menu_account.svg' | imgSrc"
							[setSVGAttributes]="middleSvgAttributes"
							class="middle-icon-svg"
						></div>
						<div class="account-and-name">
							<p class="account-account header-contrast" translate>
								settings_menu_options.my_account
							</p>
							<p
								class="account-name primary-p4 header-contrast"
								*ngIf="username"
							>
								{{username}}
							</p>
						</div>
						<div
							class="account-dropdown-arrow"
							*ngIf="headerStateService.isHamburger"
						>
							<div
								class="arrow"
								[ngClass]="accountExpanded ? 'up' : 'down'"
							></div>
						</div>
					</div>
				</li>
			</span>
			<div class="account-items-container" *ngIf="accountExpanded">
				<div *ngFor="let category of accountCategories">
					<div *ngFor="let item of category.items" class="account-item">
						<a
							*ngIf="!item.href"
							(click)="accountLinkClicked()"
							href="#"
							routerLink="/account"
							[queryParams]="{setting: item.id}"
							class="header-contrast"
						>
							{{item.name | translate}}
						</a>
						<a
							*ngIf="item.href"
							(click)="accountLinkClicked()"
							[href]="item.href"
							target="_blank"
							class="header-contrast"
						>
							{{item.name | translate}}
						</a>
					</div>
				</div>

				<div class="account-item logout-container">
					<eliq-link
						[leftIcon]="false"
						class="logout-link"
						label="settings_menu_options.logout"
						(click)="logoutClicked()"
					>
						<i
							[inlineSVG]="'/assets/images/Logout.svg' | imgSrc"
							aria-label="Log out"
							[setSVGAttributes]="{'height': '2em', 'class': 'logout-img'}"
							style="height: 2em; padding-right: 0.2rem;"
						></i>
					</eliq-link>
				</div>
			</div>
		</ul>
	</div>
</ng-template>
