// @ts-nocheck

import { Injectable } from '@angular/core'
import { PeriodType } from '../../public_api'
import { Period } from '../../public_api'
import { Fuel } from '../../models/Location.model'
import { Location } from '../../public_api'
import { DateHelper } from '@eliq/util'

@Injectable({
	providedIn: 'root',
})
export class HomePeriodService {
	private dateHelper: DateHelper

	constructor() {
		this.dateHelper = DateHelper.getInstance()
	}

	/**
	 * Returns the period and latestMonthWithDataPeriod needed for Client's home view
	 * @param fuels location fuels
	 * @param startDailyFromYesterday start daily insights from yesterday (true) or today (false)
	 * @param unit home chart using energy or cost
	 * @param periodType period type has enum values between second to year
	 */
	public createTheTwoPeriods(
		fuels: Fuel[],
		startDailyFromYesterday: boolean,
		unit: string,
		periodType: PeriodType,
	): Period[] {
		let period: Period
		let latestMonthWithDataPeriod: Period
		let mostRecentMonthWithData: Date
		if (fuels.length > 0) {
			mostRecentMonthWithData = this.dateHelper.getStartOfMonth(
				fuels[0].getLatestDataDateForUnit(unit, startDailyFromYesterday),
			)
		} else {
			mostRecentMonthWithData = new Date()
		}
		period = new Period(periodType, mostRecentMonthWithData)
		latestMonthWithDataPeriod = new Period(
			PeriodType.Month,
			mostRecentMonthWithData,
		)
		return [period, latestMonthWithDataPeriod]
	}

	/**
	 * Returns the period to date needed for Client's home view
	 * @param location Eliq core location model
	 */
	public getPeriodToDate(location: Location): Date {
		if (!location?.fuels?.length) {
			return undefined
		}

		let toDates = location.fuels
			.filter((fuel) => fuel?.consumption?.data_to)
			.map((fuel) => Date.parse(fuel.consumption.data_to))
			.filter((miliseconds) => !isNaN(miliseconds))
			.map((miliseconds) => new Date(miliseconds))

		// Sorting the dates from the earliest to the latest
		toDates = toDates.sort((date1, date2) => {
			if (date1 > date2) return 1
			if (date1 < date2) return -1
			return 0
		})

		// Taking the earliest date from all fuels 'data_to' fields
		if (toDates?.length) {
			return toDates[0]
		} else {
			return undefined
		}
	}
}
