<div class="container">
	<div class="left">
		<div
			[inlineSVG]="'/assets/images/pv_' + iconKey + '.svg' | imgSrc"
			[setSVGAttributes]="{'height': '24px', 'width': '24px', 'color': colorKey}"
			[replaceContents]="false"
		></div>
	</div>
	<div class="right">
		<p class="secondary-p2 value-header">
			{{'home_pv_card.' + titleKey | translate}}
		</p>
		<span
			class="value-number"
			*ngIf="value; else noValueBlock"
			[ngClass]="type"
		>
			<span class="primary-h3" style="margin: 0;">{{shownValue}}</span>
			<span class="primary-h6" style="margin: 0;">
				{{
					unit === 'energy'
						? 'kWh'
						: unit === 'cost'
							? eliqNumberPipe._getCurrencySymbol('narrow')
							: 'm³'
				}}
			</span>
		</span>
		<span class="diff-container" *ngIf="value && diff">
			<img
				[src]="'/assets/images/pv_arrow_right.svg' | imgSrc"
				height="15"
				class="arrow"
				[ngClass]="diff < 0 ? 'down' : 'up'"
			/>
			<span class="secondary-p3 percentage-text">{{shownPercentage}}%</span>
		</span>
	</div>
</div>

<ng-template #noValueBlock>
	<span class="primary-h6" style="margin: 0;">
		{{'home_pv_card.not_available' | translate}}
	</span>
</ng-template>
