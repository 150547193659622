import {
	Component,
	OnInit,
	EventEmitter,
	Output,
	Input,
	ChangeDetectorRef,
} from '@angular/core'
import { OnChanges } from '@angular/core'
import { PropertyNumber } from '@eliq/core'
import { TranslateModule } from '@ngx-translate/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { PropertyIconComponent } from '@eliq/ui/common/components/property-icon/property-icon.component'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NumberSpinnerCompactComponent } from '@eliq/ui'
@Component({
	selector: 'eliq-homedetails-amountof',
	templateUrl: './homedetails-amountof.component.html',
	styleUrls: ['./homedetails-amountof.component.css'],
	standalone: true,
	imports: [
		PropertyIconComponent,
		ImgSrcPipe,
		TranslateModule,
		InlineSVGModule,
		NumberSpinnerCompactComponent,
	],
})
export class HomedetailsAmountofComponent implements OnInit {
	constructor(private cdRef: ChangeDetectorRef) {}

	@Input() property: PropertyNumber

	@Output() valueChange = new EventEmitter<number | null>()

	public tempValue: number | null | undefined = undefined

	// status change is simply just here to imitate the usage of the other two homedetails modifiers, homesize
	// and radiobuttons. This one just immediately emits true, because we are always valid here as of right now.
	@Output() statusChange = new EventEmitter<boolean>()

	ngOnInit() {
		this.refresh()
	}

	refresh() {
		const value = this.property.getCurrentSelection()

		this.propertyValChange(value)
	}

	propertyValChange(value: number | null) {
		if (value === undefined) {
			value = null
		}
		this.cdRef.detectChanges()

		this.tempValue = value
		this.statusChange.emit(value !== null ? true : false)
		this.valueChange.emit(this.tempValue)
	}
}
