import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'
import { BasicButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-signing-magiclink-accountnr-help',
	templateUrl: './signing-magiclink-accountnr-help.component.html',
	styleUrls: ['./signing-magiclink-accountnr-help.component.scss'],
	standalone: true,
	imports: [BasicButtonComponent, TranslateModule],
})
export class SigningMagiclinkAccountnrHelpComponent implements OnInit {
	constructor(
		private dialogRef: MatDialogRef<SigningMagiclinkAccountnrHelpComponent>,
	) {}

	ngOnInit(): void {}

	okClicked() {
		this.dialogRef.close()
	}
}
