import { ModalService } from '@eliq/ui/modal'
import { PayModalComponent } from '../pay-modal/pay-modal.component'

import { Component, Input, OnInit } from '@angular/core'
import { APIAccountBalance } from '../../models/api-models/api-account-balance.model'
import { APITransaction } from '../../models/api-models/api-transaction.model'
import { TranslateModule } from '@ngx-translate/core'
import { SpinnerComponent } from '@eliq/ui'
import { FlexibleButtonComponent } from '@eliq/ui/common/components/buttons/flexible-button/flexible-button.component'
import { RouterLink } from '@angular/router'
import { TransactionItemComponent } from '../transaction-items/transaction-item/transaction-item.component'
import { MiniAccountBalanceComponent } from '../mini-account-balance/mini-account-balance.component'
import { NgIf } from '@angular/common'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'

@Component({
	selector: 'eliq-mini-transactions',
	templateUrl: './mini-transactions.component.html',
	styleUrls: ['./mini-transactions.component.scss'],
	standalone: true,
	imports: [
		CardComponent,
		NgIf,
		MiniAccountBalanceComponent,
		TransactionItemComponent,
		RouterLink,
		FlexibleButtonComponent,
		SpinnerComponent,
		TranslateModule,
	],
})
export class MiniTransactionsComponent implements OnInit {
	/**
	 * If account balance we show account balance and the latest transaction
	 * If its latest transcation we only show the latest transaction
	 */
	@Input() type: 'account-balance' | 'latest-transaction'
	@Input() billingAccountId: string
	@Input() transaction: APITransaction
	@Input() accountBalance: APIAccountBalance
	@Input() loaded: boolean
	@Input() options: any = {}

	constructor(private modal: ModalService) {}

	ngOnInit(): void {}

	showPayModal() {
		this.modal.openModal(PayModalComponent)
	}
}
