export * from './event-types'
export * from './event-names'
export * from './screen-names'

import { TrackingScreen } from './screen-names'

export function getAccountScreenNameFromSetting(setting: any) {
	// we route to homes by the location id
	if (!isNaN(setting)) {
		return TrackingScreen.AccountMenu
	}

	switch (setting) {
		case 'personal_details':
			return TrackingScreen.AccountPersonalDetails
		case 'my_agreements':
			return TrackingScreen.AccountTariff
		case 'language':
			return TrackingScreen.AccountLanguage
		case 'notifications':
			return TrackingScreen.AccountNotificationsSettings
		case 'consents':
			return TrackingScreen.AccountConsents
		case 'contact':
			return TrackingScreen.AccountContact
		default:
			return undefined
	}
}
