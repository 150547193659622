import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
	HttpParams,
	HttpResponse,
} from '@angular/common/http'
import { Injectable, Inject, PLATFORM_ID, inject } from '@angular/core'
import { Observable, of, throwError } from 'rxjs'
import { catchError, share, tap } from 'rxjs/operators'
import { EnvironmentService } from '../environment/environment.service'

export interface IRequestOptions {
	headers?: HttpHeaders
	observe?: 'body'
	params?: HttpParams
	reportProgress?: boolean
	responseType?: 'json'
	withCredentials?: boolean
	body?: any
}

export interface IRequestOptionsResponse {
	headers?: HttpHeaders
	observe?: 'response'
	params?: HttpParams
	reportProgress?: boolean
	responseType?: 'json'
	withCredentials?: boolean
	body?: any
}

export interface IBlobRequestOptions {
	headers?: HttpHeaders
	observe?: 'body'
	params?: HttpParams
	reportProgress?: boolean
	responseType: 'blob'
	withCredentials?: boolean
	body?: any
}

export function eliqApiHttpClientCreator(
	http: HttpClient,
	env: EnvironmentService,
) {
	return new EliqApiHttpClient(http, env)
}

@Injectable({
	providedIn: 'root',
})
export class EliqApiHttpClient {
	//private baseUrl: String = environment.eliqApiBaseUrl;
	private baseUrl!: string

	// a map of all ongoing GET requests, used to stop duplicate requests being made at once from the library
	// mainly being used for consumption requests
	private ongoingRequests = new Map<string, Observable<any>>()

	// Extending the HttpClient through the Angular DI.
	public constructor(public http: HttpClient, private env: EnvironmentService) {
		this.baseUrl = this.env.getBaseUrl()
		// If you don't want to use the extended versions in some cases you can access the public property and use the original one.
		// for ex. this.httpClient.http.get(...)
	}

	/**
	 * GET request
	 * @param endPoint it doesn't need / in front of the end point
	 * @param options options of the request like headers, body, etc.
	 * @returns
	 */
	public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
		if (this.ongoingRequests.get(endPoint)) {
			return this.ongoingRequests.get(endPoint)!
		} else {
			const observable: Observable<T> = this.http
				.get<T>(this.baseUrl + endPoint, options)
				.pipe(share())
			this.ongoingRequests.set(endPoint, observable)
			return observable.pipe(tap((_) => this.ongoingRequests.delete(endPoint)))
		}
	}

	/**
	 * GET Blob request
	 * @param endPoint it doesn't need / in front of the end point
	 * @param options options of the request like headers, body, etc.
	 * @returns An `Observable` of the response, with the response body as a `Blob`.
	 */
	public getBlob(
		endPoint: string,
		options?: IBlobRequestOptions,
	): Observable<Blob> {
		// Making sure the options have 'responseType' property
		const requestOptions = Object.assign(
			{},
			{ responseType: 'blob' } as IBlobRequestOptions,
			options,
		)

		if (this.ongoingRequests.get(endPoint)) {
			return this.ongoingRequests.get(endPoint)!
		} else {
			const observable: Observable<Blob> = this.http
				.get(this.baseUrl + endPoint, requestOptions)
				.pipe(share())
			this.ongoingRequests.set(endPoint, observable)
			return observable.pipe(tap((_) => this.ongoingRequests.delete(endPoint)))
		}
	}

	/**
	 * POST request
	 * @param endPoint end point of the api
	 * @param params body of the request.
	 * @param options options of the request like headers, body, etc.
	 * @returns
	 */
	public post<T>(
		endPoint: string,
		body: any,
		options?: IRequestOptions,
	): Observable<T> {
		return this.http
			.post<T>(this.baseUrl + endPoint, body, options)
			.pipe(catchError(this.handleError.bind(this)))
	}

	/**
	 * PUT request
	 * @param endPoint end point of the api
	 * @param params body of the request.
	 * @param options options of the request like headers, body, etc.
	 * @returns
	 */
	public put<T>(
		endPoint: string,
		body: any,
		options?: IRequestOptions,
	): Observable<T> {
		return this.http
			.put<T>(this.baseUrl + endPoint, body, options)
			.pipe(catchError(this.handleError.bind(this)))
	}
	/**
	 * DELETE request
	 * @param endPoint end point of the api
	 * @param options options of the request like headers, body, etc.
	 * @returns
	 */
	public delete<T>(
		endPoint: string,
		options?: IRequestOptions,
	): Observable<HttpResponse<T>> {
		return this.http
			.delete<T>(this.baseUrl + endPoint, { observe: 'response' })
			.pipe(catchError(this.handleError.bind(this)))
	}

	/**
	 * PATCH request
	 * @param endPoint end point of the api
	 * @param body body of request
	 * @param options options of the request like headers, body, etc.
	 * @returns
	 */
	public patch<T>(
		endPoint: string,
		body: any,
		options?: IRequestOptions,
	): Observable<T> {
		return this.http
			.patch<T>(this.baseUrl + endPoint, body, options)
			.pipe(catchError(this.handleError.bind(this)))
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message)
		}

		console.trace()
		console.error(error)
		return throwError(
			() => new Error('Something bad happened; please try again later.'),
		)
	}

	// TODO unused
	private Logout() {
		localStorage.clear()
		if (this.env.isBrowser()) {
			;(document as any).cookie =
				'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
			;(document as any).cookie =
				'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
		}
	}
}

export enum LoginStatus {
	Unauthorized = 401,
}
