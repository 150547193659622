export enum TrackingEventName {
	TopUp = 'top_up',
	AverageTemperatureButtonClicked = 'average_temperature_button_clicked',
	BudgetCreateBudgetStarted = 'budget_create_budget_started',
	BudgetCreateBudgetCancelled = 'budget_create_budget_cancelled',
	BudgetCreateBudgetCreated = 'budget_create_budget_created',
	BudgetEditBudgetStarted = 'budget_edit_budget_started',
	BudgetEditBudgetCancelled = 'budget_edit_budget_cancelled',
	BudgetEditBudgetDeleted = 'budget_edit_budget_deleted',
	BudgetEditBudgetEdited = 'budget_edit_budget_edited',
	BudgetLeftArrowClicked = 'budget_left_arrow_clicked',
	BudgetRightArrowClicked = 'budget_right_arrow_clicked',
	BudgetHomeCardBudgetClicked = 'budget_home_card_budget_clicked',
	ChatOpen = 'chat_open',
	ChatClose = 'chat_close',
	ContactUsClicked = 'contact_us_clicked',
	ChatButtonClicked = 'chat_button_clicked',
	DctHomeCardPeakDetailsClicked = 'dct_home_card_peak_details_clicked',
	DctHomeCardMonthlyInsightsClicked = 'dct_home_card_monthly_insights_clicked',
	DctInsightsInfoClicked = 'dct_insights_info_clicked',
	DctInsightsPeakDetailsClicked = 'dct_insights_peak_details_clicked',
	DctPeakDetailsLinkClicked = 'dct_peak_details_link_clicked',
	FaqButtonClicked = 'faq_button_clicked',
	ElectricityPriceButtonClicked = 'electricity_price_button_clicked',
	EmergencyPhoneNumberClicked = 'emergency_phone_number_clicked',
	EliqFailedLogin = 'eliq_failed_login',
	EliqFailedRequest = 'eliq_failed_request',
	EliqInfoCardOpen = 'eliq_info_card_open',
	MarketingPreferenceChange = 'marketing_preference_change',
	EliqNewTicket = 'eliq_new_ticket',
	EliqNotificationDismissed = 'eliq_notification_dismissed',
	EliqNotificationOpened = 'eliq_notification_opened',
	PaymentClicked = 'payment_clicked',
	RequestTicketGenerated = 'request_ticket_generated',
	OutageReported = 'outage_reported',
	ClaimTicketGenerated = 'claim_ticket_generated',
	LoginSignUpLinkOutButtonClicked = 'login_sign_up_link_out_button_clicked',
	EliqTicketResult = 'eliq_ticket_result',
	TouCardViewDetails = 'tou_card_view_details',
	ViewTransaction = 'view_transaction',
	InsightsUnitToggled = 'insights_unit_toggled',
	ViewNotification = 'view_notification',
	NotificationSettingChanged = 'notification_setting_changed',
	NotificationSettingSaved = 'notification_setting_saved',
	TariffDownloadContractClicked = 'tariff_download_contract_clicked',
	SwitchLocation = 'switch_location',
	WebTicketVerificationStarted = 'web_ticket_verification_started',
	WebTicketInvalid = 'web_ticket_invalid',
	WebTicketAlreadyUsed = 'web_ticket_already_used',
	WebTicketVerificationSuccess = 'web_ticket_verification_success',
	WebTicketVerificationExpired = 'web_ticket_verification_expired',
	WebTicketVerificationFailure = 'web_ticket_verification_failure',
	WebTicketStatusCheckFailure = 'web_ticket_status_check_failure',
	WebTipsViewNewTips = 'web_tips_view_new_tips',
	WebTipsViewNewTipsCancelled = 'web_tips_view_new_tips_cancelled',
	WebTipsViewNewTipsFinished = 'web_tips_view_new_tips_finished',
	WebTipsViewAllTips = 'web_tips_view_all_tips',
	WebTipsCategoryTodoClicked = 'web_tips_category_todo_clicked',
	WebTipsCategoryDoneClicked = 'web_tips_category_done_clicked',
	WebTipsCategoryDiscardedClicked = 'web_tips_category_discarded_clicked',
	WebTipsTipAddedToTodo = 'web_tips_tip_added_to_todo',
	WebTipsTipDone = 'web_tips_tip_done',
	WebTipsTipDiscarded = 'web_tips_tip_discarded',
	WebTipsTipReadMoreClicked = 'web_tips_tip_read_more_clicked',
	WebRenewalWebviewInitiated = 'web_renewal_webview_initiated',
	WebRenewalWebviewInitiatedSuccess = 'web_renewal_webview_initiated_success',
	WebRenewalWebviewInitiatedFailure = 'web_renewal_webview_initiated_failure',
	WebRenewalRenewalStarted = 'web_renewal_renewal_started',
	WebRenewalRenewalCancelled = 'web_renewal_renewal_cancelled',
	WebRenewalRenewalSigned = 'web_renewal_renewal_signed',
	WebRenewalRenewalSignedSuccess = 'web_renewal_renewal_signed_success',
	WebRenewalRenewalSignedFailure = 'web_renewal_renewal_signed_failure',
}
