import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	inject,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CardComponent, LinkComponent } from '@eliq/ui/common'
import { TranslateModule } from '@ngx-translate/core'
import { SimpleHomecardComponent } from '../simple-homecard/simple-homecard.component'
import {
	LocationConnectionStatus,
	EliqConnectService,
} from '@eliq/data-access/services/eliq-connect/eliq-connect.service'
import { CoreDataStoreService } from '@eliq/core'
import { Observable, Subject, map, of, switchMap, take, tap } from 'rxjs'

@Component({
	selector: 'eliq-connect-homecard',
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		SimpleHomecardComponent,
		LinkComponent,
	],
	templateUrl: './connect-homecard.component.html',
	styleUrls: ['./connect-homecard.component.scss'],
})
export class ConnectHomecardComponent implements OnInit, OnDestroy {
	@Output() showCard = new EventEmitter()

	public status: LocationConnectionStatus =
		LocationConnectionStatus.NOT_CONNECTED

	public showLink = true

	public eliqConnectService = inject(EliqConnectService)
	public coreDS = inject(CoreDataStoreService)

	getKeys = (status: LocationConnectionStatus) => {
		this.headerKey = `connect_meter.title_${status}`
		this.descriptionKey = `connect_meter.description_${status}`
		this.linkKey = `connect_meter.link_${status}`
	}

	public headerKey = 'connect_meter.title_not_connected'
	public descriptionKey = 'connect_meter.description_not_connected'
	public linkKey = 'connect_meter.link_not_connected'

	statusChanged(status: LocationConnectionStatus) {
		this.status = status
		this.getKeys(status)

		if (
			status === LocationConnectionStatus.AWAITING_CONFIRMATION ||
			status === LocationConnectionStatus.AWAITING_FIRST_DATA_SYNC
		) {
			this.showLink = false
		} else {
			this.showLink = true
		}

		if (status === LocationConnectionStatus.OK) {
			this.showCard.emit(false)
		} else {
			this.showCard.emit(true)
		}
	}
	public destroyed$ = new Subject<boolean>()

	ngOnDestroy() {
		this.destroyed$.next(true)
	}

	public linkUrl = '#'

	ngOnInit(): void {
		this.coreDS.user.subscribe((user) => {
			this.eliqConnectService
				.getWebUri(user.id)
				.subscribe((webUri) => (this.linkUrl = webUri ?? '#'))
		})
		this.coreDS.locations
			.pipe(
				switchMap((locations) => {
					if (locations.length > 0) {
						const location = locations[0]
						return this.eliqConnectService
							.pollLocationConnectionStatusUntilObs(
								location.id,
								0,
								10000,
								this.destroyed$,
							)
							.pipe(
								map((status) => {
									if (status) {
										this.statusChanged(
											status as unknown as LocationConnectionStatus,
										)
									}
									return this.status
								}),
							)
					}
					return of(LocationConnectionStatus.NOT_CONNECTED)
				}),
			)
			.subscribe()
	}

	linkClicked(e: Event): false {
		e.preventDefault()
		e.stopPropagation()

		this.eliqConnectService.openWebUri().subscribe()

		return false
	}
}
