<div class="container">
	<button class="left number-spinner-compact" (click)="decrement()">
		<img [src]="'/assets/images/minus.svg' | imgSrc" height="14" alt="-" />
	</button>
	<div
		class="middle-value"
		contenteditable
		(focus)="onFocusInput($event)"
		(blur)="onBlurInput($event)"
		(input)="inputEvent($event)"
		#inputEl
	>
		{{_value === null || _value === undefined ? '-' : _value}}
	</div>
	<button class="right number-spinner-compact" (click)="increment()">
		<img [src]="'/assets/images/plus.svg' | imgSrc" height="14" alt="+" />
	</button>
</div>
