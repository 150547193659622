<div><button
	class="btn"
	[ngStyle]="toggleButton.$selected ? buttonSelectedStyle : buttonStyle"
	(click)="onButtonClick()"
	[ngClass]="{'noBg': noBg, 'notification-toggle-button--selected selected' : toggleButton.$selected }"
>
	<span
		*ngIf="toggleButton.$color"
		class="dot"
		[style.background-color]="toggleButton.$color"
	></span>
	<p [class]="'label ' + fontClass">
		{{translatedName}}
	</p>
</button>
</div>
