import { Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core'

export interface EliqTranslatePipeArgs {
	fallbackKey?: string;
	fallbackString?: string;
	values?: { [key: string]: any };
}

/// TranslatePipe that has fallback support:
@Pipe({
	standalone: true,
	name: 'translateOrFallback',
})
export class TranslateOrFallbackPipe implements PipeTransform {
	constructor(private translate: TranslatePipe) {}

	transform(value: string, args: EliqTranslatePipeArgs) {
		const first_try = this.translate.transform(value, args.values);
		if (first_try === value) {
			if (args.fallbackKey) {
				return this.translate.transform(args.fallbackKey, args.values)
			} else if (args.fallbackString) {
				return args.fallbackString
			}
		}
		return first_try
	}
}
