import { Property } from './Property.model'
import { TranslateService } from '@ngx-translate/core'
import { APILimits, APIProperty } from '../api-models/api-home-profile.model'

export class PropertyNumber extends Property {
	private value: number
	private limits: APILimits

	constructor(apiProperty: APIProperty) {
		super(apiProperty)
		this.value = apiProperty.value as number
		this.limits = apiProperty.limits
	}

	public override savePlaceholder() {
		//if (this.$placeholder === undefined) return
		this.value = this.$placeholder
		this.$placeholder = undefined
	}

	public override getValue(): number {
		return this.value
	}

	public override getCurrentSelectionString(
		translator: TranslateService,
		maxChars?: number,
	) {
		const workingValue = this.$placeholder ? this.$placeholder : this.value
		if (workingValue === undefined) return undefined
		return workingValue.toString()
	}

	public override hasChanges(): boolean {
		return this.$placeholder !== this.value
	}

	public override getCurrentSelection(): number {
		// if (this.$placeholder === undefined) return this.value
		// else return this.$placeholder
		return !isNaN(parseInt(this.$placeholder))
			? this.$placeholder
			: this.$value ?? undefined
	}

	/**
	 * Getter $value
	 * @return {number}
	 */
	public get $value(): number {
		return this.value
	}

	/**
	 * Getter $limits
	 * @return {APILimits}
	 */
	public get $limits(): APILimits {
		return this.limits
	}

	public override hasValidSelection() {
		return !isNaN(parseInt(this.getCurrentSelection() + ''))
	}
}
