<div class="billitem-big-container">
	<p class="latest-text secondary-p2" translate>my_bills.latest_bill</p>
	<div class="my-flexbox">
		<div class="flexbox-leftpart">
			<h2 class="cost primary-h2">
				{{transaction.data.amount | eliqcurrency}}
			</h2>
			<p class="date secondary-p3">
				{{'my_bills.due' | translate | lowercase }}
				{{transaction.data.due_date | eliqdate:'short'}}
			</p>
			<span
				class="bigbill-status secondary-p3"
				[class.bigbill-status-paid]="transaction.data.status === 'paid'"
			>
				{{(transaction.data.status === 'paid' ? 'my_bills.paid' : 'my_bills.not_paid') | translate | lowercase}}
			</span>
		</div>
	</div>
	<span style="text-align: left; display: block;">
		<eliq-link
			*ngIf="transaction.is_downloadable && !downloadingTransaction"
			label="my_bills.download"
			(clicked)="downloadTransaction()"
		></eliq-link>
		<div style="height: 1em" *ngIf="downloadingTransaction"></div>
		<span style="position: relative; top: -5px; left: 12px;">
			<eliq-spinner
				[size]="'small'"
				*ngIf="downloadingTransaction"
			></eliq-spinner>
		</span>
	</span>
</div>
