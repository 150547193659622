import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { SnackbarService } from './services/snackbar.service'

@NgModule({
	declarations: [],
	imports: [CommonModule, MatSnackBarModule],
	exports: [MatSnackBarModule],
	providers: [SnackbarService],
})
export class SnackbarModule {}
