import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core'
import { Router } from '@angular/router'
import {
	ConfigInsightsFuelType,
	CoreDataStoreService,
	Fuel,
	FuelType,
	JsonGetterService,
	Location,
} from '@eliq/core'
import { ModalService } from '@eliq/ui/modal'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { APIBudget, areBudgetsEqual } from '../../models/api-budget.model'
import { BudgetApiService } from '../../services/budget-api.service'
import { BudgetTrackingService } from '../../tracking/budget-tracking.service'
import { BudgetCreateModalComponent } from '../budget-create-modal/budget-create-modal.component'
import { TrackingScreen } from '@eliq/core/tracking'
import { PeriodType } from '@eliq/core'
import { BudgetHomeCardComponent } from '../budget-home-card/budget-home-card.component'
import { NgIf } from '@angular/common'
import { forkJoin } from 'rxjs'

@Component({
	selector: 'eliq-budget-home-card-container',
	templateUrl: './budget-home-card-container.component.html',
	styleUrls: ['./budget-home-card-container.component.scss'],
	standalone: true,
	imports: [NgIf, BudgetHomeCardComponent],
})
export class BudgetHomeCardContainerComponent implements OnChanges {
	@Input() location: Location
	@Output() loading = new EventEmitter<boolean>()

	public defaultUnit = 'cost'
	public loaded = false

	public budgets: APIBudget[]
	public currentFuelBudgets: APIBudget[]
	public currentFuelEnabledBudgets: APIBudget[]

	private setupDone = false

	public currentFuel: Fuel // the currently selected fuel
	public possibleFuels: Fuel[] // the currently possible fuels
	public possibleFuelTypes: FuelType[]
	public fuelTypes: ConfigInsightsFuelType[]

	constructor(
		private coreDS: CoreDataStoreService,
		private budgetApi: BudgetApiService,
		private router: Router,
		private modal: ModalService,
		private tracking: BudgetTrackingService,
		private config: JsonGetterService,
	) {}

	ngOnInit() {
		this.setup()
		this.setupDone = true
	}

	ngOnChanges() {
		if (this.setupDone) {
			this.setup()
		}
	}

	private setup() {
		this.setLoaded(false)

		forkJoin([
			this.coreDS.getActiveLocation().pipe(take(1)),
			this.config.getInsightsConfig().pipe(take(1)),
		])
			.pipe(
				switchMap(([location, insightsConf]) => {
					return this.budgetApi
						.getUnitToUse(location)
						.pipe(tap((unit) => (this.defaultUnit = unit)))
						.pipe(
							map((unit) => {
								this.fuelTypes = insightsConf.fuelTypes
								this.possibleFuels = this.getPossibleFuels(this.location.fuels)
								this.possibleFuelTypes = this.possibleFuels.map(
									(fuel) => fuel.type,
								)
								this.currentFuel = this.possibleFuels[0]

								this.budgetApi
									.getBudgets(this.location.id, unit)
									.pipe(take(1))
									.subscribe({
										next: (res) => {
											this.budgets = res
											this.setCurrentFuelBudgets()
											this.setLoaded(true)
										},
										error: (err) => {
											this.setLoaded(true)
											throw err
										},
									})
							}),
						)
				}),
			)
			.subscribe()
	}

	budgetClicked(budget: APIBudget) {
		this.tracking.homeCardBudgetClicked(<PeriodType>budget.resolution)
		this.router.navigate(['/budget'], { queryParams: { fuel: budget.fuel } })
	}

	createBudgetClicked() {
		this.tracking.createBudgetStarted(TrackingScreen.Home)

		const dialogRef = this.modal.openModal(BudgetCreateModalComponent)
		const instance = <BudgetCreateModalComponent>dialogRef.componentInstance
		instance.budgets = this.currentFuelBudgets
		instance.locId = this.location.id

		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((res: APIBudget) => {
				if (res) {
					// we have created a new budget
					this.updateBudgets(res)
				}
			})
	}

	private updateBudgets(changedBudget: APIBudget) {
		this.budgets[
			this.budgets.findIndex((b) => areBudgetsEqual(changedBudget, b))
		] = changedBudget
		this.budgets = Object.assign([], this.budgets)
		this.setCurrentFuelBudgets()
	}

	private setLoaded(loaded: boolean) {
		// Checking if load status has changed to avoid emiting same status multiple times
		if (loaded !== this.loaded) {
			this.loaded = loaded
			this.loading.emit(!loaded)
		}
	}

	private setCurrentFuelBudgets() {
		this.currentFuelBudgets = this.budgets.filter(
			(b) => b.fuel === this.currentFuel.type,
		)
		this.currentFuelEnabledBudgets = this.currentFuelBudgets.filter(
			(b) => b.enabled,
		)
	}

	/**
	 * Checks a list of Fuels against Fuels from config's Insights section, and returns possible Fuels
	 * @param fuels The fuels for the location, presumably
	 * @returns a list of filtered fuels which are possible to use
	 */
	private getPossibleFuels(fuels: Fuel[]): Fuel[] {
		const possibleFuelTypes = this.fuelTypes
			.filter((cfgFuelType) => cfgFuelType.budgetEnabled !== false)
			.map((f) => f.fuelType)
		return fuels.filter((fuel) => possibleFuelTypes.includes(fuel.type))
	}

	fuelChanged(newFuelType: FuelType) {
		this.currentFuel = this.possibleFuels.find(
			(fuel) => fuel.type == newFuelType,
		) as Fuel
		this.setCurrentFuelBudgets()
	}
}
