<div class="container">
  <div class="x" (click)="closeClicked()">
    <eliq-x-button></eliq-x-button>
  </div>
  <div class="inner-max-w-container">
    <h6 class="header primary-h6">{{title}}</h6>
    <div class="content secondary-p2" mat-mdc-dialog-content [innerHTML]="text"></div>
  </div>


</div>
