// @ts-nocheck

import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core'
import { CoreDataStoreService } from '@eliq/core'
import { Observable, of, ReplaySubject, Subscription } from 'rxjs'
import { switchMap, take, tap } from 'rxjs/operators'
import {
	APITransaction,
	TransactionType,
} from '../../models/api-models/api-transaction.model'
import { BillingAccount2 } from '../../models/BillingAccount2.model'
import { BillingHandlerService } from '../../services/billing-handler.service'
import { TranslateModule } from '@ngx-translate/core'
import { SpinnerComponent } from '@eliq/ui'
import { PagerComponent } from '@eliq/ui/common/components/pager/pager.component'
import { TransactionsListComponent } from '../transactions-list/transactions-list.component'
import { AccountBalanceComponent } from '../account-balance/account-balance.component'
import { TransactionBigInvoiceComponent } from '../transaction-big-invoice/transaction-big-invoice.component'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-bills-container-two',
	templateUrl: './bills-container-two.component.html',
	styleUrls: ['./bills-container-two.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		CardComponent,
		TransactionBigInvoiceComponent,
		AccountBalanceComponent,
		TransactionsListComponent,
		PagerComponent,
		SpinnerComponent,
		TranslateModule,
	],
})
export class BillsContainerTwoComponent implements OnInit, OnDestroy {
	@Input() pagerOutside = false
	@Input() options: any = {}
	@Input() showHeading = true

	public loading = true

	public billingAccounts: BillingAccount2[]

	public transactionList: APITransaction[] = []
	public transactionsPerPage: number
	public transactionsShowed: APITransaction[]

	public currentPage = 1
	public nCurrentTotalPages: number

	public topElementType: 'latest-transaction' | 'account-balance'
	public latestTransaction: APITransaction

	private selectedBASubject: ReplaySubject<BillingAccount2> =
		new ReplaySubject()
	public selectedBAObservable: Observable<BillingAccount2> =
		this.selectedBASubject.asObservable()
	public currentBA: BillingAccount2

	private subs: Subscription[] = []

	constructor(
		private billsHandler: BillingHandlerService,
		private cds: CoreDataStoreService,
	) {}

	ngOnInit(): void {
		this.topElementType = this.options.topElement.type
		this.transactionsPerPage = this.options.billsPerPage

		this.cds.getActiveLocation().subscribe((location) => {
			const isAccountBalanceRequired =
				!this.options.isAccountBalanceNonApplicable
			this.billsHandler
				.getBillingAccountsForLocation(
					location.id,
					true,
					true,
					isAccountBalanceRequired,
				)
				.pipe(take(1))
				.subscribe((ba) => {
					this.billingAccounts = ba
					this.selectedBASubject.next(this.billingAccounts[0])
				})
		})

		const currBaSub = this.selectedBAObservable.subscribe((ba) => {
			this.currentBA = ba
			this.transactionList = ba.transactions
			if (this.topElementType === 'latest-transaction') {
				this.latestTransaction = this.getLatestTransactionOfType(
					ba.transactions,
					this.options.topElement.options.transactionTypePriorities,
				)
				if (this.latestTransaction) {
					// null check just in case
					this.transactionList = this.transactionList.filter(
						(t) => t.id !== this.latestTransaction.id,
					)
				}
			}

			this.newPageClicked(1)
		})

		this.subs.push(currBaSub)
	}

	private getLatestTransactionOfType(
		transactions: APITransaction[],
		priorities: TransactionType[],
	): APITransaction {
		let foundTransaction: APITransaction

		for (let i = 0; i < priorities.length; i++) {
			foundTransaction = transactions.find(
				(transaction) => transaction.type == priorities[i],
			)
			if (foundTransaction) {
				// we found something, lets exit the loop
				break
			}
		}

		return foundTransaction
	}

	newPageClicked(page: number) {
		this.currentPage = page
		this.transactionsShowed = this.transactionList.slice(
			this.transactionsPerPage * (page - 1),
			this.transactionsPerPage * page,
		)
		this.nCurrentTotalPages = Math.ceil(
			this.transactionList.length / this.transactionsPerPage,
		)
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe())
	}
}
