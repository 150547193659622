export class HeaderObject {
	link: string
	title: string
	icon: string
	selected: boolean
	isActive: boolean

	constructor(
		link: string,
		title: string,
		icon: string,
		selected?: boolean,
		isActive?: boolean,
	) {
		this.link = link
		this.title = title
		this.icon = icon
		if (selected) this.selected = selected
		else this.selected = false
		this.isActive = isActive ?? true
	}
}
