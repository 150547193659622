import { Inject, Optional, Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'imgSrc',
	standalone: true,
})
export class ImgSrcPipe implements PipeTransform {
	private baseUrl: string

	constructor(@Optional() @Inject('ASSETS_BASE_URL') baseUrl: string) {
		this.baseUrl = baseUrl
	}

	transform(value: string): string {
		return (this.baseUrl ?? '') + value
	}
}
