import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { OnChanges } from '@angular/core'
import { FuelType } from '@eliq/core'
import { TranslateModule } from '@ngx-translate/core'
import { NgFor } from '@angular/common'

@Component({
	selector: 'eliq-fuel-switch',
	templateUrl: './fuel-switch.component.html',
	styleUrls: ['./fuel-switch.component.css'],
	standalone: true,
	imports: [NgFor, TranslateModule],
})
export class FuelSwitchComponent implements OnChanges {
	@Input() selectedFuel!: FuelType
	@Input() fuels!: FuelType[]
	@Output() newFuelSelected = new EventEmitter<FuelType>()

	ngOnChanges() {
		if (!this.selectedFuel) {
			this.selectedFuel = this.fuels[0]
		}
	}

	onFuelSelect(fuelType: FuelType) {
		this.newFuelSelected.emit(fuelType)
	}
}
