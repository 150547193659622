<div class="footer footer-background secondary-p1">
	<div class="footercontainer">
		<p class="footertext" *ngIf="!!footerText">
			{{footerText}}
		</p>

		<div style="display: flex;" *ngIf="!!footerLink && !!footerLinkText">
			<eliq-link class="secondary-p2 footerLink" [href]="footerLink" [target]="'_blank'" [colorKey]="'footer-contrast'">
				{{footerLinkText}}
			</eliq-link>
		</div>
		<div style="display: flex;" *ngIf="!!policyLink">
			<eliq-link
				class="secondary-p2 footerLink"
				[href]="policyLink"
				[target]="'_blank'"
				[colorKey]="'footer-contrast'"
			>
				Privacy Policy
		</eliq-link>
		</div>
	</div>
</div>
