import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { NgIf } from '@angular/common'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'

@Component({
	selector: 'eliq-signin-card-two',
	templateUrl: './signin-card-two.component.html',
	styleUrls: ['./signin-card-two.component.scss'],
	standalone: true,
	imports: [CardComponent, NgIf, ImgSrcPipe, TranslateModule],
})
export class SigninCardTwoComponent implements OnInit {
	@Input() padding = '50px 16px'
	@Input() showBackButton: boolean
	@Input() header: string
	@Output() backClicked = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}
}
