<div [ngClass]="{'container': true, 'mini-transaction': isMini}">
	<div class="container-left-mid">
		<div class="img">
			<img
				[src]="'/assets/images/transaction_' + transaction.data.key + '.svg' | imgSrc"
				height="30"
			/>
		</div>
		<div class="mid">
			<div class="header secondary-p1">
				<span>{{'transaction_type.' + transaction.data.key | translate}}</span>
			</div>

			<div
				class="secondary-p3"
				style="margin-bottom: 5px;"
				*ngIf="transaction.data.due_date"
			>
				{{"my_bills.expires" | translate}}
				{{ transformDate(transaction.data.due_date) }}
			</div>

			<div
				class="secondary-p3"
				style="margin-bottom: 5px;"
				*ngIf="!transaction.data.due_date"
			>
				{{transformDate(transaction.created_date) }}
			</div>

			<div
				class="secondary-p3 billitem-status"
				*ngIf="transaction.data.status"
				[ngClass]="transaction.data.status === 'paid' ? 'billitem-status-paid' : 'billitem-status-notpaid'"
			>
				{{(transaction.data.status === 'paid' ? 'my_bills.paid' : isMini ? '' : 'my_bills.not_paid') | translate | lowercase}}
				<eliq-basic-button
					(clicked)="showPayModal()"
					[label]="'my_bills.pay'"
					*ngIf="transaction.data.key === 'invoice' && options.showPayButton && transaction.data.status !== 'paid'"
					class="pay-button"
				></eliq-basic-button>
			</div>
		</div>
	</div>

	<div class="right">
		<h5 class="amount primary-h5">
			{{transaction.data.amount | eliqcurrency}}
		</h5>
		<div class="button-container" *ngIf="transaction.is_downloadable">
			<eliq-link
				style="display: inline-block;"
				*ngIf="!downloadingTransaction"
				label="my_bills.download"
				(clicked)="downloadTransaction()"
			></eliq-link>
			<div class="button-container" style="position: relative;">
				<eliq-spinner
					[size]="'small'"
					*ngIf="downloadingTransaction"
				></eliq-spinner>
			</div>
		</div>
	</div>
</div>
