// @ts-nocheck

import { Component, OnInit, Input, OnChanges } from '@angular/core'
import { ConsumptionHelperService } from '@eliq/core'
import { EliqNumberPipe } from '@eliq/core'
import { TranslateModule } from '@ngx-translate/core'
import { NgIf, NgFor } from '@angular/common'

class OutputObject {
	constructor(
		public name: string,
		public leftValue: string,
		public rightValue: string,
	) {}
}

@Component({
	selector: 'eliq-costs-popper',
	templateUrl: './costs-popper.component.html',
	styleUrls: ['./costs-popper.component.css'],
	standalone: true,
	imports: [NgIf, NgFor, TranslateModule],
})
export class CostsPopperComponent implements OnInit, OnChanges {
	@Input() cost: Map<string, number[]>
	@Input() energy: Map<string, number[]>
	@Input() isCostHidden: boolean

	outputs: OutputObject[] = []

	constructor(
		private consHelper: ConsumptionHelperService,
		private eliqNumberPipe: EliqNumberPipe,
	) {}

	ngOnInit() {
		this.setup()
	}

	ngOnChanges() {
		this.setup()
	}

	private setup() {
		this.outputs = []
		// NOTE!! This component should work if only one of the cost, energy inputs are existing!!
		const mapToUse = this.energy ? this.energy : this.cost
		if (!mapToUse) return

		mapToUse.forEach((arr, key) => {
			const values = []

			if (this.cost) {
				values.push(
					this.eliqNumberPipe.transform(
						this.consHelper.summarizeArray(this.cost.get(key)),
						'cost',
					),
				)
			}

			if (this.energy) {
				values.push(
					this.eliqNumberPipe.transform(
						this.consHelper.summarizeArray(this.energy.get(key)) / 1000,
						'energy',
					),
				)
			}

			while (values.length < 2) values.push(undefined)

			this.outputs.push(new OutputObject(key, values[0], values[1]))
		})
	}
}
