import { PeriodType } from './PeriodType.model'
export enum ResolutionType {
	TenSec = '10sec',
	SixMin = '6min',
	ThirtyMin = '30min',
	Hour = 'hour',
	Day = 'day',
	Month = 'month',
}

export function getResolutionTypeFromLogFreq(logFreq: number): ResolutionType {
	switch (logFreq) {
		case 360:
			return ResolutionType.SixMin
		case 1800:
			return ResolutionType.ThirtyMin
		case 3600:
			return ResolutionType.Hour
		case 86400:
			return ResolutionType.Day
		default:
			return ResolutionType.Month
	}
}

/** The most likely scenario is the first index of the returned array */
export const possiblePeriodForResolution = (
	resolutionType: ResolutionType,
): PeriodType[] => {
	switch (resolutionType) {
		case ResolutionType.Month:
			return [PeriodType.Year]
		case ResolutionType.Day:
			return [PeriodType.Month, PeriodType.Week]
		case ResolutionType.ThirtyMin:
			return [PeriodType.Day]
		case ResolutionType.Hour:
			return [PeriodType.Day]
		case ResolutionType.SixMin:
			return [PeriodType.Hour]
		case ResolutionType.TenSec:
			return [PeriodType.Minute]
	}
}

/**
 * If a is smaller than b, returns -1.
 * If a is equal to b, returns 0.
 * If a is larger than b, returns 1.
 * "Small" is a high resolution. SixMin is the smallest/highest resolution.
 * "Big" means low resolution. Month is the biggest/lowest resolution.
 * @param a
 * @param b
 */
export const resolutionSorter = (a: any, b: any) => {
	const map = {} as any
	map[ResolutionType.SixMin] = 1
	map[ResolutionType.ThirtyMin] = 2
	map[ResolutionType.Hour] = 3
	map[ResolutionType.Day] = 4
	map[ResolutionType.Month] = 5

	if (map[a] < map[b]) {
		return -1
	}

	if (map[a] > map[b]) {
		return 1
	}
	return 0
}
