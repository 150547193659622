import {
	Component,
	Inject,
	OnInit,
	Optional,
	PLATFORM_ID,
	//ViewEncapsulation,
} from '@angular/core'
import { CommonModule, isPlatformServer } from '@angular/common'
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens'
import { Request, Response } from 'express'

@Component({
	selector: 'eliq-404',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './404.component.html',
	styleUrls: ['./404.component.scss'],
})
export class NotFoundComponent implements OnInit {
	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		@Optional() @Inject(RESPONSE) private response: Response,
		@Optional() @Inject(REQUEST) private request: Request,
	) {}

	ngOnInit() {
		if (isPlatformServer(this.platformId)) {
			//
			if (this.request?.res) {
				this.request.res.status(404)
			}
		}
	}
}
