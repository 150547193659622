import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { EliqNumberPipe } from '@eliq/core'

import { AdviceStatus, APIAdvice } from '../../models/api-advice.model'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { BasicButtonComponent, LinkComponent } from '@eliq/ui'
import { NgIf, NgStyle } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { InlineSVGModule } from 'ng-inline-svg-2'

@Component({
	selector: 'eliq-tip-modal',
	templateUrl: './tip-modal.component.html',
	styleUrls: ['./tip-modal.component.scss'],
	standalone: true,
	imports: [
		TranslateModule,
		NgIf,
		BasicButtonComponent,
		InlineSVGModule,
		LinkComponent,
		NgStyle,
		ImgSrcPipe,
	],
})
export class TipModalComponent implements OnInit {
	@Input() advice: APIAdvice
	@Input() enableDiscardTab = false
	@Output() moveTo = new EventEmitter<string>()

	public showMarkAsDoneButton: boolean
	public showAddToTodoButton: boolean
	public showDiscardButton: boolean

	constructor(public numberPipe: EliqNumberPipe) {}

	ngOnInit(): void {
		this.setupButtons()
	}

	markAsDoneClicked() {
		this.moveTo.emit('category_done')
	}

	discardClicked() {
		this.moveTo.emit('category_discarded')
	}

	moveToTodoClicked() {
		this.moveTo.emit('category_todo')
	}

	openUrl(url: string) {
		window?.location?.href && (window.location.href = url)
	}

	linkClicked(event: Event) {
		event.preventDefault()
		if (window && this.advice?.link?.url && window.parent) {
			if (screen.width < 900) {
				window.open(this.advice.link.url, '_top')
			} else {
				window.open(this.advice.link.url, '_blank')
			}
		}
		return false
	}

	private setupButtons() {
		if (this.advice.status === AdviceStatus.None) {
			this.showMarkAsDoneButton = true
			this.showAddToTodoButton = false
			this.showDiscardButton = true
		} else if (this.advice.status === AdviceStatus.SaveForLater) {
			this.showMarkAsDoneButton = true
			this.showAddToTodoButton = false
			this.showDiscardButton = true
		} else if (this.advice.status === AdviceStatus.Implemented) {
			this.showMarkAsDoneButton = false
			this.showAddToTodoButton = true
			this.showDiscardButton = true
		} else if (this.advice.status === AdviceStatus.NotRelevant) {
			this.showMarkAsDoneButton = false
			this.showAddToTodoButton = true
			this.showDiscardButton = false
		}

		// Override settings if the parent setting is disabled
		if (this.enableDiscardTab == false) this.showDiscardButton = false
	}
}
