import { DatePipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'
import { Router } from '@angular/router'
import {
	EliqTrackingService,
	TrackingEventName,
	ViewNotificationEvent,
	TrackingScreen,
} from '@eliq/core/tracking'
import { TranslateModule } from '@ngx-translate/core'
import { LinkComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-notification-item-abnormal',
	templateUrl: './notification-item-abnormal.component.html',
	styleUrls: ['./notification-item-abnormal.component.scss'],
	standalone: true,
	imports: [LinkComponent, TranslateModule],
})
export class NotificationItemAbnormalComponent implements OnInit {
	@Input() notification: NotificationData

	public dayToLinkTo = ''
	public todayDate: Date

	constructor(
		private router: Router,
		private tracking: EliqTrackingService,
		private datePipe: DatePipe,
	) {}

	ngOnInit(): void {
		this.todayDate = this.notification.$createdDate
		this.dayToLinkTo =
			this.datePipe.transform(this.todayDate, 'yyyy-MM-ddTHH:mm:ss') ?? ''
	}

	// TODO NOTE here.
	// Should we not use EliqNotificationOpenedEvent on link click maybe? Or something else? I'm not 100% sure
	// but I knew more back when I coded this about the nitty gritty of tracking and how we do it in the apps.
	// So it's more likely that I change it to something wrong than fix it, for now.
	linkClicked(href): void {
		const eventData: ViewNotificationEvent = {
			v: 2,
			from: TrackingScreen.Notifications,
			header: this.notification.$header,
			eventType: this.notification.$eventType,
		}

		this.tracking.logEvent(TrackingEventName.ViewNotification, eventData)

		this.router.navigate([href], {
			queryParams: {
				period: 'month',
				date: encodeURIComponent(this.dayToLinkTo),
			},
		})
	}
}
