import { Component, OnInit, Input } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import {
	HomeProfile,
	LocationHttpService,
	Property,
	PropertyGroup,
} from '@eliq/core'
import { ModalService } from '@eliq/ui/modal'
import { EnvironmentService } from '@eliq/data-access'
import { BasicButtonComponent } from '@eliq/ui'
import { HomedetailsAppliancesComponent } from '../homedetails-appliances/homedetails-appliances.component'
import { HomedetailsAmountofComponent } from '../homedetails-amountof/homedetails-amountof.component'
import { HomedetailsHomesizeComponent } from '../homedetails-homesize/homedetails-homesize.component'
import { HomedetailsMultipleSelectComponent } from '../homedetails-multiple-select/homedetails-multiple-select.component'
import { HomedetailsRadiobuttonsComponent } from '../homedetails-radiobuttons/homedetails-radiobuttons.component'
import { NgIf } from '@angular/common'
import { XButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-homedetails-modal-container',
	templateUrl: './homedetails-modal-container.component.html',
	styleUrls: ['./homedetails-modal-container.component.css'],
	standalone: true,
	imports: [
		XButtonComponent,
		NgIf,
		HomedetailsRadiobuttonsComponent,
		HomedetailsMultipleSelectComponent,
		HomedetailsHomesizeComponent,
		HomedetailsAmountofComponent,
		HomedetailsAppliancesComponent,
		BasicButtonComponent,
	],
})
export class HomedetailsModalContainerComponent implements OnInit {
	// we'll either be handling a property, or a list of appliances.
	@Input() property: Property
	@Input() propertyGroup: PropertyGroup
	@Input() locId: number

	private prevPropertyPlaceholder: any
	private prevPropertyGroupPlaceholders: number[]

	// the form set up will always initially be valid.
	public currentlyValid = true

	public header = ''
	public saveLoading = false

	constructor(
		private env: EnvironmentService,
		public dialogRef: MatDialogRef<HomedetailsModalContainerComponent>,
		public translator: TranslateService,
		private locHttp: LocationHttpService,
		private modal: ModalService,
	) {}

	ngOnInit() {
		// when the user clicks on backdrop, run afterclose method.
		this.dialogRef.backdropClick().subscribe(() => {
			this.cancelDialog()
		})

		if (this.property) {
			this.prevPropertyPlaceholder = this.property.$placeholder
			this.header = this.translator.instant(
				`home_profile_options.${this.property.$key}`,
			)
		}

		if (this.propertyGroup) {
			this.prevPropertyGroupPlaceholders = this.propertyGroup.$properties.map(
				(prop) => prop.$placeholder,
			)
			this.header = this.translator.instant(
				`home_profile_options.${this.propertyGroup.$type}`,
			)
		}
	}

	shouldNumberPropHaveSlider(property: Property) {
		// just match against different keys such
		const sliderKeys = ['living_area', 'yearlyenergy']

		return sliderKeys.some((key) => {
			return key === property.$key
		})
	}

	saveClick() {
		const usedProp = this.property ? this.property : this.propertyGroup
		if (!this.propHasChange(usedProp)) {
			this.cancelDialog()
		} else {
			this.saveLoading = true

			this.locHttp
				.patchProperties(
					this.locId,
					this.property ? [this.property] : [],
					this.propertyGroup ? [this.propertyGroup] : undefined,
				)
				.subscribe(
					(res) => {
						this.saveLoading = false
						if (res === null) {
							this.dialogRef.close(null)
						} else {
							this.dialogRef.close(new HomeProfile(this.env, res))
						}
					},
					(err) => {
						this.modal.openErrorModal()
						this.saveLoading = false
					},
				)
		}
	}

	cancelDialog() {
		// set the placeholder values back to what they were
		if (this.property) {
			this.property.$placeholder = this.prevPropertyPlaceholder
		}

		if (this.propertyGroup) {
			this.propertyGroup.$properties.forEach((prop, i) => {
				prop.$placeholder = this.prevPropertyGroupPlaceholders[i]
			})
		}

		this.dialogRef.close()
	}

	currentValidityChange(valid: boolean) {
		this.currentlyValid = valid
	}

	getCurrentlyValid(): boolean {
		return this.currentlyValid
	}

	setNewValue(event) {
		this.property.$placeholder = event
	}

	private propHasChange(prop: Property | PropertyGroup): boolean {
		if (prop instanceof Property) {
			return prop.hasChanges()
		} else {
			//propgroup
			return prop.hasChange()
		}
	}
}
