import { Property } from './Property.model'
import { PropertyNumber } from './PropertyNumber.model'
import { PropertySingleSelect } from './PropertySingleSelect.model'
import { PropertyMultipleSelect } from './PropertyMultipleSelect.model'
import { PropertyGroup } from './PropertyGroup.model'
import {
	APIYearlyConsumption,
	APIHomeProfile,
	APIProperty,
	APIYearlyConsumptionToProperty,
} from '../api-models/api-home-profile.model'
import { EnvironmentService } from '@eliq/data-access'

export class HomeProfile {
	private properties: Property[]
	private propertyGroups: PropertyGroup[]

	constructor(env: EnvironmentService, apiProfile: APIHomeProfile) {
		this.properties = this.createProperties(apiProfile.properties)
		this.propertyGroups = []

		if (apiProfile.appliances)
			this.propertyGroups.push(
				new PropertyGroup('appliances', apiProfile.appliances),
			)
		if (apiProfile.cooking)
			this.propertyGroups.push(new PropertyGroup('cooking', apiProfile.cooking))
		if (apiProfile.fridges_and_freezers)
			this.propertyGroups.push(
				new PropertyGroup(
					'fridges_and_freezers',
					apiProfile.fridges_and_freezers,
				),
			)
		if (apiProfile.cooling)
			this.propertyGroups.push(new PropertyGroup('cooling', apiProfile.cooling))
		if (apiProfile.saunas_and_hot_tubs)
			this.propertyGroups.push(
				new PropertyGroup(
					'saunas_and_hot_tubs',
					apiProfile.saunas_and_hot_tubs,
				),
			)
		if (apiProfile.extras)
			this.propertyGroups.push(new PropertyGroup('extras', apiProfile.extras))
	}

	private createProperties(properties: APIProperty[]): Property[] {
		return properties?.map((prop) => {
			if (prop.data_type === 'number') {
				return new PropertyNumber(prop)
			} else if (prop.data_type === 'single_select') {
				return new PropertySingleSelect(prop)
			} else if (prop.data_type === 'multiple_select') {
				return new PropertyMultipleSelect(prop)
			} else {
				return undefined as any
			}
		}) ?? []
	}

	/**
	 * Getter $properties
	 * @return
	 */
	public get $properties(): Property[] {
		return this.properties
	}

	/**
	 * Getter $propertyGroups
	 * @return
	 */
	public get $propertyGroups(): PropertyGroup[] {
		return this.propertyGroups
	}
}
