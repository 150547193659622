<div class="container">
	<h6
		class="primary-h6"
		*ngIf="header"
		style="text-align: center; margin: 0;"
		translate
	>
		{{header}}
	</h6>
	<p
		class="secondary-p1"
		*ngIf="question"
		style="text-align: center;"
		translate
	>
		{{question}}
	</p>
	<div class="button-container">
		<eliq-basic-button
			class="button1"
			[label]="optionYes"
			(clicked)="confirm()"
			[useEntireWidth]="true"
		></eliq-basic-button>
		<eliq-basic-button
			[label]="optionNo"
			[variant]="'secondary'"
			(clicked)="cancel()"
			[useEntireWidth]="true"
		></eliq-basic-button>
	</div>
</div>
