import { Inject, Injectable, Optional } from '@angular/core'
import {
	AuthService,
	LoginService,
	TicketService,
	TicketStatusType,
	MagicLinkLoginVM,
} from '../../../../../auth/src'
import { EliqApiHttpClient } from '@eliq/data-access'
import { ModalService } from '@eliq/ui/modal'
import { TranslateService } from '@ngx-translate/core'
import {
	interval,
	Observable,
	of,
	ReplaySubject,
	Subscription,
	timer,
} from 'rxjs'
import {
	catchError,
	map,
	switchMap,
	take,
	takeWhile,
	tap,
} from 'rxjs/operators'
import { LanguageService, UserHttpService } from '@eliq/core'

@Injectable({
	providedIn: 'root',
})
export class LoginHelperService {
	private url = '/v3'
	private clientId = ''
	private localStorageAuth: boolean

	//private checkingTicket = false

	//private tokenPollerSubject: ReplaySubject<TicketStatusType> =
	//	new ReplaySubject()

	constructor(
		private http: EliqApiHttpClient,
		@Inject('clientId') clientId: string,
		@Optional() @Inject('LOCAL_STORAGE_AUTH') localStorageAuth: boolean,
		private ticketService: TicketService,
		private modal: ModalService,
		private translator: TranslateService,
		private loginService: LoginService,
		private languageService: LanguageService,
		private userHttpService: UserHttpService,
		private authService: AuthService,
	) {
		this.clientId = clientId
		this.localStorageAuth = localStorageAuth || false
	}

	/**
	 * Gets a ticket id which can be verified by the user. When this returns, an email should have been sent to the user.
	 * This email contains a link which needs to be clicked. The ticket then becomes verified, and then the
	 * frontend can fetch an access token using the verified ticket.
	 * @param userReference the email the user inputs
	 * @param type default email (only supported)
	 */
	getMagiclinkTicket(email: string) {
		const body = {
			client_id: this.clientId,
			type: 'email',
			user_reference: email,
			custom: {},
		}

		const lang = this.languageService.getCurrentLanguage()
		if (lang) {
			body.custom = {
				pref_language: lang,
			}
		}

		return this.http.post<MagicLinkLoginVM>(
			`${this.url}/authentication/login/magiclink`,
			body,
		)
	}

	getMagiclinkTicketEmailAccountnr(email: string, accountNumber: string) {
		return this.http.post<MagicLinkLoginVM>(
			`${this.url}/authentication/login/magiclink`,
			{
				client_id: this.clientId,
				type: 'custom',
				custom: {
					customer_number: accountNumber,
					email: email,
				},
			},
		)
	}

	startTicketVerificationAndLogin(ticketId: string) {
		let ticketStatus = TicketStatusType.Initiated

		return timer(0, 3000).pipe(
			takeWhile(() => ticketStatus !== TicketStatusType.Completed),
			switchMap(() => {
				if (this.authService.isLoggedIn()) {
					ticketStatus = TicketStatusType.Completed
					return of(TicketStatusType.Completed)
				}
				return this.ticketService.getTicketStatus(ticketId)
			}),
			switchMap((ticket) => {
				/*if (ticket === TicketStatusType.Completed) {
					return of(TicketStatusType.Completed)
				}*/
				if (typeof ticket === 'string') {
					return of(TicketStatusType.Error)
				}

				ticketStatus = ticket.status
				if (ticket.status === TicketStatusType.Completed)
					return this.getTokensAndLogin(ticketId)
				else return of(ticketStatus)
			}),
		)
	}

	public getTokensAndLogin(ticketId: string) {
		return this.authService.getRefreshAndAccessTokensByTicket(ticketId).pipe(
			tap((tokenWrapper) => {
				const userId = tokenWrapper?.user_id ?? 0
				const accessToken = tokenWrapper?.token?.access_token ?? ''

				this.authService.login(
					userId,
					accessToken,
					tokenWrapper?.token?.refresh_token ?? '',
				)

				//Syncing web local language and user config language after login.
				const currentLang = this.languageService.getCurrentLanguage()
				this.userHttpService
					.patchLanguageCode(userId, currentLang, accessToken)
					.subscribe(
						(res) => {},
						(error) => {
							console.error(
								'Error when trying to patch language code via user service',
								error,
							)
						},
					)
			}),
			map(() => TicketStatusType.Completed),
		)
	}

	public handleLoginError(error: any) {
		console.error('handleLoginError')
		console.error(error)
		console.trace()
		const errorMsg = this.translator.instant('error.user_not_found_only')
		this.modal.openErrorModal(errorMsg)
	}
}
