import { Component, Input, OnInit } from '@angular/core'
import { SmrMeter } from '@eliq/feature/smr-card/models/SmrMeter.model'
import { EliqDatePipe, ImgSrcPipe } from '@eliq/core'
import { NgFor, NgForOf, NgIf } from '@angular/common'

@Component({
	selector: 'eliq-smr-view-history-modal',
	templateUrl: './view-history-modal.component.html',
	styleUrls: ['./view-history-modal.component.css'],
	standalone: true,
	imports: [NgIf, NgFor, NgForOf, EliqDatePipe, ImgSrcPipe],
})
export class ViewHistoryModalComponent implements OnInit {
	@Input() meterRead: any
	@Input() meter: SmrMeter

	submittedDate: Date
	meterType: string

	ngOnInit(): void {
		this.submittedDate = new Date(this.meterRead.date)
		this.meterType = this.meter?.type
	}
}
