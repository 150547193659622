import { ResolutionType } from '../ResolutionType.model'
import { APILocationAddress } from './api-location-address.model'

export class APILocation {
	id: number
	name: string
	timezone: string
	address: APILocationAddress
	currency_code: string
	fuels: {
		elec?: APIFuel
		district_heating?: APIFuel
		gas?: APIFuel
	}
	pv_systems: APIPVSystem[]
}

export class APIFuel {
	import?: APIDataStream
	export?: APIDataStream
	consumption?: APIDataStream
	production?: APIDataStream
}

export function IsValidFuel(fuel: APIFuel): boolean {
	return (
		(fuel?.import?.type?.length ?? 0) > 0 ||
		(fuel?.export?.type?.length ?? 0) > 0 ||
		(fuel?.consumption?.type?.length ?? 0) > 0 ||
		(fuel?.production?.type?.length ?? 0) > 0
	)
}

export class APIDataStream {
	type: string
	data_from: string
	data_to: string
	resolution: ResolutionType
	units: string[]
	source?: string
}

export class APIPVSystem {
	id: string
	created_date: string
	data: any // ?
	data_from: string
	data_to: string
	installation_date: string
	installation_size_kwp: number
	model: string
	resolution: ResolutionType
}
