import { Component, Input, OnInit } from '@angular/core'
import { ConfigLoginType } from '@eliq/feature/login/models/config-login-type.model'

@Component({
	selector: 'eliq-signin-types-footer',
	templateUrl: './signin-types-footer.component.html',
	styleUrls: ['./signin-types-footer.component.scss'],
	standalone: true,
})
export class SigninTypesFooterComponent implements OnInit {
	@Input() loginTypes: ConfigLoginType[]

	constructor() {}

	ngOnInit(): void {}
}
