<div class="topmenu">
  <div class="inner">
    <div class="left">
      <span class="fuel primary-p2" [class.fuelselected]="selectedFuel === fuel" *ngFor="let fuel of fuels"
        (click)="onFuelSelect(fuel)">{{'common.' + fuel | translate }}</span>
    </div>
    <div class="right">
    </div>
  </div>
</div>
