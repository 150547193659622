<div
	*ngIf="!loading; else loadingBlock"
	class="container"
	[style.color]=" greyBorder ? 'black' : '#003665'"
>
	<div class="x" (click)="closeClicked()">
		<eliq-x-button></eliq-x-button>
	</div>
	<h5 class="primary-h5 header">{{addTitleText}}</h5>
	<div class="div_rounded_picture">
		<img
			class="bolt-image"
			[src]="'/assets/images/bolt.svg'"
			alt=""
			height="30"
		/>
	</div>

	<form [formGroup]="form">
		<div class="energy-area">
			<p class="secondary-p3 long-text">
				{{addEstimationPrefixText}}
				<b>{{estimatedEnergy}} {{valueSuffix}}</b>
				{{addEstimationSuffixText}}
			</p>
			<p></p>
			<p class="secondary-p3 question">{{addQuestionText}}</p>

			<div class="input-field-container">
				<mat-form-field appearance="outline">
					<input
						matInput
						[id]="randomId"
						type="number"
						class="input-text primary-p2"
						style="font-weight: bold;"
						formControlName="energy"
						inputmode="numeric"
						pattern="[0-9]*"
						[value]="form.get('energy').value"
						(change)="this.form.get('energy').setValue($event.value)"
					/>
					<span
						*ngIf="valueSuffix"
						class="secondary-p3"
						style="font-weight: bold;"
						matSuffix
					>
						{{valueSuffix}}
					</span>
				</mat-form-field>
			</div>
			<mat-slider [min]="0" [max]="upperBoundEnergy" [step]="50" #ngSlider>
				<input
					matSliderThumb
					[value]="form.get('energy').value"
					(change)="this.form.get('energy').setValue({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value)"
					#ngSliderThumb="matSliderThumb"
				/>
			</mat-slider>
			<div class="sliderlabelcontainer secondary-p2">
				<div class="sliderlabel">0 kWh</div>
				<div class="sliderlabel">{{upperBoundEnergy}} kWh</div>
			</div>
		</div>

		<div
			class="button-area"
			[style.justify-content]=" greyBorder ? 'flex-start' : 'flex-end'"
			style="display: flex; align-items: center; flex-wrap: wrap;"
		>
			<div class="button-container">
				<eliq-basic-button
					class="saveBtn"
					(clicked)="saveClicked()"
					[disabled]="!form.valid || saveLoading || loading"
					[loading]="saveLoading"
					[submit]="true"
					[useEntireWidth]="true"
				>
					{{saveButtonText}}
				</eliq-basic-button>
			</div>
		</div>
	</form>
</div>

<ng-template #loadingBlock>
	<div class="loading-container">
		<eliq-spinner size="medium"></eliq-spinner>
	</div>
</ng-template>
