import { Component, OnInit, Input } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'

@Component({
	selector: 'eliq-notification-item-info',
	templateUrl: './notification-item-info.component.html',
	styleUrls: ['./notification-item-info.component.css'],
	standalone: true,
})
export class NotificationItemInfoComponent implements OnInit {
	@Input() notification: NotificationData
	constructor() {}

	ngOnInit() {}
}
