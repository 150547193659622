import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'
import { forkJoin, of } from 'rxjs'
import { JsonGetterService, CoreDataStoreService, APIUser } from '@eliq/core'
import { ModalService } from '@eliq/ui/modal'
import { TermsAcceptedDialogComponent } from '../components/terms-accepted-dialog/terms-accepted-dialog.component'
import { catchError, map, switchMap } from 'rxjs/operators'
@Injectable({
	providedIn: 'root',
})
export class TermsCheckerService {
	private checkingTerms = false
	private termsAccepted = false

	constructor(
		private coreDS: CoreDataStoreService,
		private authService: AuthService,
		private modalService: ModalService,
		private configService: JsonGetterService,
	) {}

	/**
	 * this function gets the user from the coreDS, and then checks if the user has accepted terms.
	 * if it has, nothing is done.
	 * if the user has not accepted terms, it opens modal and forces user to accept terms.
	 * if user does not accept terms, user is logged out and redirected to login.
	 */
	checkTerms() {
		if (this.termsAccepted) return of(true)
		return this.checkTermsObs()
	}

	openTermsModal(user: APIUser | null) {
		let userId = -1
		if (user) {
			userId = user.id
		}
		this.checkingTerms = true
		return forkJoin({
			termsAndConditionsUrl: this.configService.getTermsAndConditionsLink(),
			privacyPolicyUrl: this.configService.getPrivacyPolicyLink(),
		}).pipe(
			switchMap((response) => {
				const dialogRef = this.modalService.openModal(
					TermsAcceptedDialogComponent,
				)
				dialogRef.disableClose = true
				const instance = <TermsAcceptedDialogComponent>(
					dialogRef.componentInstance
				)
				instance.userId = userId
				instance.termsLink = response.termsAndConditionsUrl
				instance.privacyPolicyLink = response.privacyPolicyUrl

				return dialogRef.afterClosed().pipe(
					switchMap((res) => {
						if (!res) {
							this.authService.logout()
							this.checkingTerms = false
							return of(false)
						} else {
							// successful yes
							this.checkingTerms = false
							this.termsAccepted = true
							return of(true)
						}
					}),
				)
			}),
		)
	}
	checkTermsObs() {
		if (this.termsAccepted) return of(true)

		return this.configService.isClientHandlingTerms().pipe(
			switchMap((isClientHandlingTerms) => {
				if (isClientHandlingTerms) {
					this.termsAccepted = true
					return of(true)
				} else {
					return this.coreDS.user.pipe(
						switchMap((user) => {
							if (user.terms_accepted) {
								this.termsAccepted = true
								return of(true)
							}
							if (!user.terms_accepted && !this.checkingTerms) {
								return this.openTermsModal(user)
							}
							return of(false)
						}),
						catchError((_err, caught) => {
							// try without userId
							if (!this.checkingTerms) {
								return this.openTermsModal(null)
							}
							return caught
						}),
					)
				}
			}),
		)
	}

	setTermsAccepted(value: boolean) {
		this.termsAccepted = value
	}
}
