<div class="container">
	<p class="content secondary-p3">{{notification.$content}}</p>
	<!--<div *ngIf="showMonitorPart"> <!--This part within this div is the stuff we want to hide if we have poor readings.-->
	<!--<div class="comparisons">
      <div class="current">
        <p class="text secondary-p3" translate>monitor_custom_limit.current</p>
        <p class="value primary-h6">{{currentValue + ' ' + valueSuffix}}</p>
      </div>
      <div class="limit">
        <p class="text secondary-p3" translate>monitor_custom_limit.limit</p>
        <p class="value primary-h6">{{notificationSource.$monitorData.$limitUpper + ' ' + valueSuffix}}</p>
      </div>
    </div>
    <div class="monitor">
      <eliq-monitor-chart *ngIf="values" [monitor]="notificationSource.$monitorData" [values]="values"
        [chartId]="chartId"></eliq-monitor-chart>
    </div>
  </div>-->

	<div style="margin-top: 12px;">
		<a (click)="notificationLinkClicked('/monitors')" routerLink="/monitors">
			<eliq-link label="notifications_view.view_monitors"></eliq-link>
		</a>
	</div>
</div>

<ng-template #elseBlock>
	<eliq-spinner [size]="'small'"></eliq-spinner>
</ng-template>
