<ng-template #linkContent>
	<ng-container *ngIf="leftIconPath$ | async as leftIconPath">
		<div
			[inlineSVG]="leftIconPath | imgSrc"
			[setSVGAttributes]="leftIconSvgAttributes"
			class="link-left-icon"
		></div>
	</ng-container>
	<ng-content></ng-content>
	<span class="link-text">{{label | translate}}</span>
</ng-template>

<a
	*ngIf="isExternalLink && (href?.length ?? 0) > 0"
	[href]="href"
	[target]="target"
	class="link secondary-p2"
	[ngStyle]="buttonStyle"
	[class.disabled]="disabled"
	(click)="handleClick($event)"
>
	<ng-container *ngTemplateOutlet="linkContent"></ng-container>
</a>

<a
	*ngIf="!isExternalLink && (href?.length ?? 0) > 0"
	[routerLink]="href"
	class="link secondary-p2"
	[ngStyle]="buttonStyle"
	[class.disabled]="disabled"
	(click)="handleClick($event)"
>
	<ng-container *ngTemplateOutlet="linkContent"></ng-container>
</a>

<button
	*ngIf="!href"
	class="link link-text secondary-p2"
	[ngStyle]="buttonStyle"
	[type]="type"
	[class.disabled]="disabled"
	(click)="handleClick($event)"
>
	<ng-container *ngTemplateOutlet="linkContent"></ng-container>
</button>
