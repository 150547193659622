import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { BasicButtonComponent } from '@eliq/ui'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-confirm-action-dialog',
	templateUrl: './confirm-action-dialog.component.html',
	styleUrls: ['./confirm-action-dialog.component.css'],
	standalone: true,
	imports: [NgIf, TranslateModule, BasicButtonComponent],
})
export class ConfirmActionDialogComponent implements OnInit {
	public header? = ''
	public question = ''
	public optionNo? = ''
	public optionYes? = ''
	constructor(
		public dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
		private translator: TranslateService,
	) {}

	ngOnInit() {
		if (!this.optionNo) {
			// if we were not provided with an option for negating the action, then default to cancel:
			this.optionNo = this.translator.instant('common.button_cancel')
		}

		if (!this.optionYes) {
			// same thing for optionyes as above
			this.optionYes = this.translator.instant('common.button_yes')
		}
	}

	confirm() {
		this.dialogRef.close(true)
	}

	cancel() {
		this.dialogRef.close(false)
	}
}
