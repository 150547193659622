import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core'
import { JsonGetterService } from '@eliq/core';
import { EliqThemeService } from '@eliq/theme';
import { LinkComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-jersey-b2b-footer',
	templateUrl: './jersey-b2b-footer.component.html',
	styleUrls: ['./jersey-b2b-footer.component.scss'],
	standalone: true,
	imports: [LinkComponent, NgIf, NgFor],
})
export class JerseyB2BFooterComponent implements OnInit {
	public header = {
		title: "Jersey Electricity",
		link: {url: "https://www.jec.co.uk/for-business/", text: "jec.co.uk/for-business"}
	}
	public links = [
		{url: "https://www.jec.co.uk/your-home/help-advice/my-je-app/", text: "FAQ"}, //
		{url: "https://www.jec.co.uk/contact/", text: "Contact Us"},
		{url: "https://www.jec.co.uk/privacy-notice/", text: "Privacy Policy"},
		{url: "https://forms.office.com/Pages/ResponsePage.aspx?id=gIe9q6AD9EC6ve-_3c0tHIqX9PfKPHZLlXFd1OY633VUMEFaQUVZMFk1RFMwOVlIS1pWU0RSUUhMNi4u", text: "Help us improve My JE Business"}
	]
	public footer = {
		text: "© 2024 Jersey Electricity plc"
	}

	public themeService = inject(EliqThemeService)
	public config = inject(JsonGetterService)

	ngOnInit() {
		this.config.getFooterInfo().subscribe((res) => {
			if (res && res["links"]) {
				this.links = res["links"]
			}
		})

	}
}
