// @ts-nocheck
import { NotificationDataSource } from './NotificationDataSources.model'
import { NotificationDataSourceMonitor } from './NotificationDataSourceMonitor.model'
import { NotificationDataSourceReport } from './NotificationDataSourceReport.model'
import { NotificationDataSourceBill } from './NotificationDataSourceBill.model'
import { NotificationDataSourceCustom } from './NotificationDataSourceCustom.model'
import { APINotificationsData } from './api-notification.model'

export class NotificationData {
	private id: number
	private header: string
	private content: string
	private read: boolean
	private createdDate: Date
	private eventCreatedDate: Date
	private eventType: string
	private source: NotificationDataSource
	private shouldRedirect: boolean
	private redirectTo: string
	private redirectText: string

	constructor(apiNotificationData: APINotificationsData) {
		this.id = apiNotificationData.id
		this.header = apiNotificationData.header
		this.content = apiNotificationData.content
		this.read = apiNotificationData.read
		this.createdDate = new Date(apiNotificationData.created_date)
		this.eventCreatedDate = new Date(apiNotificationData.event.created_date)
		this.eventType = apiNotificationData.event.type
		this.source = apiNotificationData.event.source
			? this.createSourceObject(apiNotificationData.event.source)
			: undefined
		this.setRedirections(apiNotificationData.event.type)
	}

	private setRedirections(eventType: string) {
		if (eventType === 'monitor_triggered') {
			this.shouldRedirect = true
			this.redirectTo = 'monitors'
			this.redirectText = 'show_monitor'
		} else if (eventType === 'report_created_monthly') {
			this.shouldRedirect = true
			this.redirectTo = 'reports'
			this.redirectText = 'show_report'
		} else if (eventType === 'info') {
			this.shouldRedirect = false
		} else if (eventType === 'power_outage_update') {
		} else {
			this.shouldRedirect = false
		}
	}

	private createSourceObject(source: any): NotificationDataSource {
		if (this.eventType === 'monitor_triggered') {
			return new NotificationDataSourceMonitor(source)
		} else if (this.eventType.includes('report_created')) {
			return new NotificationDataSourceReport(source)
		} else if (this.eventType.includes('bill_created')) {
			return new NotificationDataSourceBill(source)
		} else if (this.eventType.includes('custom')) {
			return new NotificationDataSourceCustom(source)
		} else {
			return undefined
		}
	}

	/**
	 * Getter $source
	 * @return
	 */
	public get $source(): NotificationDataSource {
		return this.source
	}

	/**
	 * Setter $source
	 * @param value
	 */
	public set $source(value: NotificationDataSource) {
		this.source = value
	}

	/**
	 * Getter $redirectText
	 * @return
	 */
	public get $redirectText(): string {
		return this.redirectText
	}

	/**
	 * Setter $redirectText
	 * @param value
	 */
	public set $redirectText(value: string) {
		this.redirectText = value
	}

	/**
	 * Getter $shouldRedirect
	 * @return
	 */
	public get $shouldRedirect(): boolean {
		return this.shouldRedirect
	}

	/**
	 * Getter $redirectTo
	 * A string like 'monitors' or 'reports' for example
	 * indicating where a potential link should take the user.
	 * @return
	 */
	public get $redirectTo(): string {
		return this.redirectTo
	}

	/**
	 * Setter $shouldRedirect
	 * @param value
	 */
	public set $shouldRedirect(value: boolean) {
		this.shouldRedirect = value
	}

	/**
	 * Setter $redirectTo
	 * @param value
	 */
	public set $redirectTo(value: string) {
		this.redirectTo = value
	}

	/**
	 * Getter $id
	 * @return
	 */
	public get $id(): number {
		return this.id
	}

	/**
	 * Getter $header
	 * @return
	 */
	public get $header(): string {
		return this.header
	}

	/**
	 * Getter $content
	 * @return
	 */
	public get $content(): string {
		return this.content
	}

	/**
	 * Getter $read
	 * @return
	 */
	public get $read(): boolean {
		return this.read
	}

	/**
	 * Getter $createdDate
	 * @return
	 */
	public get $createdDate(): Date {
		return this.createdDate
	}

	/**
	 * Getter $eventCreatedDate
	 * @return
	 */
	public get $eventCreatedDate(): Date {
		return this.eventCreatedDate
	}

	/**
	 * Getter $eventType
	 * @return
	 */
	public get $eventType(): string {
		return this.eventType
	}

	/**
	 * Setter $id
	 * @param value
	 */
	public set $id(value: number) {
		this.id = value
	}

	/**
	 * Setter $header
	 * @param value
	 */
	public set $header(value: string) {
		this.header = value
	}

	/**
	 * Setter $content
	 * @param value
	 */
	public set $content(value: string) {
		this.content = value
	}

	/**
	 * Setter $read
	 * @param value
	 */
	public set $read(value: boolean) {
		this.read = value
	}

	/**
	 * Setter $createdDate
	 * @param value
	 */
	public set $createdDate(value: Date) {
		this.createdDate = value
	}

	/**
	 * Setter $eventCreatedDate
	 * @param value
	 */
	public set $eventCreatedDate(value: Date) {
		this.eventCreatedDate = value
	}

	/**
	 * Setter $eventType
	 * @param value
	 */
	public set $eventType(value: string) {
		this.eventType = value
	}
}
