
export enum ConfigButtonTypes {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	UNDERLINE = 'underline',
	ERROR = 'error',
}
export interface ConfigButton {
	id: string
	type: ConfigButtonTypes
	style: Record<string, string>
}
export interface CommonConfig {
	daily_graph_shift_right?: number
	no_web_component_styles?: boolean
	underline_button_icon_left: string
	button_options: {
		primary_button_all_caps: boolean
	}
	dates?: {
		hideGermanDots?: boolean
	}
	button_types: ConfigButton[]
}
