<eliq-fullpage-linear-loading-bar
	*ngIf="notBrowser || !transLoaded"
></eliq-fullpage-linear-loading-bar>

<div *ngIf="transLoaded" class="example-container mat-eliq-background my-theme">
	<eliq-onscreen-console-container
		*ngIf="enableHiddenConsole"
	></eliq-onscreen-console-container>

	<div class="mainview">
		<eliq-header
			*ngIf="showHeader && !((embeddedService.params$ | async)?.hideHeader)"
			[locations]="locations"
			[currentLocation]="activeLocation"
			[showMenuOptions]="!!this.headers && this.showMenuOptions"
			[accountCategories]="categories"
			[headers]="headers"
			(logout)="logoutClicked()"
		></eliq-header>
		<!-- Was [showMenuOptions]="!!this.headers && !this.env.isIntegrationless()" -->

		<router-outlet></router-outlet>

		<div
			*ngIf="isIntegrationless && isLoggedInAndNotOnFlow()"
			class="il-email-register-banner-container"
		>
			<eliq-luna-register-email-banner></eliq-luna-register-email-banner>
		</div>
	</div>
	<ng-container *ngIf="showMainFooter && (isServer || !((embeddedService.params$ | async)?.hideFooter))">
		<eliq-jersey-footer *ngIf="clientName === 'jersey'"></eliq-jersey-footer>
		<eliq-jersey-b2b-footer *ngIf="clientName === 'jerseyb2b'"></eliq-jersey-b2b-footer>
		<eliq-footer *ngIf="clientName !== 'jersey' && clientName !== 'jerseyb2b'"></eliq-footer>
	</ng-container>
</div>
